<template>
  <div
    class="app-icon"
    :class="[iconClass]"
    :style="{
      backgroundImage: 'url(' + require('@/assets/icons/' + icon + '.svg') + ')'
    }"
  ></div>
</template>

<script>
export default {
  name: "AppIcon",
  props: {
    icon: String,
    iconClass: String
  }
};
</script>

<style scoped>
.app-icon {
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* classes */
.inverted {
  background-color: var(--color-text);
}
.filled {
  background-color: var(--color-4-superlight);
}
.ringed {
  border: 2px solid var(--color-4-light);
}
</style>
