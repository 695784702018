<template>
  <app-notification-bar
    v-if="orgState !== OrgStateEnum.ACTIVE"
    :message="OrgStateEnum.properties[orgState].msg"
    :displayClass="lowerCaseState"
    @click="showOrgStateModal = true"
  >
    <template
      v-slot:action
      v-if="
        isOwner &&
        (orgState == OrgStateEnum.TRIAL || orgState == OrgStateEnum.TRIALFREEZE)
      "
    >
      &mdash;
      <router-link :to="{ name: 'Billing', params: { id: orgId } }" class="link"
        >Upgrade Account</router-link
      ></template
    >
  </app-notification-bar>
  <!-- modal end of trial -->
  <transition name="modal">
    <app-modal
      v-if="
        showOrgStateModal &&
        orgState == OrgStateEnum.TRIALFREEZE &&
        !suppressModal
      "
      @close="showOrgStateModal = false"
    >
      <template v-slot:header><h3>Free Trial has ended</h3></template>
      <template v-slot:body>
        <p v-if="!isOwner">
          All information can still be viewed for a while, but is now
          read-only.<br /><br />
          Once your organization owner, {{ orgOwner.fullName }} (<a
            href="mailto:{{ orgOwner.email }}"
            >{{ orgOwner.email }}</a
          >) provides payment details you can continue using Role-up.
        </p>
        <p v-if="isOwner">
          All information can still be viewed for a while, but is now
          read-only.<br /><br />To keep using Role-up, go to 'Upgrade Account'
          at the top of this page.
        </p></template
      >
    </app-modal>
  </transition>
  <!-- end modal -->
  <!-- modal payment failed -->
  <transition name="modal">
    <app-modal
      v-if="
        showOrgStateModal && orgState == OrgStateEnum.FROZEN && !suppressModal
      "
      @close="showOrgStateModal = false"
    >
      <template v-slot:header
        ><h3 v-if="!isOwner">Oops</h3>
        <h3 v-if="isOwner">Your Payment Failed</h3></template
      >
      <template v-slot:body>
        <p v-if="!isOwner">
          It seems that the payment for this organization failed.<br />All
          information can still be viewed, but it is read-only until payment is
          received.<br /><br />
          Your organization owner, {{ orgOwner.fullName }} (<a
            href="mailto:{{ orgOwner.email }}"
            >{{ orgOwner.email }}</a
          >) can fix this.
        </p>
        <p v-if="isOwner">
          All information can still be viewed, but it is read-only until we
          receive your payment
          <br /><br />You can find your billing details at the bottom of the
          ogranization page.
        </p></template
      >
    </app-modal>
  </transition>
  <!-- end modal -->
</template>

<script>
import AppNotificationBar from "./AppNotificationBar.vue";
import AppModal from "./AppModal.vue";
import { OrgStateEnum } from "../shared/orgstate";

export default {
  name: "AppOrgState",
  components: {
    AppNotificationBar,
    AppModal
  },
  props: {
    orgState: {
      required: true,
      type: String
    },
    orgId: {
      type: String,
      required: true
    },
    orgOwner: Object,
    user: Object,
    suppressModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOrgStateModal: true,
      OrgStateEnum: OrgStateEnum
    };
  },
  computed: {
    isOwner() {
      return this.orgOwner.id == this.user.id;
    },
    lowerCaseState() {
      return this.orgState.toLowerCase();
    }
  }
};
</script>

<style scoped>
.link {
  color: var(--color-text);
}
</style>
