<template>
  <!-- enable in Team.vue  -->
  <input
    id="off-canvas"
    type="checkbox"
    :checked="status"
    aria-label="display off-canvas menu"
  />
  <label for="off-canvas" v-if="userCanEdit">
    <app-icon-button
      :icon="'checklist_inverted'"
      :buttonClass="'inverted bordered'"
      :labelText="'Talking Points'"
      :labelPosition="'left'"
      @click="status = !status"
    />
  </label>
  <div class="off-canvas-container" v-if="userCanEdit">
    <div class="off-canvas-body">
      <div class="off-canvas-header">
        <h2>Talking Points</h2>
        <app-icon-button
          :icon="'close'"
          :buttonClass="'white'"
          @click="status = !status"
        />
      </div>
      <p>This is some information that is in the body of the offside</p>
    </div>
  </div>
</template>

<script>
import AppIconButton from "./AppIconButton.vue";

export default {
  components: { AppIconButton },
  name: "AppOffCanvas",
  inject: ["userCanEdit"],
  data() {
    return {
      status: true
    };
  },
  setup() {}
};
</script>

<style scoped>
#off-canvas {
  display: none;
}
label {
  display: block;
  position: absolute;
  left: -56px;
  left: -48px;
  top: calc(50% - 64px);
}
#off-canvas:not(:checked) ~ label {
  display: none;
}
.off-canvas-container {
  right: 0;
  width: 300px;
  /* height: var(--viewport-height); */
  height: 100%;
  transition: width 0.3s ease-out;
  background-color: var(--color-text);
  background-color: var(--color-container-background);
  /* border-left: 2px solid white; */
}
#off-canvas:checked ~ .off-canvas-container {
  width: 0px;
  overflow: hidden;
}
.off-canvas-body {
  padding: 0 var(--spacing-content);
  width: 300px;
}
.off-canvas-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-s) 0;
  padding: var(--spacing-content) 0;
}
</style>
