<template>
  <div class="poc">
    <h1>This is a POC</h1>
    <div class="graph-wrapper">
      <v-network-graph
        :nodes="nodes"
        :edges="edges"
        :configs="configs"
        :zoom-level="1.5"
        id="org-graph"
        :event-handlers="eventHandlers"
        ref="graph"
      >
      </v-network-graph>
      <div class="buttons-wrapper">
        <app-icon-button
          :icon="'dots-hexagon'"
          @click="layoutFree"
          :labelText="'Network layout'"
          :labelPosition="'left'"
        />
        <app-icon-button
          :icon="'dots-circle'"
          @click="layoutRadial"
          :labelText="'Circle layout'"
          :labelPosition="'left'"
        />
        <app-icon-button
          :icon="'center-content-2'"
          @click="graph.fitToContents()"
          :labelText="'Recenter'"
          :labelPosition="'left'"
        />
      </div>
    </div>

    <div
      class="inspector small"
      :class="isTouchDevice() ? 'isTouch' : 'noTouch'"
      :style="{
        top: `${this.y}px`,
        left: `${this.x}px`,
        opacity: `${this.opacity}`
      }"
    >
      <div class="inspector-header" v-if="source">
        <div class="team-dot" :style="{ background: `${source.color}` }"></div>
        <div class="team-name">{{ source.name }}</div>
      </div>
      <span v-html="members"></span>
      <div class="inspector-footer" v-if="target">
        <div class="team-dot" :style="{ background: `${target.color}` }"></div>
        <div class="team-name">{{ target.name }}</div>
      </div>
    </div>

    <p>Todo:</p>
    <ul>
      <li>My teams vs other teams (team groups)</li>
      <li>Team colors and representation (background-images?)</li>
      <li>Convert poc-page into component</li>
    </ul>

    <p @click="graph.getSizes()">
      <!-- Graph.getSizes()<br /> -->
      <!-- Uncomment next line only after cliking first! -->
      <!-- {{ graph.getSizes() }} -->
    </p>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUnmounted } from "vue";
import * as vNG from "v-network-graph";
import { ForceLayout } from "v-network-graph/lib/force-layout";
import AppIconButton from "../components/AppIconButton.vue";

// docs: https://dash14.github.io/v-network-graph/examples/layout.html
// https://www.typescriptlang.org/play?
// used to convert ts to js

export default {
  components: { AppIconButton },
  name: "Poc",
  setup() {
    const nodes = {
      node1: {
        id: "idDesignTeam123",
        name: "Design Team",
        color: "#4bd4cc",
        members: [
          "Anna Keller",
          "Jules Hart",
          "Dave Wang",
          "Karen Tiedeman",
          "Michelle Colombo",
          "Cindy Rowe",
          "Carl Leakey",
          "Cassie Nicolaidis",
          "Mateo Stagliano"
        ]
      },
      node2: {
        id: "idResearchTeam123",
        name: "Research Team",
        color: "#92f0b8",
        members: [
          "Karen Tiedeman",
          "John Ciucci",
          "Carl Leakey",
          "Grace Kearns",
          "Elena Hainsworth"
        ]
      },
      node3: {
        id: "idMT123",
        name: "Management Team",
        color: "#9dd8f9",
        members: [
          "Anna Keller",
          "Oliver Quinn",
          "Michelle Colombo",
          "Trevor Alston"
        ]
      },
      node4: {
        id: "idSales123",
        name: "Sales Tigers",
        color: "#c1b8fc",
        members: ["Anna Keller", "Michelle Colombo", "Mateo Stagliano"]
      },
      node5: {
        id: "idInfrastructure123",
        name: "Infrastructure",
        color: "#febdff",
        members: [
          "Carl Leakey",
          "Kelly Smith",
          "Trevor Alston",
          "Cassie Nicolaidis"
        ]
      },
      node6: {
        id: "idWebsite123",
        name: "Website Project",
        color: "#ffdc2e",
        members: [
          "Oliver Quinn",
          "Dave Want",
          "Michelle Colombo",
          "Carl Leakey",
          "Mateo Stagliano"
        ]
      },
      node7: {
        id: "idFinance123",
        name: "Finance",
        color: "#9fffee",
        members: ["Jacquelyn Cohn", "Trevor Alston"]
      },
      node8: {
        id: "idHR123",
        name: "HR",
        color: "#fe993c",
        members: ["Alex Ruiz", "Trevor Alston"]
      }
    };

    /*
    create edges based on the nodes

    https://stackoverflow.com/questions/55525886/how-to-create-edges-between-nodes-that-have-similarities
    1. iterate over every pair of nodes
        for each node, pair with all nodes with a higher index in array
    2. per node-pair take compare member arrays
    3. create array with the members that are in both arrays (or remove the ones that are not?)
    4. create edge if array is larger than 0 items
    */

    var edges = {};

    const teams = Object.keys(nodes);
    teams.forEach((node, index) => {
      // pair with all teams with a higher index
      const a = index;
      teams.forEach((node2, index) => {
        if (index > a) {
          // console.log(node + "-" + node2);
          // we have a pair node-node2
          const edgeMembers = nodes[node].members.filter((x) =>
            nodes[node2].members.includes(x)
          );
          if (edgeMembers.length > 0) {
            // console.log(edgeMembers);
            // we have an edge
            const edgeID = "edge" + a + index;
            const newEdge = {
              source: `${node}`,
              target: `${node2}`,
              members: edgeMembers
            };
            // console.log(newEdge);
            edges[edgeID] = newEdge;
          }
        }
      });
    });

    const configs = reactive(
      vNG.defineConfigs({
        view: {
          layoutHandler: new ForceLayout({
            positionFixedByDrag: false,
            positionFixedByClickWithAltKey: true,
            // * The following are the default parameters for the simulation.
            // * You can customize it by uncommenting below.
            createSimulation: function (d3, nodes, edges) {
              const forceLink = d3.forceLink(edges).id(function (d) {
                return d.id;
              });
              return d3
                .forceSimulation(nodes)
                .force("edge", forceLink.distance(100))
                .force("charge", d3.forceManyBody().strength(1.5))
                .force("collide", d3.forceCollide(60).strength(0.02))
                .force("center", d3.forceCenter().strength(0.8))
                .alphaMin(0.0001);
              //  https://github.com/d3/d3-force
            }
          }),
          panEnabled: false,
          zoomEnabled: false
        },
        node: {
          normal: {
            /*
            https://static1.squarespace.com/static/59df9853cd0f68dd29301c12/t/61ba32f86a54ad11bbe3f471/1639592697089/Sizing-Circles-for-Data-Visualization-InfoNewt.pdf
           */
            radius: (node) =>
              Math.min((40 * Math.sqrt(node.members.length / 2)) / 2, 60),
            color: (node) => node.color
          },
          hover: {
            radius: (node) =>
              Math.min((40 * Math.sqrt(node.members.length / 2)) / 2, 60) + 2,
            color: (node) => node.color
          },
          selectable: false,
          label: {
            visible: true,
            fontFamily: "inter",
            fontSize: 14,
            lineHeight: 0.9,
            // direction: "center",
            direction: "south",
            color: "#000",
            background: {
              visible: false,
              color: "#fff",
              padding: 4,
              borderRadius: 2
            }
          }
        },
        edge: {
          normal: {
            width: (edge) => Math.min(edge.members.length * 6, 96),
            color: "#f3f0ff"
          },
          hover: {
            width: (edge) => Math.min(edge.members.length * 6, 96) + 4,
            color: "#c1b9fc"
          }
        }
      })
    );

    const graph = ref();

    // inspector
    const opacity = ref(0);
    const members = ref();
    const source = ref();
    const target = ref();

    const eventHandlers = {
      "edge:pointerover": ({ edge }) => {
        members.value = edges[edge].members.sort().join("</br>");
        opacity.value = 1;
        source.value = nodes[edges[edge].source];
        target.value = nodes[edges[edge].target];
      },
      "edge:pointerout": () => {
        opacity.value = 0;
      },
      "node:pointerover": ({ node }) => {
        members.value = nodes[node].members.sort().join("</br>");
        opacity.value = 1;
        source.value = nodes[node];
        target.value = null;
      },
      "node:pointerout": () => {
        opacity.value = 0;
      },
      "node:click": ({ node }) => {
        console.log("open team page for team: " + nodes[node].id);
      }
    };

    // mouse coordinates
    const x = ref(0);
    const y = ref(0);
    const offset = 12;

    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    function update(event) {
      if (isTouchDevice()) {
        x.value = 16;
        y.value = 60;
      } else {
        x.value = event.pageX + offset;
        y.value = event.pageY + offset;
      }
    }

    onMounted(() => window.addEventListener("mousemove", update));
    onUnmounted(() => window.removeEventListener("mousemove", update));

    // layout buttons
    function layoutRadial() {
      const forceRadius = 120;
      configs.view.layoutHandler = new ForceLayout({
        createSimulation: function (d3, nodes, edges) {
          const forceLink = d3.forceLink(edges).id(function (d) {
            return d.id;
          });
          return d3
            .forceSimulation(nodes)
            .force("edge", forceLink.distance(80))
            .force("charge", d3.forceManyBody().strength(0.5))
            .force("radial", d3.forceRadial(forceRadius).strength(2))
            .force("collide", d3.forceCollide(50).strength(0.02))
            .force("center", d3.forceCenter().strength(1))
            .alphaMin(0.0001);
        }
      });
    }
    function layoutFree() {
      configs.view.layoutHandler = new ForceLayout({
        createSimulation: function (d3, nodes, edges) {
          const forceLink = d3.forceLink(edges).id(function (d) {
            return d.id;
          });
          return d3
            .forceSimulation(nodes)
            .force("edge", forceLink.distance(100))
            .force("charge", d3.forceManyBody().strength(1.5))
            .force("collide", d3.forceCollide(60).strength(0.02))
            .force("center", d3.forceCenter().strength(0.8))
            .alphaMin(0.0001);
        }
      });
    }

    return {
      nodes,
      edges,
      configs,
      graph,
      eventHandlers,
      members,
      x,
      y,
      opacity,
      layoutRadial,
      layoutFree,
      isTouchDevice,
      source,
      target
    };
  }
};
</script>

<style scoped>
/* TEMP ONLY FOR POC */
.poc {
  padding: 1rem;
}
p,
ul {
  margin: 1rem 0;
}

/* REAL DEAL */
.graph-wrapper {
  position: relative;
}
.buttons-wrapper {
  position: absolute;
  right: var(--spacing-s);
  bottom: 50%;
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
}
.buttons-wrapper .button-sized-container {
  margin: var(--spacing-xs);
}
/* use id to overwrite values from plugin css */
#org-graph {
  /* border: 1px solid var(--color-1-light); */
  /* minimal height, defined in svg */
  height: 600px;
}
svg.v-canvas {
  width: 100%;
  height: 400px;
}

/* https://css-tricks.com/svg-properties-and-css/ */
svg.v-canvas .v-text {
  /* targets the labels */
  fill: var(--color-text);
}

.inspector {
  position: absolute;
  display: block;
  background-color: var(--color-text);
  color: var(--color-white);
  border-radius: var(--border-radius);
  padding: var(--spacing-m) var(--spacing-m);
  line-height: var(--line-height);
}
.inspector-header,
.inspector-footer {
  display: flex;
}
.inspector-header {
  margin-bottom: var(--line-height);
}
.inspector-footer::before {
  content: "";
  display: block;
  width: 1px;
  height: var(--line-height);
  background-color: var(--color-white);
  position: absolute;
  top: 31px;
  left: 16px;
  opacity: 50%;
}
.inspector-footer {
  margin-top: var(--line-height);
}
.inspector-footer::after {
  content: "";
  display: block;
  width: 1px;
  height: var(--line-height);
  background-color: var(--color-white);
  position: absolute;
  bottom: 30px;
  left: 16px;
  opacity: 50%;
}
.team-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  /* transform: translateX(-3px); */
  transform: translate(-3px, 3px);
}

.team-name {
  opacity: 70%;
}
</style>
