<template>
  <!-- footer for owners -->
  <div class="org-footer" v-if="userCanEdit">
    <span class="info">You are the owner of this organization.</span>
    <span
      v-if="
        org.organisationState.derivedOrganisationState == 'TRIAL' ||
        org.organisationState.derivedOrganisationState == 'TRIALFREEZE'
      "
    >
      <router-link
        :to="{ name: 'Billing', params: { id: org.id } }"
        class="info"
        >Upgrade Account</router-link
      ></span
    >
    <span
      v-if="
        org.organisationState.derivedOrganisationState != 'TRIAL' &&
        org.organisationState.derivedOrganisationState != 'TRIALFREEZE'
      "
    >
      <router-link
        :to="{ name: 'Billing', params: { id: org.id } }"
        class="info"
        >Admin & Billing</router-link
      ></span
    >
  </div>
  <div class="org-footer" v-if="!userCanEdit && userIsAdminOfTeams">
    <span class="info"
      >To leave {{ org.name }}, transfer the ownership of your teams to other
      team members.</span
    >
  </div>
  <!-- footer for members not owner -->
  <div class="org-footer" v-if="!userCanEdit && !userIsAdminOfTeams">
    <span class="info"
      >By leaving {{ org.name }}, you will lose access to all its teams.</span
    >
    <app-button
      :buttonLabel="'Leave'"
      @click="showLeaveOrgModal = !showLeaveOrgModal"
    />
  </div>
  <!-- user list modal -->
  <transition name="modal">
    <app-modal v-if="showLeaveOrgModal" @close="showLeaveOrgModal = false">
      <template v-slot:header>
        <h3>Leave</h3>
      </template>
      <template v-slot:body>
        Are you sure you want to leave {{ org.name }}?<br />
        You will lose access to all its teams and roles.
      </template>
      <template v-slot:footer>
        <app-button
          :buttonLabel="'Cancel'"
          @click="showLeaveOrgModal = false"
        />
        <app-button
          :buttonLabel="'Leave'"
          @click="(showLeaveOrgModal = false), leaveThisOrg()"
        />
      </template>
    </app-modal>
  </transition>
  <!-- end modal -->
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import { mutationRemoveUserFromOrganisation } from "../apollo";
import AppButton from "./AppButton.vue";
import AppModal from "./AppModal.vue";

export default {
  components: { AppButton, AppModal },
  name: "OrgFooter",
  props: {
    org: {
      type: Object,
      required: true
    },
    user: { type: Object }
  },
  inject: ["userCanEdit"],
  data() {
    return {
      showLeaveOrgModal: false
    };
  },
  computed: {
    userIsAdminOfTeams() {
      return (
        this.org.organisationTeams.filter(
          (ot) => ot.team.adminUser.id == this.user.userId
        ).length > 0
      );
    }
  },
  setup(props) {
    const { mutate: leaveOrg } = useMutation(
      mutationRemoveUserFromOrganisation,
      () => ({
        variables: { id: props.user.userId, organisationId: props.org.id }
      })
    );
    function leaveThisOrg() {
      console.log(
        "[OrgFooter.vue] " +
          props.user.fullName +
          " would like to leave " +
          props.org.name
      );
      leaveOrg()
        .then((s) => {
          console.log(
            `OrgFooter. User succesfully removed from organisation ${JSON.stringify(
              s
            )}`
          );
        })
        .catch((error) => {
          console.log(`failed to remove user from organisation ${error}`);
          // TODO: should return something here
        });
    }
    return {
      leaveThisOrg
    };
  }
};
</script>

<style scoped>
.org-footer {
  padding-top: calc(var(--spacing-content) * 3);
  padding-bottom: calc(var(--spacing-content) * 2);
  padding-right: var(--spacing-content);
  padding-left: var(--padding-logo);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info {
  padding-right: var(--spacing-content);
}
</style>
