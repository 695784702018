<template>
  <div class="container" :style="{ backgroundColor: bgColor }">
    <div
      class="progress"
      :style="{
        width: progressPercentage * 100 + '%',
        backgroundColor: progressColor
      }"
      :class="{ invert: invertDirection, full: isFull }"
    ></div>
    <div class="label">
      <span>{{ label }}</span> <span>{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppChartBar",
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: true
    },
    progressPercentage: {
      type: Number,
      required: true
    },
    progressColor: {
      type: String,
      default: "var(--color-4)"
    },
    bgColor: {
      type: String,
      default: "var(--color-4-light)"
    },
    invertDirection: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFull() {
      return this.progressPercentage == 1;
    }
  }
};
</script>

<style scoped>
.container {
  /* border: 1px solid red; */
  position: relative;
  min-height: 24px;
  height: 100%;
  border-radius: var(--spacing-s);
}
.progress {
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: var(--spacing-s) 0 0 var(--spacing-s);
}
.invert {
  right: 0;
  border-radius: 0 var(--spacing-s) var(--spacing-s) 0;
}
.label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: var(--spacing-s);
}
</style>
