<template>
  <h2>Billing history</h2>
  <section class="spaced-bottom">
    <ul>
      <li>
        <div class="info labels">
          <span class="column-label">Invoice Date</span>
          <span class="column-label">Members</span>
          <span class="column-label align-right">Amount</span>
          <span class="column-label align-right padded-right">Payment</span>
        </div>
      </li>
      <li v-for="item in invoices" :key="item.id">
        <app-line-item>
          <template v-slot:label>
            <div class="info">
              <span
                ><a v-bind:href="item.receiptUrl" target="_blank">
                  <app-format-date :dateString="item.payoutDate" /> </a
              ></span>
              <span>{{ item.quantity }}</span>
              <span class="align-right amount"
                >€ {{ item.amount.toFixed(2) }}</span
              >
              <div>
                <span class="status" :class="item.paymentInfo">{{
                  item.paymentInfo
                }}</span>
              </div>
            </div>
          </template>
        </app-line-item>
      </li>
    </ul>
    <br />
  </section>
</template>

<script>
import AppLineItem from "./AppLineItem.vue";
import AppFormatDate from "./AppFormatDate.vue";

export default {
  name: "BillingInvoiceList",
  components: { AppLineItem, AppFormatDate },
  props: {
    invoices: {
      type: Object,
      required: false
    }
  },

  setup() {
    function formatDate(dateString) {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long" };

      return date.toLocaleDateString("en-US", options);
    }

    return {
      formatDate
    };
  }
};
</script>

<style scoped>
section {
  padding: var(--line-height) 0;
}
section.spaced-bottom {
  padding-bottom: 4.75rem;
}

.info {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 2fr;
  gap: var(--spacing-content);
}
.labels {
  min-height: var(--spacing-content);
  margin-top: var(--line-height);
  border-bottom: 1px solid var(--color-4-light);
}
.padded-right {
  padding-right: var(--spacing-m);
}
.amount {
  font-variant-numeric: tabular-nums;
}
.status {
  float: right;
  padding: var(--spacing-xs) var(--spacing-m);
  border-radius: var(--spacing-s);
  text-transform: capitalize;
}
.succeeded {
  /* background-color: #92f0b8; */
}
.failed {
  background-color: #ffdc2e;
}
</style>
