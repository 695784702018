import { DefaultApolloClient } from "@vue/apollo-composable";
import VNetworkGraph from "v-network-graph";
import { createApp } from "vue";
import { apolloClient } from "./apollo";
import App from "./App.vue";
import "./assets/css/BaseStyles.css";
import "v-network-graph/lib/style.css";
import router from "./router";
import { RoleUpWebSocket } from "./websocket";
import { store } from "./store";
let paddleJs = document.createElement("script");
paddleJs.setAttribute("src", "https://cdn.paddle.com/paddle/paddle.js");
document.head.appendChild(paddleJs);

const roleUpWebSocket = new RoleUpWebSocket();

const app = createApp(App);
app.config.unwrapInjectedRef = true;
app.use(router);
app.use(store);
app.provide("websocket", roleUpWebSocket);
app.use(VNetworkGraph);
app.provide(DefaultApolloClient, apolloClient);
app.mount("#app");
