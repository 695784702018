<template>
  <h2>Danger Zone</h2>
  <section>
    <p class="spaced-bottom">
      If you no longer wish to use this Role-up organization, you can cancel
      your subscription. After cancelation, you can delete all data.
    </p>
    <app-faq>
      <template v-slot:question
        >What happens if I cancel this organization?</template
      >
      <template v-slot:answer>
        If you cancel your subscription, this organization will become
        read-only. <br />
        Members in the organization can then start a new subscription and become
        the new owner, or you (as owner) can delete all information from our
        servers. All data that was entered in the organization, such as Teams,
        Roles and responsibilities will then be removed from our servers.<br />
        If no new subscription is created, the data will be deleted
        automatically after a few months.
      </template>
    </app-faq>
    <app-faq>
      <template v-slot:question
        >Will my credit card be billed when I cancel?</template
      >
      <template v-slot:answer>
        Yes. When you cancel your Paddle subscription, your credit card will be
        billed for the current month.
      </template>
    </app-faq>
    <app-faq>
      <template v-slot:question
        >Can I make someone else the owner of this organization?</template
      >
      <template v-slot:answer
        >Yes, if you cancel your subscription, another member in the
        organization can become the new owner, by creating a new subscription.
        Make sure that the new owner is in the organization before you cancel
        your subscription.</template
      >
    </app-faq>
    <app-faq>
      <template v-slot:question
        >Can I transfer all teams and roles to another organization?</template
      >
      <template v-slot:answer
        >If you want this, please contact us via email.</template
      >
    </app-faq>
    <br />
    <p>
      Make sure to download any receipts you want to keep from the above Billing
      history before cancelling your subscription.
    </p>
    <br />
    <br />
    <br />
    <app-button
      v-if="showCancelButton"
      :buttonLabel="'Cancel Subscription'"
      :buttonClass="'destroy-secondary'"
      @click="cancelSubscription()"
    />
  </section>
</template>

<script>
import AppButton from "./AppButton.vue";
import AppFaq from "../components/AppFaq.vue";

export default {
  name: "BillingDangerZone",
  components: {
    AppButton,
    AppFaq
  },
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  computed: {
    showCancelButton() {
      return (
        this.organisation.organisationState.derivedOrganisationState ==
          "ACTIVE" ||
        this.organisation.organisationState.derivedOrganisationState == "FROZEN"
      );
    }
  },
  mounted() {
    if (process.env.VUE_APP_PADDLE_SANDBOX == "true") {
      console.log("PADDLE SANDBOX SET");
      // eslint-disable-next-line no-undef
      Paddle.Environment.set("sandbox");
    }

    // eslint-disable-next-line no-undef
    Paddle.Setup({
      vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID)
    });
  },
  setup(props) {
    function cancelSubscription() {
      const {
        organisation: {
          paymentDetails: { cancelUrl }
        }
      } = props;
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        override: cancelUrl
      });
    }
    return {
      cancelSubscription
    };
  }
};
</script>

<style scoped>
section {
  padding: var(--line-height) 0;
}
section.spaced-bottom {
  padding-bottom: 4.75rem;
}
.spaced-bottom {
  margin-bottom: var(--line-height);
}
</style>
