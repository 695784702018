<template>
  <div class="message-wrapper">
    <h3>The subscription on this organization has been cancelled.</h3>
    <p v-if="!billingPage">
      To keep using this organization, please create a new subscription. Any
      member of this organization can do this. You will have to provide payment
      details and will become the new owner of this organization.
    </p>
    <p v-if="billingPage">
      You can create a new subscription for this organization by providing
      payment details.
    </p>
    <app-button
      :buttonClass="'cta'"
      :buttonLabel="'Create New Subscription'"
      @click="createPayment"
      class="button"
    />
    <span class="payment">{{ paymentMessage }}</span>
  </div>
</template>

<script>
import AppButton from "./AppButton.vue";
import router from "../router";
import { ref } from "vue";
import { retrieveMembers } from "../generic/organisationLogic";

export default {
  name: "OrgClaimSubscription",
  components: { AppButton },
  props: {
    organisation: { type: Object },
    loggedOnUser: { type: Object },
    billingPage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (process.env.VUE_APP_PADDLE_SANDBOX == "true") {
      console.log("PADDLE SANDBOX SET");
      // eslint-disable-next-line no-undef
      Paddle.Environment.set("sandbox");
    }

    // eslint-disable-next-line no-undef
    Paddle.Setup({
      vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID)
    });
  },
  setup(props) {
    const paymentMessage = ref();
    const evaluateCheckout = (data) => {
      console.log("checkoutComplete");
      const {
        checkout: { completed, id }
      } = data;
      console.log(`evaluateCheckout completed:${completed} id:${id}`);
      if (completed) {
        router.push({
        name: "Billing",
        params: { id: props.organisation.id }
      });
      } else {
        paymentMessage.value = "Payment not succesful";
      }
    };

    function createPayment() {
      const { quantity } = retrieveMembers(props.organisation);
      console.log(`createPayment for org ${props.organisation.id}`);
      const {loggedOnUser:{ email: adminEmail, id: adminUserId }} = props
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        product: parseInt(process.env.VUE_APP_PADDLE_PRODUCT),
        quantity,
        allowQuantity: false,
        disableLogout: true,
        passthrough: `{"userId": "${adminUserId}", "version":"2" ,"organisationId": "${props.organisation.id}"}`,
        email: adminEmail,
        successCallback: evaluateCheckout,
        closeCallback: evaluateCheckout
      });

    }
    return { createPayment, paymentMessage };
  }
};
</script>

<style scoped>
.message-wrapper {
  padding: calc(var(--spacing-content) * 1.5);
  border-radius: var(--border-radius-l);
  background-color: var(--color-4-superlight);
}
@media screen and (max-width: 767px) {
  .message-wrapper {
    margin-top: var(--spacing-content);
  }
}
p {
  margin-top: var(--line-height);
  margin-bottom: var(--line-height);
}
.button {
  margin-top: var(--line-height);
}
</style>
