<template>
  <div class="drag-item" :class="{ locked: this.locked }">
    {{ role.title }}
    <div class="drag-icon"></div>
  </div>
</template>

<script>
export default {
  name: "TeamRoleDragItem",
  components: {},
  props: {
    role: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
};
</script>

<style scoped>
.drag-item {
  padding: var(--spacing-m);
  background-color: var(--color-input-filled);
  margin: 0 var(--spacing-content);
  padding-right: var(--button-size);
  margin-bottom: var(--spacing-s);
  position: relative;
  min-height: var(--button-size);
}
.drag-icon {
  width: var(--button-size);
  height: var(--button-size);
  position: absolute;
  right: 0;
  top: calc(50% - var(--button-size) / 2);
  background-image: url("../assets/icons/move.svg");
  background-repeat: no-repeat;
}
.drag-item:hover {
  background-color: var(--color-input-background-active);
}

.drag-item.locked {
  background-color: var(--color-grey-100);
  background-image: none;
  color: var(--color-text-superlight);
}
</style>
