import { createStore } from "vuex";

const USER_LOGON = "USER_LOGON";
const USER_LOGOFF = "USER_LOGOFF";
const USER_ACTIVEINTEAM = "USER_ACTIVEINTEAM";
const USER_INACTIVEINTEAM = "USER_INACTIVEINTEAM";
const CLEAR_USERS = "CLEAR_USERS";
const CLEAR_LOCKS = "CLEAR_LOCKS";
const LOCK_FIELD = "LOCK_FIELD";
const UNLOCK_FIELD = "UNLOCK_FIELD";
const CLEAR_STATE = "CLEAR_STATE";

// the user part of this store keeps track of other users
// within the organisation of which the current user is logged on
// when logging of or switching organisation the user part of
// this store gets cleared and re-populated

const store = createStore({
  state() {
    return {
      currentUsers: [],
      lockedFields: []
    };
  },
  getters: {
    fieldLockedByUserId: (state) => (teamId, typeName, roleId, field) => {
      const id = `${teamId}:${typeName}:${roleId}:${field}`;
      const e = state.lockedFields.find((u) => u.id == id);
      return e ? e.userId : undefined;
    },
    userStatus: (state) => (userId) =>
      state.currentUsers.find((u) => u.userId === userId),
    userOnline: (state) => (userId) =>
      state.currentUsers.find((u) => u.userId === userId) != undefined,
    userOnlineAndInTeamId: (state) => (userId, teamId) =>
      state.currentUsers.filter((u) => u.userId == userId && u.teamId == teamId)
        .length > 0,
    teamHasLockedFields: (state) => (teamId) =>
      state.lockedFields.filter((u) => u.id.split(":")[0] == teamId).length > 0
  },
  actions: {
    [CLEAR_STATE](context) {
      // TODO: does this work?
      context.commit(CLEAR_USERS);
      context.commit(CLEAR_LOCKS);
    }
  },
  mutations: {
    // we can use the ES2015 computed property name feature
    // to use a constant as the function name
    [LOCK_FIELD](state, lockDetails) {
      const { userId, typeName, teamId, roleId, field } = lockDetails;
      const id = `${teamId}:${typeName}:${roleId}:${field}`;
      state.lockedFields = [
        ...state.lockedFields.filter((l) => l.userId != userId),
        { userId, id }
      ];
    },
    [UNLOCK_FIELD](state, lockDetails) {
      const { userId } = lockDetails;

      state.lockedFields = state.lockedFields.filter((l) => l.userId != userId);
    },
    [CLEAR_USERS](state) {
      state.currentUsers = [];
    },
    [CLEAR_LOCKS](state) {
      state.lockedFields = [];
    },
    [USER_LOGON](state, userDetails) {
      console.log(`[STORE][LOGON_USER] ${JSON.stringify(userDetails)}`);
      state.currentUsers = [
        ...state.currentUsers.filter((u) => u.userId != userDetails.userId),
        userDetails
      ];
    },
    [USER_LOGOFF](state, userDetails) {
      console.log(`[STORE][LOGOFF_USER] ${JSON.stringify(userDetails)}`);
      state.currentUsers = state.currentUsers.filter(
        (u) => u.userId != userDetails.userId
      );
    },
    [USER_ACTIVEINTEAM](state, userDetails) {
      console.log(`[STORE][USER_ACTIVEINTEAM] ${JSON.stringify(userDetails)}`);
      state.currentUsers = [
        ...state.currentUsers.filter((u) => u.userId != userDetails.userId),
        userDetails
      ];
    },
    [USER_INACTIVEINTEAM](state, userDetails) {
      console.log(
        `[STORE][USER_INACTIVEINTEAM] ${JSON.stringify(userDetails)}`
      );
      state.currentUsers = [
        ...state.currentUsers.filter((u) => u.userId != userDetails.userId),
        userDetails
      ];
    }
  }
});

export {
  store,
  USER_LOGON,
  USER_LOGOFF,
  USER_ACTIVEINTEAM,
  USER_INACTIVEINTEAM,
  CLEAR_STATE,
  LOCK_FIELD,
  UNLOCK_FIELD
};
