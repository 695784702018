<template>
  <div class="home">
    <div>last commit id: {{ lastCommitId }}</div>
    <div>last build start: {{ codeBuildStart }}</div>
    <div>webhook trigger: {{ webhookTrigger }}</div>
  </div>
</template>

<script>
export default {
  name: "Home",
  setup() {
    return {
      lastCommitId: process.env.VUE_APP_LAST_COMMIT_ID.substring(0, 6),
      codeBuildStart: new Date(
        parseInt(process.env.VUE_APP_CODEBUILD_START_TIME)
      ).toLocaleString(),
      webhookTrigger: process.env.VUE_APP_CODEBUILD_WEBHOOK_TRIGGER
    };
  }
};
</script>
