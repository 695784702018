<template>
  <div class="logo">
    <img class="role-up-logo" src="../assets/logo.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "AppLogo",
  setup() {}
};
</script>

<style scoped>
.role-up-logo {
  margin-top: 4px;
  /* first line aligns with team title */
  height: 50px;
}
@media screen and (max-width: 767px) {
  .role-up-logo {
    margin-top: 4px;
    height: 32px;
  }
}
</style>
