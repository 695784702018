<template>
  <div class="notification small" :class="displayClass">
    <span v-html="message"></span>
    <slot name="action"></slot>
  </div>
</template>

<script>
export default {
  name: "AppNotificationBar",
  props: {
    message: String,
    displayClass: {
      type: String,
      required: false
    }
  },
  setup() {}
};
</script>

<style scoped>
.notification {
  --bar-height: 28px;
  display: block;
  height: var(--bar-height);
  line-height: var(--bar-height);
  /* background-color: var(--color-text); */
  /* color: #c1b9fc; */
  background-color: #92f0b8;
  width: 100%;
  padding: 0 var(--spacing-content);
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
}
.frozen,
.trialfreeze {
  background-color: #ffdc2e;
}
.cancelled {
  background-color: var(--color-destroy);
  color: var(--color-white);
}
</style>
