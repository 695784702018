<template>
  <div class="org-state-wrapper">
    <app-org-state
      v-if="!loading"
      :orgOwner="this.team.organisation.adminUser"
      :orgState="
        this.team.organisation.organisationState.derivedOrganisationState ||
        OrgStateEnum.BETA
      "
      :user="this.user"
      :orgId="this.team.organisation.id"
    />
    <div class="org-state-inner">
      <div class="off-canvas-wrapper">
        <div class="page-body">
          <div class="page-container">
            <div v-if="loading">
              <app-spinner />
            </div>

            <team-header
              v-if="!loading"
              :team="teamWithLocks"
              :user="wrappedUser"
            />
            <team-role-container v-if="!loading" :team="teamWithLocks" />
          </div>
        </div>
        <div class="aside">
          <app-off-canvas v-if="!loading && enableOffCanvas" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamHeader from "../components/TeamHeader.vue";
import TeamRoleContainer from "../components/TeamRoleContainer.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { apolloClient, queryTeam, subscriptionUpdateTeam } from "../apollo";
import { computed } from "vue";
import AppSpinner from "../components/AppSpinner.vue";
import { store } from "../store";
import AppOrgState from "../components/AppOrgState.vue";
import { OrgStateEnum } from "../shared/orgstate";
import { orgIsLocked } from "../generic/organisationLogic";
import AppOffCanvas from "../components/AppOffCanvas.vue";

export default {
  name: "Team",
  props: ["id"],
  components: {
    TeamRoleContainer,
    TeamHeader,
    AppSpinner,
    AppOrgState,
    AppOffCanvas
  },
  inject: ["websocket"],
  data() {
    return {
      // disables off-canvas talking points
      enableOffCanvas: false,

      showOrgStateModal: true,
      OrgStateEnum: OrgStateEnum
    };
  },
  computed: {
    wrappedUser() {
      return {
        ...this.team.organisation.organisationMembers.find(
          (tm) => tm.userId == this.user.id
        ),
        online: true
      };
    },
    orgIsLocked() {
      return orgIsLocked(this.team.organisation);
    },
    teamWithLocks() {
      const nameLockUserId = store.getters.fieldLockedByUserId(
        this.team.id,
        "Team",
        "",
        "name"
      );
      const missionLockUserId = store.getters.fieldLockedByUserId(
        this.team.id,
        "Team",
        "",
        "mission"
      );
      return {
        ...this.team,
        teamMembers: this.team.teamMembers.map((m) => ({
          ...m,
          online:
            store.getters.userOnlineAndInTeamId(m.userId, this.id) ||
            m.userId == this.user.id
        })),
        nameLockedBy: this.team.teamMembers.find(
          (t) => t.userId == nameLockUserId
        ),
        missionLockedBy: this.team.teamMembers.find(
          (t) => t.userId == missionLockUserId
        )
      };
    }
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      userCanEdit: computed(
        () =>
          // return false if orgState = locked
          this.team.teamMembers
            .map((member) => member.user.id)
            .includes(this.user.id) && !this.orgIsLocked
      ),
      userIsOwner: computed(() => this.team.adminUser.id == this.user.id)
    };
  },
  setup(props) {
    const { result, loading, error, subscribeToMore } = useQuery(queryTeam, {
      id: props.id
    });

    const team = useResult(result, [], (data) => data.getTeam);

    const user = useResult(result, {}, (data) => data.getMe.user);

    // change of team name and mission
    subscribeToMore(() => ({
      document: subscriptionUpdateTeam,
      variables: {
        id: props.id
      },
      updateQuery: (previousResult) => {
        if (!previousResult.getTeam.adminUser) {
          // team is deleted, force reload of org
          console.log(
            `evicting cache for organisation ${previousResult.getTeam.organisation.name}`
          );
          apolloClient.cache.evict({
            id: `Organisation:${previousResult.getTeam.organisation.id}`
          });
        }
        return previousResult;
      }
    }));

    return {
      team,
      user,
      loading,
      error
    };
  }
};
</script>

<style>
/* global styles are loaded in main.js */
/* @import "../assets/css/styles.css"; */

.org-state-wrapper {
  display: flex;
  width: 100%;
  height: var(--viewport-height);
  flex-direction: column;
  align-items: stretch;
}
.org-state-inner {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.off-canvas-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
}
.page-body {
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.aside {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}
.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* layout */
/* @supports (-webkit-touch-callout: none) {
  .page-container {
    min-height: -webkit-fill-available;
  }
} */
</style>
