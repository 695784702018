<template>
  <div class="user-search">
    <app-input-text
      :placeholderText="'Search for a member'"
      v-model="searchString"
    />
  </div>

  <ul class="users">
    <li v-if="user && searchString == ''">
      <app-user-list-item :user="user">
        <template v-slot:actions>
          <span class="owner" v-if="user.userId == organisation.adminUser.id"
            >Owner</span
          >
        </template>
      </app-user-list-item>
    </li>
    <li v-for="item in getMembersWithoutUser" :key="item.userId">
      <app-user-list-item :user="item">
        <template v-slot:actions>
          <span class="owner" v-if="item.userId == organisation.adminUser.id"
            >Owner</span
          >
        </template>
      </app-user-list-item>
    </li>
  </ul>
  <!-- pending invitations -->
  <div v-if="getPendingInvites.length > 0">
    <h3 class="spaced-top">Sent invitations</h3>
    <ul>
      <li v-for="item in getPendingInvites" :key="item.id">
        <app-line-item>
          <template v-slot:label>
            {{ item.user.email }}<br />
            <span class="small url"
              >https://app.role-up.com/signup?email={{ item.user.email }}</span
            >
          </template>
          <template v-slot:actions>
            <app-button
              v-if="user.userId == organisation.adminUser.id"
              :buttonLabel="'Remove'"
              :buttonClass="'text-only'"
              @click="removeMember(item)"
            />
          </template>
        </app-line-item>
      </li>
    </ul>
  </div>
  <p class="spaced-top" v-if="getPendingInvites.length == 0">
    There are no pending invitations.
  </p>
</template>

<script>
import AppUserListItem from "./AppUserListItem.vue";
import { store } from "../store";
import AppLineItem from "./AppLineItem.vue";
import AppButton from "./AppButton.vue";
import { useMutation } from "@vue/apollo-composable";
import { mutationRemoveUserFromOrganisation } from "../apollo";
import AppInputText from "./AppInputText.vue";

export default {
  components: { AppUserListItem, AppLineItem, AppButton, AppInputText },
  name: "OrgMemberList",
  props: {
    user: { type: Object },
    organisation: { type: Object }
  },
  inject: ["userCanEdit"],
  data() {
    return {
      searchString: ""
    };
  },
  computed: {
    getMembersWithoutUser() {
      return this.organisation.organisationMembers
        .filter(
          (member) =>
            member.userId != this.user.userId &&
            member.user.claimsub &&
            !member.dateRemoved &&
            member.user.fullName
              .toLowerCase()
              .includes(this.searchString.toLowerCase())
        )
        .map((u) => ({ ...u, online: store.getters.userOnline(u.userId) }));
    },

    getPendingInvites() {
      return this.organisation.organisationMembers
        .filter(
          (member) =>
            member.userId != this.user.userId &&
            !member.user.claimsub &&
            !member.dateRemoved
        )
        .map((u) => ({ ...u, online: store.getters.userOnline(u.userId) }));
    }
  },
  setup(props) {
    const { mutate: leaveOrg } = useMutation(
      mutationRemoveUserFromOrganisation
    );

    function removeMember(member) {
      console.log(
        "BillingMembers.vue: member " +
          member.id +
          " needs to be removed from org " +
          props.organisation.id
      );
      leaveOrg({ id: member.user.id, organisationId: props.organisation.id })
        .then((s) => {
          console.log(
            `OrgMemberList. User succesfully removed from organisation ${JSON.stringify(
              s
            )}`
          );
        })
        .catch((error) => {
          console.log(`failed to remove user from organisation ${error}`);
          // TODO: should return something here
        });
    }

    return {
      removeMember
    };
  }
};
</script>

<style scoped>
.user-search {
  margin-bottom: 1rem;
}
.spaced-top {
  margin-top: 2.5rem;
}
.owner {
  padding-right: var(--spacing-s);
}
.url {
  opacity: 0.4;
}
</style>
