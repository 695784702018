<template>
  <div class="select-wrapper" :class="{ rtl: rightAligned }">
    <select>
      <option
        v-for="item in optionsArray"
        :key="item"
        :selected="item == optionSelected"
      >
        {{ item }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "AppSelect",
  props: {
    optionsArray: {
      type: Array,
      required: true
    },
    optionSelected: {
      type: String,
      required: false
    },
    rightAligned: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
select {
  display: block;
  width: 100%;
  /* min-width: 12rem; */
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: var(--border-radius);
  background-image: url("../assets/icons/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right;
  color: var(--color-link);
}
select:hover {
  background-color: var(--color-input-background-active);
}
select:active {
  border-radius: cal(var(--border-radius) / 2);
}
.rtl select {
  direction: rtl;
}
</style>
