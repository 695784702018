<template>
  <div class="menu inverted-text">
    <app-menu-item
      :icon="'open-in-new-white'"
      :menuItemText="'Role Workshop'"
      :inverted="true"
      :iconPos="'right'"
      :suppressLine="true"
      @click="
        openUrl(
          'https://www.role-up.com/resources/workshop-start-working-with-roles/'
        )
      "
    />
    <app-menu-item
      :icon="'open-in-new-white'"
      :menuItemText="'Governance Meeting'"
      :inverted="true"
      :iconPos="'right'"
      @click="
        openUrl(
          'https://www.role-up.com/resources/how-to-run-an-effective-governance-meeting/'
        )
      "
    />
    <app-menu-item
      :icon="'open-in-new-white'"
      :menuItemText="'Resources'"
      :inverted="true"
      :iconPos="'right'"
      @click="openUrl('https://www.role-up.com/resources/')"
    />
  </div>
</template>

<script>
import AppMenuItem from "./AppMenuItem.vue";

export default {
  name: "AppHelpMenu",
  components: { AppMenuItem },
  setup() {
    function openUrl(url) {
      window.open(url, "_blank");
    }

    return { openUrl };
  }
};
</script>

<style scoped>
.menu {
  width: 90%;
  max-width: 280px;
  padding: var(--spacing-content);
  background-color: var(--color-text);
  color: var(--color-card-background);
  border-radius: var(--border-radius-l);
  text-align: center;
}
</style>
