<template>
  <app-spinner />
</template>

<script>
import { keycloak } from "../keycloak";
import router from "../router";
import AppSpinner from "../components/AppSpinner.vue";

export default {
  name: "Home",
  components: { AppSpinner },
  setup() {
    console.log(
      `[Home] keycloak authenticated [${keycloak.authenticated}]. forwarding to Orgs`
    );
    router.push({
      name: "Orgs"
    });
  }
};
</script>
