<template>
  <div class="team-header">
    <router-link
      :to="{ name: 'Orgs', params: { id: team.organisation.id } }"
      class="no-line"
    >
      <div class="logo-container">
        <app-logo role="button" aria-label="Back to Organization page" /></div
    ></router-link>
    <div class="team-info">
      <h1>
        <app-textarea
          :inputValue="team.name"
          :editingUser="team.nameLockedBy"
          :labelText="'Team name'"
          :placeholderText="'My Team'"
          :labelPosition="'bottom'"
          :maxLength="36"
          :id="{ id: team.id, mission: team.mission }"
          @update:apptextarea="updateTeamName"
          @lock="lockField($event, 'name')"
        />
      </h1>
      <p class="team-mission">
        <app-textarea
          :inputValue="team.mission"
          :editingUser="team.missionLockedBy"
          :labelText="'Mission of the team'"
          :labelPosition="'top'"
          :placeholderText="'Put a man on the moon'"
          :maxLength="72"
          :id="{ id: team.id, name: team.name }"
          @update:apptextarea="updateTeamMission"
          @lock="lockField($event, 'mission')"
        />
      </p>
    </div>
    <div v-if="!team.adminUser">Team is deleted by an administrator</div>
    <!-- TODO: where to add members from the org to the team? -->
    <!-- team.organisation.members -->
    <div class="right-menu">
      <div class="team-members">
        <app-user-badge-container
          :users="teamMembersPresentInTeam"
          :overlapBadges="true"
          class="user-badges"
        >
          <template v-slot:add>
            <app-icon-button
              v-if="userCanEdit"
              :icon="'plus'"
              :buttonClass="'plus'"
              :labelPosition="'bottom'"
              :labelText="'Invite Team Members'"
              :labelWrap="true"
              @click="showInviteModal = !showInviteModal"
            />
          </template>
        </app-user-badge-container>
        <!-- invite modal -->
        <transition name="modal">
          <team-invite-modal
            v-if="showInviteModal"
            @email="(value) => (lastEmail = value.toLowerCase())"
            @close="showInviteModal = false"
            @next="(showInviteModal = false), (showInviteSuccesModal = true)"
            :team="team"
          />
        </transition>
        <!-- end modal -->
        <!-- invite succes modal -->
        <transition name="succes-modal">
          <app-modal
            v-if="showInviteSuccesModal"
            @close="(showInviteSuccesModal = false), (lastEmail = '')"
          >
            <template v-slot:header>
              <h3>Invite Sent</h3>
            </template>
            <template v-slot:body>
              <div class="spaced-top" v-if="this.hasAccount(this.lastEmail)">
                <!-- has account -->
                <p>
                  {{ this.lastEmail }} already has a Role-up account<br />
                  and is now a member of this team.
                </p>
              </div>
              <div class="spaced-top" v-if="!this.hasAccount(this.lastEmail)">
                <!-- no account found -->
                <p>
                  Mail servers can delay invitations, or place them in spam.<br />
                  You can send this link yourself, if you want.
                </p>
                <p class="spaced-top url">
                  https://app.role-up.com/signup?email={{ this.lastEmail }}
                </p>
                <p class="spaced-top">
                  You can always retrieve this link in the team settings.
                </p>
              </div>
            </template>
            <template v-slot:footer>
              <app-button
                :buttonLabel="'Close'"
                @click="showInviteSuccesModal = false"
              />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
      </div>
      <div class="team-settings">
        <app-icon-button
          v-if="userCanEdit"
          :icon="'tune-vertical'"
          :labelText="'Team Settings'"
          :labelPosition="'bottom'"
          @click="showSettingsModal = !showSettingsModal"
        />
        <!-- settings modal -->
        <transition name="settings-modal">
          <app-modal
            v-if="showSettingsModal"
            @close="showSettingsModal = false"
          >
            <template v-slot:header>
              <h3>Team Settings</h3>
            </template>
            <template v-slot:body>
              <div class="invite-on-settings-modal">
                <app-button
                  v-if="userCanEdit"
                  :buttonLabel="'Invite Team Members'"
                  @click="(showSettingsModal = false), (showInviteModal = true)"
                />
              </div>
              <team-member-list
                @leaveThisTeam="
                  (showSettingsModal = false), (showLeaveTeamModal = true)
                "
                @deleteTeam="
                  (showSettingsModal = false), (showDeleteTeamModal = true)
                "
                :user="user"
                :team="team"
              />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
        <!-- leave team modal -->
        <transition name="modal">
          <app-modal
            v-if="showLeaveTeamModal"
            @close="showLeaveTeamModal = false"
          >
            <template v-slot:header>
              <h3>Leave</h3>
            </template>
            <template v-slot:body
              >Are you sure you want to leave this team?</template
            >
            <template v-slot:footer>
              <app-button
                :buttonLabel="'Cancel'"
                @click="showLeaveTeamModal = false"
              />
              <app-button
                :buttonLabel="'Leave'"
                :buttonClass="'destroy'"
                @click="(showLeaveTeamModal = false), leaveThisTeam()"
              />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
        <!-- delete team modal -->
        <transition name="modal">
          <app-modal
            v-if="showDeleteTeamModal"
            @close="showDeleteTeamModal = false"
          >
            <template v-slot:header>
              <h3>Delete this team</h3>
            </template>
            <template v-slot:body
              >Are you sure you want to delete this team for everyone?
              <br />This action cannot be undone.</template
            >
            <template v-slot:footer>
              <app-button
                :buttonLabel="'Cancel'"
                @click="showDeleteTeamModal = false"
              />
              <app-button
                :buttonLabel="'Delete Team'"
                :buttonClass="'destroy'"
                @click="(showDeleteTeamModal = false), deleteThisTeam()"
              />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
      </div>
      <div class="account">
        <app-icon-button
          :icon="'account-outline'"
          :labelText="'My Account'"
          :labelPosition="'bottom'"
          :labelWrap="true"
          @click="showAccountModal = !showAccountModal"
        />
        <!-- account modal -->
        <transition name="modal">
          <app-account-modal
            v-if="showAccountModal"
            @close="showAccountModal = false"
            :user="user"
            :organisation="team.organisation"
          />
        </transition>
        <!-- end modal -->
      </div>
    </div>
  </div>
</template>

<script>
import AppTextarea from "./AppTextarea.vue";
import AppUserBadgeContainer from "./AppUserBadgeContainer.vue";
import AppIconButton from "./AppIconButton.vue";
import AppAccountModal from "./AppAccountModal.vue";
import TeamInviteModal from "./TeamInviteModal.vue";
import AppLogo from "./AppLogo.vue";
import { useMutation } from "@vue/apollo-composable";
import {
  apolloClient,
  mutationDeleteTeam,
  mutationRemoveUserFromTeam,
  mutationUpdateTeam
} from "../apollo";
import AppModal from "./AppModal.vue";
import TeamMemberList from "./TeamMemberList.vue";
import AppButton from "./AppButton.vue";
import router from "../router";
import { store } from "../store";
import { inject } from "vue";

export default {
  name: "TeamHeader",
  components: {
    AppTextarea,
    AppUserBadgeContainer,
    AppIconButton,
    AppAccountModal,
    TeamInviteModal,
    AppLogo,
    AppModal,
    TeamMemberList,
    AppButton
  },
  props: {
    team: {
      type: Object,
      required: true
    },
    user: { type: Object }
  },
  inject: ["userCanEdit", "userIsOwner", "websocket"],
  computed: {
    teamMembersPresentInTeam() {
      return this.team.teamMembers
        .filter((m) => m.user.claimsub)
        .map((m) => ({
          ...m,
          online:
            store.getters.userOnlineAndInTeamId(m.userId, this.team.id) ||
            m.userId == this.user.userId
        }));
    }
  },
  mounted: function () {
    console.log("mounted teamheader", this.team.id);
    this.websocket.startConnection(
      this.user.user.id,
      this.team.organisation.id,
      this.team.id
    );
  },
  data() {
    return {
      showAccountModal: false,
      showInviteModal: false,
      showInviteSuccesModal: false,
      showSettingsModal: false,
      showLeaveTeamModal: false,
      showDeleteTeamModal: false,
      lastEmail: ""
    };
  },
  setup(props) {
    const ws = inject("websocket");
    function lockField(value, field) {
      ws.lockField("Team", props.team.id, undefined, field, value.lock);
    }
    const { mutate: updateTeam } = useMutation(mutationUpdateTeam);
    const { mutate: deleteTeam } = useMutation(mutationDeleteTeam);
    const { mutate: leaveTeam } = useMutation(mutationRemoveUserFromTeam);
    function updateTeamName(value) {
      updateTeam({
        id: props.team.id,
        mission: value.id.mission || "",
        name: value.value || ""
      });
    }
    function updateTeamMission(value) {
      updateTeam({
        id: props.team.id,
        mission: value.value || "",
        name: value.id.name || ""
      });
    }

    function leaveThisTeam() {
      console.log(
        "[TeamHeader] " +
          props.user.user.fullName +
          " would like to leave this team"
      );
      leaveTeam({
        id: props.user.user.id,
        teamId: props.team.id
      })
        .then((s) =>
          console.log(`User succesfully removed from team ${JSON.stringify(s)}`)
        )
        .catch((error) => {
          console.log(`failed to remove user from team ${error}`);
          // TODO: should return something here
        });
    }
    function deleteThisTeam() {
      console.log(
        "[TeamHeader]: team " +
          props.team.name +
          " (and all its roles etc.) was deleted by the owner"
      );
      deleteTeam({
        organisationId: props.team.organisation.id,
        id: props.team.id
      })
        .then((s) => {
          console.log(`User succesfully deleted team ${JSON.stringify(s)}`);
          // force reload
          apolloClient.cache.reset();
          router.push({
            name: "Orgs",
            params: { id: s.data.deleteTeam.organisationId }
          });
        })
        .catch((error) => {
          console.log(`failed to delete team ${error}`);
          // TODO: should return something here
        });
    }
    function hasAccount(email) {
      return (
        this.team.teamMembers.filter(
          (m) => m.user.email == email && m.user.claimsub
        ).length > 0
      );
    }

    return {
      lockField,
      updateTeamName,
      updateTeamMission,
      leaveThisTeam,
      deleteThisTeam,
      hasAccount
    };
  }
};
</script>

<style scoped>
/* header layout */
.team-header {
  padding: var(--spacing-content);
  padding-bottom: var(--spacing-s);
  display: flex;
  align-items: stretch;
  border-bottom: 2px solid var(--color-container-background);
}
.team-info {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  padding-right: var(--spacing-content);
  padding-left: 16px;
  margin-left: 16px;
}
.team-info::before {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% - 18px);
  height: calc(100% - 26px);
  position: absolute;
  left: 0;
  top: 4px;
  background-color: var(--color-text);
}
.team-info * {
  max-width: calc(
    (var(--card-width-min) - var(--spacing-content)) * 2
  ); /* last number is number of cards */
}
.right-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-end;
}
.team-members {
  flex-grow: 1;
}
/* team-members */
.invite {
  margin: 0 var(--spacing-s);
}
/* team-settings */
.team-settings {
  padding-left: var(--spacing-m);
}
.invite-on-settings-modal {
  padding-top: var(--spacing-content);
  padding-bottom: calc(var(--spacing-content) * 2);
}
.spaced-top {
  margin-top: var(--line-height);
}
.url {
  color: var(--color-link);
}

/* MOBILE VERSION */
@media screen and (max-width: 767px) {
  .team-header {
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .team-info {
    flex-basis: 90%;
    padding-left: var(--spacing-content);
    padding-right: var(--spacing-content);
    padding-top: var(--spacing-m);
    margin-left: 0;
    order: 9;
    border-top: 2px solid var(--color-container-background);
    /* background-color: var(--color-4-megalight); */
  }
  .team-info::before {
    display: none;
  }
  /* .team-members, */
  .user-badges {
    display: none;
  }
  .right-menu,
  .logo-container {
    padding: var(--spacing-s) var(--spacing-m);
  }
  .logo-container {
    padding-left: var(--spacing-content);
  }
}
</style>
