<template>
  <div
    class="org-badge"
    :class="[{ active: activeOrg }, 'data-viz-' + (index + 1)]"
    @mouseenter="showLabel = true"
    @mouseleave="showLabel = false"
  >
    <div class="masked">
      <span class="uppercase small">{{ initial }}</span>
    </div>
    <span class="uppercase small">{{ initial }}</span>
    <app-floating-lable
      v-show="showLabel"
      v-if="org.name"
      :labelText="org.name"
      :labelPosition="'right'"
      :labelWrap="false"
      :centerLabel="true"
    />
  </div>
</template>

<script>
import AppFloatingLable from "./AppFloatingLable.vue";

export default {
  components: { AppFloatingLable },
  name: "AppOrgBadge",
  props: {
    org: {
      type: Object,
      required: true
    },
    activeOrg: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      showLabel: false
    };
  },
  computed: {
    initial() {
      console.log(`${this.org.name}-${this.org.organisationState.derivedOrganisationState}`);
      if (!this.org.name) return "new";
      const name = this.org.name.trim();
      if (name) {
        if (name.length == 2) return name;
        const i = name.indexOf(" ");
        if (i == -1) {
          const matches = name.matchAll(/[A-Z\s]/g);
          if (matches) {
            const a = Array.from(matches).map(([key]) => key);
            if (a.length > 1) return a[0] + a[1];
          }
          return name.substring(0, 1);
        }
        if (i > 0) return name.substring(0, 1) + name.substring(i + 1, i + 2);
      }
      return "odd";
    }
  }
};
</script>

<style scoped>
@import "../assets/css/DataVizColors.css";

.org-badge {
  --badge-size: 40px;
}
.org-badge {
  display: block;
  position: relative;
  height: var(--badge-size);
  width: var(--badge-size);
  border-radius: 50%;
  line-height: calc(var(--badge-size) - 2px);
  text-align: center;
  cursor: pointer;
}
.uppercase {
  text-transform: uppercase;
}
.masked {
  --diameter: 36px;
  width: var(--diameter);
  height: var(--diameter);
  position: absolute;
  border-radius: 50%;
  top: calc((var(--badge-size) - var(--diameter)) / 2);
  left: calc((var(--badge-size) - var(--diameter)) / 2);
  line-height: var(--diameter);
  background-color: var(--color-white);
  color: var(--color-text);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.7);
}
.org-badge.active .masked,
.org-badge:hover .masked {
  display: none;
}
</style>
