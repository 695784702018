<template>
  <input
    v-model="value"
    type="text"
    :placeholder="placeholderText"
    ref="activeInput"
    maxlength="256"
  />
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "AppInputText",
  props: {
    modelValue: {
      type: String
    },
    placeholderText: {
      type: String,
      default: "Type Something..."
    },
    autoFocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  emits: ["update:modelValue"],
  setup(props) {
    const activeInput = ref(null);
    onMounted(() => {
      if (props.autoFocus == true) {
        activeInput.value.focus();
      }
    });
    return {
      activeInput
    };
  }
};
</script>

<style scoped>
input {
  width: 100%;
  min-height: 44px;
  margin: 2px 0;
  padding: var(--spacing-s);
  background-color: var(--color-input-background-active);
  text-align: inherit;
  border-radius: var(--border-radius);
}
input:hover,
input:placeholder-shown {
  background-color: var(--color-input-background-active);
}
input:focus {
  background-color: transparent;
  border-radius: calc(var(--border-radius) / 2);
}
</style>
