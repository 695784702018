<template>
  <ul
    class="badge-container"
    :class="{ overlap: overlapBadges, 'no-users': noUsers }"
  >
    <li class="user-badge" v-for="item in users" :key="item.userId">
      <app-user-badge :user="item" />
    </li>
    <li class="empty" v-if="noUsers">
      <slot name="empty">{{ placeholderText }}</slot>
    </li>
    <li class="add">
      <slot name="add"></slot>
    </li>
  </ul>
</template>

<script>
import AppUserBadge from "./AppUserBadge.vue";

export default {
  name: "AppUserBadgeContainer",
  components: {
    AppUserBadge
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    overlapBadges: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: "No-one here"
    }
  },
  computed: {
    noUsers() {
      return !(this.users && this.users.length > 0);
    }
  }
};
</script>

<style scoped>
.badge-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--spacing-xs) 0;
}
li.user-badge + li.add {
  margin-left: var(--spacing-xs);
}
.badge-container li.user-badge:not(:first-of-type) {
  margin-left: var(--spacing-xs);
}

/* overlapping badges */
.badge-container.overlap {
  padding-left: var(--spacing-s);
}
.badge-container.overlap li.user-badge {
  margin-left: calc(var(--spacing-s) * -1);
}

/* empty state */
.badge-container.no-users {
  /* background-color: var(--color-input-background-active); */
  color: var(--color-input-placeholder);
  padding: var(--spacing-xs);
  padding-left: 0;
}
li.empty {
  flex-grow: 1;
}
</style>
