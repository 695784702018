<template>
  <div class="button-bar">
    <app-icon-button
      :icon="'delete-outline'"
      :labelText="'Delete'"
      :labelPosition="'top'"
      @click="showModal = true"
    />
    <!-- modal component remove role-->
    <transition name="modal">
      <app-modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>Delete {{ roleTitle }}</h3>
        </template>
        <template v-slot:body>
          <p>Are you sure you want to permanently delete this role?</p>
        </template>
        <template v-slot:footer>
          <app-button :buttonLabel="'Cancel'" @click="showModal = false" />
          <app-button
            :buttonLabel="'Delete'"
            :buttonClass="'destroy'"
            @click="(showModal = false), $emit('delete:role')"
          />
        </template>
      </app-modal>
    </transition>
    <!-- end modal -->

    <!-- <app-icon-button
      :icon="'arrow-left-right'"
      :labelText="'Drag'"
      :labelPosition="'top'"
      class="handle"
    /> -->
    <div class="dummy"></div>
  </div>
</template>

<script>
import AppButton from "./AppButton.vue";
import AppModal from "./AppModal.vue";
import AppIconButton from "./AppIconButton.vue";

export default {
  name: "TeamRoleButtonBar",
  components: {
    AppButton,
    AppModal,
    AppIconButton
  },
  props: {
    roleTitle: {
      type: String,
      default: "this role"
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  setup() {},
  emits: ["delete:role"]
};
</script>

<style scoped>
.button-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dummy {
  width: var(--button-size);
}
</style>
