import Keycloak from "keycloak-js";

const redirectUri = process.env.VUE_APP_KEYCLOAK_REDIRECT_URI;

const keycloak = Keycloak({
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
  initOptions: {
    redirectUri,
    checkLoginIframe: false,
    enableLogging: true
  }
});

export { keycloak, redirectUri };
