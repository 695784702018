import { OrgStateEnum } from "../shared/orgstate";

export function retrieveMembers(organisation) {
  console.log(`Retrieving members from organisation id ${organisation.id}`);
  const totalFreeDays = 30;
  const newMembers = [];
  const leftMembers = [];
  const paidMembers = [];
  const invitedMembers = [];
  let startPeriod = new Date();
  let endPeriod = new Date().setMonth(new Date().getMonth() + 1);

  if ( organisation.paymentDetails &&
    organisation.paymentDetails.nextPaymentDate &&
    organisation.paymentDetails.lastPaymentDate
  ) {
    startPeriod = new Date(organisation.paymentDetails.lastPaymentDate);
    endPeriod = new Date(organisation.paymentDetails.nextPaymentDate);
  }
  console.log(`startPeriod [${startPeriod}] endPeriod [${endPeriod}]`);
  if (organisation.organisationMembers) {
    console.log(` total of ${organisation.organisationMembers.length} members`);
    const {
      adminUser: { id: adminUserId }
    } = organisation;
    organisation.organisationMembers.forEach((member) => {
      const {
        dateAdded: da,
        dateRemoved: dr,
        dateEndTrial: de,
        user: { id, fullName, claimsub, email }
      } = member;
      const dateAdded = new Date(da);
      const dateRemoved = dr ? new Date(dr) : undefined;
      const dateEndTrial = new Date(de);

      const daysLeft =
        totalFreeDays -
        Math.min(
          totalFreeDays,
          Math.round((new Date() - dateAdded) / (1000 * 60 * 60 * 24))
        );
      var status = undefined;
      var expectedOnInvoice = false;
      if (id == adminUserId) {
        status = "PAID";
        expectedOnInvoice = true;
      } else {
        if (!claimsub) {
          status = "INVITED";
        } else {
          // still in trialperiod -> new Member.
          if (dateEndTrial >= new Date()) {
            status = "NEW";
            expectedOnInvoice = dateEndTrial < endPeriod;
          }
          // after trial period and not removed -> paid Member
          if (dateEndTrial < new Date() && dateRemoved == undefined) {
            status = "PAID";
            expectedOnInvoice = true;
          }
          // left this month
          if (dateRemoved > startPeriod && dateRemoved > dateEndTrial) {
            status = "LEFT";
            expectedOnInvoice = true;
          }
        }
        if (dateRemoved < startPeriod) {
          status = "LEFTBEFORETHISMONTH";
        }
      }

      const user = {
        id,
        email,
        fullName,
        daysLeft,
        totalFreeDays,
        expectedOnInvoice,
        trialEndDate: dateEndTrial,
        joinDate: dateAdded,
        returnee: dateAdded > startPeriod && dateEndTrial < startPeriod,
        leaveDate: dateRemoved
      };

      console.log(
        `user email [${email}] hasClaimSub [${
          claimsub != undefined ? "X" : " "
        }] isAdmin [${
          id == adminUserId ? "X" : " "
        }] trialExpiredAtEndOfPeriod [${
          dateEndTrial < endPeriod ? "X" : " "
        }] removedThisPeriod [${
          dateRemoved >= startPeriod ? "X" : " "
        }] daysLeft [${daysLeft}] expectedOnInvoice [${expectedOnInvoice}] status [${status}]`
      );
      switch (status) {
        case "PAID":
          paidMembers.push(user);
          break;
        case "INVITED":
          invitedMembers.push(user);
          break;
        case "NEW":
          newMembers.push(user);
          break;
        case "LEFT":
          leftMembers.push(user);
          break;
        case "LEFTBEFORETHISMONTH":
          break;
        default:
          console.error(`no category for ${id} ${email}`);
          break;
      }
    });
  }
  return {
    quantity:
      leftMembers.length +
      paidMembers.length +
      newMembers.filter((m) => m.expectedOnInvoice).length,
    lastInvoiceDate: startPeriod,
    nextInvoiceDate: endPeriod,
    newMembers: newMembers.sort((a, b) => b.joinDate - a.joinDate),
    leftMembers: leftMembers.sort((a, b) => b.joinDate - a.joinDate),
    paidMembers: paidMembers.sort((a, b) => b.joinDate - a.joinDate),
    invitedMembers: invitedMembers.sort((a, b) => b.joinDate - a.joinDate)
  };
}

export function maxTeamsReached(organisation) {
  const teams = organisation.organisationTeams.length;
  const reached =
    organisation.organisationState.derivedOrganisationState == OrgStateEnum.TRIAL &&
    teams >= parseInt(process.env.VUE_APP_FREE_ORG_MAX_TEAMS);
  console.log(
    `[maxTeamsReached] check ${organisation.name} #teams [${teams}] [${
      reached ? "Y" : "N"
    }]`
  );

  return reached;
}

export function maxMembersReached(organisation) {
  const members = organisation.organisationMembers.length;
  const reached =
    organisation.organisationState.derivedOrganisationState == OrgStateEnum.TRIAL &&
    members >= parseInt(process.env.VUE_APP_FREE_ORG_MAX_MEMBERS);
  console.log(
    `[maxMembersReached] check ${organisation.name} #members [${members}] [${
      reached ? "Y" : "N"
    }]`
  );

  return reached;
}
export function orgIsLocked(organisation) {
  return (
    organisation.organisationState.derivedOrganisationState == OrgStateEnum.TRIALFREEZE ||
    organisation.organisationState.derivedOrganisationState == OrgStateEnum.FROZEN ||
    organisation.organisationState.derivedOrganisationState == OrgStateEnum.CANCELLED
  );
}
