<template>
  <div class="modal-mask">
    <div
      class="modal-wrapper"
      @click.self="$emit('close')"
      :class="{ fixPos: fixPos }"
    >
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header"> default header </slot>
        </div>
        <div class="modal-body">
          <slot name="body"> default body </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <app-button
              :buttonLabel="'Close'"
              @click="$emit('close')"
            ></app-button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "./AppButton.vue";
export default {
  name: "AppModal",
  components: {
    AppButton
  },
  props: {
    fixPos: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"]
};
</script>

<style scoped>
/* https://codepen.io/team/Vue/pen/mdPoyvv */
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--viewport-height);
  display: table;
  background-color: rgba(220, 217, 240, 0.6);
  backdrop-filter: blur(6px);
}
@-moz-document url-prefix() {
  .modal-mask {
    background-color: rgba(220, 217, 240, 0.9);
  }
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-wrapper.fixPos {
  vertical-align: top;
  padding-top: 64px;
}
.modal-container {
  margin: 0 auto;
  width: 90%;
  max-width: 600px;
  padding: var(--spacing-content);
  background-color: var(--color-card-background);
  border-radius: var(--border-radius-l);
  text-align: center;
  /* max-height: var(--viewport-height); -- not working on iOS */
  max-height: 90vh;
  overflow-y: scroll;
}
.modal-header {
  padding: var(--spacing-content) 0;
}
.modal-body {
  margin-bottom: calc(var(--spacing-content) * 2);
}
/* standard-vue transition on enter and leave */
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.modal-enter-active {
  transition: all 0.4s ease;
}

@media screen and (max-width: 767px) {
  .modal-wrapper {
    vertical-align: bottom;
  }
  .modal-container {
    width: 100vw;
    margin-top: 4rem;
    /* max-height: calc(100vh - 4rem); */
    /* border-radius: var(--border-radius-xl) 0 var(--border-radius-xl) 0 0; */
    border-top-left-radius: var(--border-radius-xl);
    border-top-right-radius: var(--border-radius-xl);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 10rem;
  }
}
</style>
