<template>
  <div class="page-container">
    <app-header>
      <template v-slot:title>Account Settings</template>
    </app-header>
    <div class="content">
      <div v-if="loading">loading...</div>
      <div v-if="error">{{ error }}</div>
      <section>
        <!-- <app-user-badge
          :user="{ user: user, colorUI: 0, online: true }"
          :allowLabel="false"
        /> -->
        <app-user-badge :user="wrappedUser" :allowLabel="false" />
        <div class="form-wrapper">
          <label>Name</label>
          <app-input-text
            :modelValue="user.fullName"
            @update:modelValue="updateName"
          />
          <label>Email adress</label>
          <app-input-email
            :modelValue="user.email"
            @update:modelValue="updateEmail"
            @update:emailValid="validEmail"
          />
          <app-button
            :buttonLabel="'Cancel'"
            class="top-spaced"
            @click="donotsubmit()"
          />
          <app-button
          v-if="validEmailAddress"
            :buttonLabel="'Save'"
            class="top-spaced"
            @click="submit(newEmailAddress, newFullName, user)"
          />
          <app-button
          v-if="!validEmailAddress"
            :buttonLabel="'Save'"
            class="top-spaced"
            :button-class="'locked'"
            @click="submit(newEmailAddress, newFullName, user)"
          />
        </div>
      </section>
      <section>
        <p>
          If you delete your account, you will no longer have access to Role-up
          organizations or teams.
        </p>
        <app-button
          v-if="!loading && adminOfOrganisations.length == 0"
          :buttonLabel="'Delete Account'"
          class="top-spaced"
          @click="showDeleteMyAccountModal = !showDeleteMyAccountModal"
        />
        <div v-if="!loading && adminOfOrganisations.length > 0">
          Cannot delete account, please transfer ownership of the following
          organisatons or teams
          {{ adminOfOrganisations }}
        </div>
        <!-- delete account modal -->
        <transition name="modal">
          <app-modal
            v-if="showDeleteMyAccountModal"
            @close="showDeleteMyAccountModal = false"
          >
            <template v-slot:header>
              <h3>Delete my Account</h3>
            </template>
            <template v-slot:body>
              Are you sure you want to delete your Role-up account?<br />
              You will lose access to all its organizations, teams and roles.
            </template>
            <template v-slot:footer>
              <app-button
                :buttonLabel="'Cancel'"
                @click="showDeleteMyAccountModal = false"
              />
              <app-button
                :buttonLabel="'Delete'"
                :buttonClass="'destroy'"
                @click="(showDeleteMyAccountModal = false), deleteMyAccount()"
              />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
      </section>
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppInputText from "../components/AppInputText.vue";
import AppInputEmail from "../components/AppInputEmail.vue";
import AppButton from "../components/AppButton.vue";
import AppUserBadge from "../components/AppUserBadge.vue";
import router from "../router";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import {
  mutationDeleteMe,
  mutationUpdateMe,
  queryOrganisationsWithTeams
} from "../apollo";
import AppModal from "../components/AppModal.vue";

export default {
  components: {
    AppHeader,
    AppInputText,
    AppInputEmail,
    AppButton,
    AppUserBadge,
    AppModal
  },
  name: "Account",
  computed: {
    adminOfOrganisations() {
      if (this.user) {
        return this.user.organisations
          .map((org) => ({
            name: org.name,
            isAdminUserOfOrg:
              org.adminUser.id == this.user.id &&
              org.organisationMembers &&
              org.organisationMembers.filter(
                (m) => !m.dateRemoved && m.user.claimsub
              ).length > 1,
            id: org.id,
            isAdminOfTeams: org.organisationTeams
              .filter(
                (team) =>
                  team.adminUser &&
                  team.adminUser.id == this.user.id &&
                  team.teamMember &&
                  team.teamMembers.filter((m) => m.user.claimsub).length > 1
              )
              .map((team) => ({ name: team.name, id: team.id }))
          }))
          .filter((r) => r.isAdminUserOfOrg || r.isAdminOfTeams.length > 0);
      } else return [];
    },
    wrappedUser() {
      if (this.user && this.user.organisations) {
        return {
          ...this.user.organisations[0].organisationMembers.find(
            (om) => om.userId == this.user.id
          ),
          online: true
        };
      } else return {} ;
    }
  },
  data() {
    return {
      newEmailAddress: String,
      validEmailAddress: Boolean,
      newFullName: String,
      showDeleteMyAccountModal: false
    };
  },
  methods: {
    updateEmail(value) {
      this.newEmailAddress = value;
    },
    updateName(value) {
      this.newFullName = value;
    },
    validEmail(value) {
      this.validEmailAddress = value
    }
  },
  mounted() {
    if (this.user) {
      this.updateEmail(this.user.email);
      this.updateName(this.user.fullName);
    }
  },
  setup() {
    const { result, loading, error } = useQuery(queryOrganisationsWithTeams);

    const user = useResult(result, {}, (data) => ({
      ...data.getMe.user
    }));

    const { mutate: updateUser } = useMutation(mutationUpdateMe);

    const { mutate: deleteMe } = useMutation(mutationDeleteMe);

    function submit(email, fullName, user) {
      if (email != user.email || fullName != user.fullName) {
        updateUser({
          fullName: fullName || user.fullName,
          email: email || user.email
        })
          .then(() => {
            console.log("successfully updated");
          })
          .catch((e) => console.log("failed to update", e));
      } else {
        console.log("nothing to update");
      }
    }
    function donotsubmit() {
      router.back();
    }

    function deleteMyAccount() {
      deleteMe()
        .then((r) => {
          console.log("successfully deleted account, ", r);
          router.push({ name: "Logout" });
        })
        .catch((e) => console.log("failed to delete", e));
    }

    return {
      user,
      loading,
      error,
      submit,
      donotsubmit,
      deleteMyAccount
    };
  }
};
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: var(--viewport-height);
}
.content {
  padding: var(--spacing-content);
  padding-left: var(--padding-logo);
  display: flex;
  flex-direction: column;
}
.form-wrapper,
p {
  padding-top: var(--spacing-content);
  max-width: 500px;
}
label {
  padding-top: var(--line-height);
  padding-bottom: var(--spacing-s);
  display: block;
}
.top-spaced {
  margin-top: 2rem;
}
section {
  padding-bottom: 6rem;
}
</style>
