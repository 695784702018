<template>
  <div class="member-container">
    <div class="blob data-viz-8" v-if="memberCount > 3"></div>
    <div class="blob data-viz-10" v-if="memberCount > 2"></div>
    <div class="blob data-viz-1"></div>
    <div class="blob data-viz-17 button" @click="$emit('toggleUserList')">
      {{ memberCount }}
      <app-icon-button
        class="button-overlay"
        :buttonClass="'empty'"
        :icon="'empty'"
        :labelPosition="'bottom'"
        :labelText="'Members'"
      />
    </div>
    <div class="add">
      <slot name="add"></slot>
    </div>
  </div>
</template>

<script>
import AppIconButton from "./AppIconButton.vue";
export default {
  components: { AppIconButton },
  name: "OrgHeaderMembers",
  props: {
    memberCount: {
      type: Number,
      default: 4
    }
  },
  emits: ["toggleUserList"],
  setup() {}
};
</script>

<style scoped>
@import "../assets/css/DataVizColors.css";

.member-container {
  --badge-size: 40px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: var(--spacing-xs) 0;
}
.blob {
  display: block;
  position: relative;
  height: var(--badge-size);
  min-width: var(--badge-size);
  border-radius: calc(var(--badge-size) / 2);
  line-height: var(--badge-size);
  padding: 0 var(--spacing-m);
  text-align: center;
  box-shadow: 0 0 0 2px var(--color-white);
}
.blob:nth-last-of-type(5) {
  margin-right: -34px;
}
.blob:nth-last-of-type(4) {
  margin-right: -32px;
}
.blob:nth-last-of-type(3) {
  margin-right: -30px;
}
.button {
  cursor: pointer;
}
.add {
  padding: 0;
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-m);
}
.button-overlay {
  position: absolute;
  right: 0;
  top: 0;
}

/* MOBILE VERSION */
@media screen and (max-width: 767px) {
  .member-container {
    --badge-size: 36px;
    padding: 0;
  }
  .blob:nth-last-of-type(5) {
    margin-right: -30px;
  }
  .blob:nth-last-of-type(4) {
    margin-right: -28px;
  }
  .blob:nth-last-of-type(3) {
    margin-right: -26px;
  }
  .add {
    padding-right: 0;
  }
}
</style>
