<template>
  <ul class="user-list assignees" :v-if="roleAssignedUsersWithColors">
    <li v-for="item in roleAssignedUsersWithColors" :key="item.userId">
      <app-user-list-item :user="item">
        <template v-slot:actions>
          <app-button
            :buttonLabel="'Remove'"
            :buttonClass="'text-only'"
            @click="removeFromRole({ roleId: role.id, userId: item.userId })"
          />
        </template>
      </app-user-list-item>
    </li>
  </ul>
  <ul class="user-list candidates" :v-if="candidates">
    <li v-for="item in candidates" :key="item.userId">
      <app-user-list-item :user="item">
        <template v-slot:actions>
          <app-button
            :buttonLabel="'Assign'"
            :buttonClass="'text-only'"
            @click="assignToRole({ roleId: role.id, userId: item.userId })"
          />
        </template>
      </app-user-list-item>
    </li>
  </ul>
</template>

<script>
import AppButton from "./AppButton.vue";
import { useMutation } from "@vue/apollo-composable";
import {
  mutationAssignUserToRole,
  mutationRemoveUserFromRole
} from "../apollo";
import AppUserListItem from "./AppUserListItem.vue";

export default {
  name: "AppUserList",
  components: {
    AppButton,
    AppUserListItem
  },
  props: {
    members: {
      type: Array
    },
    role: { type: Object }
  },
  computed: {
    // assignees() {
    //   const assignedIds = this.role.roleAssignedUsers.map((a) => a.id);
    //   return this.members.filter(
    //     (member) => assignedIds.indexOf(member.id) > -1
    //   );
    // },
    // candidates() {
    //   const assignedIds = this.role.assignedUsers.map((a) => a.id);
    //   return this.members.filter(
    //     (member) => assignedIds.indexOf(member.id) == -1 && member.claimsub
    //   );
    // }
    candidates() {
      const assignedIds = this.role.roleAssignedUsers.map((a) => a.userId);
      return this.members.filter(
        (m) => !assignedIds.includes(m.userId) && m.user.claimsub
      );
    },
    roleAssignedUsersWithColors() {
      // get color and online from teamMember
      var map = new Map();
      this.members.forEach((m) =>
        map.set(m.userId, { colorUI: m.colorUI, online: m.online })
      );
      const rc = this.role.roleAssignedUsers
        .map((r) => ({
          ...r,
          ...map.get(r.userId)
        }))
        .sort((a, b) => a.position - b.position);
      return rc;
    }
  },
  setup() {
    const { mutate: assignUserToRole } = useMutation(mutationAssignUserToRole);

    const { mutate: removeUserFromRole } = useMutation(
      mutationRemoveUserFromRole
    );
    function assignToRole(value) {
      assignUserToRole(value);
    }
    function removeFromRole(value) {
      removeUserFromRole(value);
    }
    return {
      assignToRole,
      removeFromRole
    };
  }
};
</script>

<style scoped>
li {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 48px;
}
li:last-of-type {
  margin-bottom: calc(var(--spacing-content) * 2);
}
.user-badge {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: var(--spacing-m);
}
.user-name {
  flex-grow: 1;
  text-align: left;
  padding: var(--spacing-m) 0;
}
.lined {
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-container-background);
}
.action {
  padding-top: 6px;
  padding-bottom: 2px;
}
</style>
