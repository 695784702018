<template>
  <div class="page-container">
    <div v-if="userLoading || orgLoading">
      <app-spinner />
    </div>
    {{ orgError }}
    {{ checkUserIsStillActiveMemberOfOrg() }}
    <app-org-state
      v-if="!userLoading && !orgLoading && organisation.id != undefined"
      :orgState="organisation.organisationState.derivedOrganisationState || OrgStateEnum.BETA"
      :orgOwner="organisation.adminUser"
      :user="user"
      :orgId="organisation.id"
    />
    <div class="page-rows grow">
      <org-navigation
        v-if="!userLoading"
        :orgs="user.organisations"
        :activeOrgId="id"
        class="org-nav"
      />
      <div class="panel org-view">
        <org-header
          v-if="!userLoading && !orgLoading && organisation.id != undefined"
          :org="organisation"
          :orgIsLocked="orgIsLocked"
          :user="wrappedUser"
        />
        <org-teams
          v-if="!userLoading && !orgLoading && organisation.id != undefined"
          :userId="user.id"
          :organisation="organisation"
          :loggedOnUser="wrappedUser.user"
          :orgIsLocked="orgIsLocked"
          class="grow"
        />
        <org-footer
          v-if="!userLoading && !orgLoading && organisation.id != undefined"
          :org="organisation"
          :user="wrappedUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import OrgFooter from "../components/OrgFooter.vue";
import OrgHeader from "../components/OrgHeader.vue";
import OrgNavigation from "../components/OrgNavigation.vue";
import OrgTeams from "../components/OrgTeams.vue";
import {
  apolloClient,
  mutationAddOrganisation,
  queryOrganisation,
  queryOrganisations,
  subscriptionUpdateOrganisation
} from "../apollo";
import router from "../router";
import { computed, ref } from "vue";
import AppSpinner from "../components/AppSpinner.vue";
import AppOrgState from "../components/AppOrgState.vue";
import { OrgStateEnum } from "../shared/orgstate";
import { orgIsLocked } from "../generic/organisationLogic";

export default {
  name: "Orgs",
  props: ["id"],
  components: {
    OrgNavigation,
    OrgHeader,
    OrgTeams,
    OrgFooter,
    AppSpinner,
    AppOrgState
  },
  data() {
    return {
      OrgStateEnum: OrgStateEnum
    };
  },
  methods: {
    checkUserIsStillActiveMemberOfOrg() {
      const organisationMembers = this.organisation.organisationMembers || [];
      const userIsRemovedFromOrg =
        organisationMembers.filter(
          (m) => m.user && m.dateRemoved && m.userId == this.user.id
        ).length > 0;
      if (userIsRemovedFromOrg) {
        console.log(
          `User is not active member of this organisation ${this.organisation.name}. redirecting`
        );
        apolloClient.cache.evict({
          id: `Organisation:${this.organisation.id}`
        });
        router.push({
          name: "Orgs"
        });
      }
    }
  },
  computed: {
    wrappedUser() {
      return {
        ...this.organisation.organisationMembers.find(
          (om) => om.userId == this.user.id
        ),
        online: true
      };
    },
    orgIsLocked() {
      return orgIsLocked(this.organisation);
    }
  },
  provide() {
    return {
      userCanEdit: computed(
        () => this.organisation.adminUser.id == this.user.id
      )
    };
  },
  watch: {
    id(newValue) {
      this.switchOrganisation(newValue);
    }
  },
  setup(props) {
    // console.log(
    //   `Orgs id [${props.id}] isUndefined [${
    //     props.id == undefined
    //   }] isEmptyString [${props.id == ""}]`
    // );
    const orgId = ref(props.id);
    function switchOrganisation(newId) {
      orgId.value = newId;
    }

    const {
      result: userResult,
      loading: userLoading,
      error: userError
    } = useQuery(queryOrganisations);

    const { mutate: mutateAddFirstOrg } = useMutation(
      mutationAddOrganisation,
      () => ({
        variables: {},
        update: (cache, { data: { addOrganisation } }) => {
          console.log("added");
          const data = cache.readQuery({
            query: queryOrganisations,
            variables: {}
          });
          const {
            getMe: { user }
          } = data;
          cache.writeQuery({
            query: queryOrganisations,
            variables: {},
            data: {
              getMe: {
                user: {
                  ...user,
                  organisations: [addOrganisation]
                }
              }
            }
          });
        }
      })
    );

    const user = useResult(userResult, {}, (data) => {
      if (
        !props.id &&
        data.getMe.user.organisations &&
        data.getMe.user.organisations.length > 0
      ) {
        console.log(
          `No id set in url, forwarding to first ${data.getMe.user.organisations[0].id}`
        );
        router.push({
          name: "Orgs",
          params: { id: data.getMe.user.organisations[0].id }
        });
      }

      if (!props.id && data.getMe.user.organisations.length == 0) {
        console.log("user has no organisations, creating first");
        mutateAddFirstOrg().then((d) => {
          const {
            data: {
              addOrganisation: { id }
            }
          } = d;
          console.log(`added first org with id ${id}`);
          router.push({
            name: "Orgs",
            params: { id }
          });
        });
      }
      return data.getMe.user;
    });

    const {
      result,
      loading: orgLoading,
      error: orgError,
      subscribeToMore
    } = useQuery(
      queryOrganisation,
      () => ({
        id: props.id
      }),
      () => ({
        enabled: props.id != ""
      })
    );

    const organisation = useResult(
      result,
      {
        id: undefined,
        name: "",
        teams: [],
        members: [],
        adminUser: { id: undefined },
        __typename: "Organisation"
      },
      (data) => data.getOrganisation
    );

    //TODO: duplicate subsciptions after switch
    subscribeToMore(() => ({
      document: subscriptionUpdateOrganisation,
      variables: { id: orgId.value },
      updateQuery: (previous) => {
        console.log(`Orgs. organisation ${orgId.value} updated`);
        return previous;
      }
    }));

    return {
      switchOrganisation,
      orgId,
      user,
      organisation,
      userLoading,
      orgLoading,
      userError,
      orgError
    };
  }
};
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  height: var(--viewport-height);
}
.page-rows {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.org-view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.grow {
  flex-grow: 1;
}

/* MOBILE VERSION */
@media screen and (max-width: 767px) {
  .page-rows {
    flex-direction: column;
  }
}
</style>
