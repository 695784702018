<template>
  <div class="textarea-wrapper">
    <app-floating-lable
      :labelText="labelText"
      :labelPosition="labelPosition"
      v-if="showLabel"
    />
    <div
      class="grow-wrap"
      :class="{
        padded: displayPadded,
        frozen: !userCanEdit
      }"
    >
      <textarea
        :maxlength="maxLength"
        spellcheck="false"
        rows="1"
        v-model="value"
        :placeholder="placeholderText"
        ref="textarea"
        @mouseenter="showLabel = true"
        @mouseout="showLabel = false"
        @focus="(showLabel = true), focus()"
        @blur="(showLabel = false), changed(value), blurred()"
        @keydown.enter.exact.prevent="changed(value)"
      ></textarea>
      <div class="replica">
        <span class="placeholder">{{ this.placeholderText }}</span
        >{{ this.value }} &nbsp;
      </div>
      <div
        class="editingUser"
        :class="'data-viz-' + newEditingUser.colorUI"
        v-if="newEditingUser"
      >
        <!-- <div class="edit-icon"></div> -->
        {{ newEditingUser.user.fullName }} ...
      </div>
    </div>
  </div>
</template>

<script>
import AppFloatingLable from "./AppFloatingLable.vue";

export default {
  name: "AppTextarea",
  components: { AppFloatingLable },
  props: {
    id: {
      type: Object
    },
    inputValue: {
      type: String
    },
    labelText: {
      type: String
    },
    placeholderText: {
      type: String,
      default: "Type something..." // needed to style background of empty fields...
    },
    labelPosition: {
      type: String
    },
    displayPadded: {
      type: Boolean,
      default: false
    },
    editingUser: {
      type: Object
    },
    forceFocus: {
      type: Boolean
    },
    maxLength: {
      type: Number,
      default: 256
    }
  },
  inject: ["userCanEdit"],

  methods: {
    focus() {
      this.$emit("lock", { lock: true, id: this.id });
    },
    changed(value) {
      if (value != this.inputValue) {
        this.$refs.textarea.blur();
        this.$emit("update:apptextarea", {
          id: this.id,
          value
        });
      }
      this.$emit("lock", { lock: false, id: this.id });
    },
    blurred() {
      this.$emit("blurred");
    }
  },
  emits: ["update:apptextarea", "lock", "blurred"],

  data() {
    return {
      value: String,
      showLabel: false,
      newEditingUser: undefined
    };
  },
  mounted() {
    this.value = this.inputValue;
    this.newEditingUser = this.editingUser;
    if (this.forceFocus) {
      this.$refs.textarea.focus();
    }
  },
  watch: {
    inputValue(newValue) {
      this.value = newValue;
    },
    editingUser(newValue) {
      this.newEditingUser = newValue;
    }
  }
};
</script>

<style scoped>
/*
todo: find a way to display edit icon / tooltip when :focus, since textarea can have no pseudo elements...
*/

/* This text-area grows as you type, to always have the right height! */
/* https://codepen.io/chriscoyier/pen/XWKEVLy */
.grow-wrap {
  display: grid;
  position: relative;
  /* neccessary for focus state! */
  margin-bottom: 4px;
  margin-top: 4px;
  min-height: 40px;
}
.grow-wrap > .replica {
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}

/* frozen = read only */
.grow-wrap.frozen > textarea {
  display: none;
}
.grow-wrap.frozen > .replica {
  visibility: visible;
}

.grow-wrap > textarea,
.grow-wrap > .replica {
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  /* padding: 10px 0 14px 0; */
  padding: 8px 0;
  border-radius: var(--border-radius);
  background-color: transparent;
  color: var(--color-text);
}

/* padded option with backround */
.grow-wrap.padded > textarea,
.grow-wrap.padded > .replica {
  padding: 8px;
  padding-bottom: 12px;
  /* margin-top: 4px; */
}

/* textarea:placeholder-shown, */
textarea:hover {
  background-color: var(--color-input-background-active);
}
textarea:focus {
  background-color: var(--color-white);
  border-radius: calc(var(--border-radius) / 2);
}
.textarea-wrapper {
  position: relative;
}

/* grow .replica with placeholder text */
textarea:placeholder-shown + .replica .placeholder {
  display: inline;
}
textarea:not(:placeholder-shown) + .replica .placeholder {
  display: none;
}

/* for titles */
h1 .grow-wrap {
  min-height: 36px;
  margin-top: 0;
}
h1 .grow-wrap > textarea,
h1 .grow-wrap > .replica {
  padding: 0;
}

h2 .grow-wrap > textarea,
h2 .grow-wrap > .replica {
  padding: 8px 0 12px 0;
}

p.team-mission .grow-wrap {
  min-height: 36px;
}
p.team-mission .grow-wrap > textarea,
p.team-mission .grow-wrap > .replica {
  /* padding: unset;
  padding-top: var(--spacing-xs); */
  padding: 1px 0 15px 0;
}

/* display replica as placeholder if needed */
textarea:placeholder-shown + .replica {
  color: var(--color-input-placeholder);
}

/* editing users */
@import "../assets/css/DataVizColors.css";
.editingUser {
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: var(--base-size);
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}
.padded .editingUser {
  height: calc(100% + 8px);
  top: -4px;
}
</style>
