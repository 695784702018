<template>
  <div class="page-container">
    <app-org-state
      v-if="organisation.id != undefined"
      :orgState="orgState"
      :orgOwner="organisation.adminUser"
      :user="loggedOnUser"
      :orgId="organisation.id"
      :suppressModal="true"
    />
    <div v-if="loading">
      <app-spinner />
    </div>
    <app-header :logoLink="'Orgs'" :logoLinkId="organisation.id">
      <template v-slot:title>
        <span v-if="orgState == OrgStateEnum.TRIAL"
          ><span class="heavy">Upgrade</span> — {{ organisation.name }}</span
        >
        <span v-if="orgState != OrgStateEnum.TRIAL"
          ><span class="heavy">Admin & Billing</span> — {{ organisation.name }}
        </span>
      </template>
    </app-header>
    <div class="content">
      <br />
      <!-- case: Beta -->
      <div v-if="orgState == OrgStateEnum.BETA && !loading">
        <section>This area is under construction 😅</section>
        <section>
          <billing-danger-zone-delete :organisation="organisation" />
        </section>
      </div>
      <!-- case: Trial or TrialEnd -->
      <div
        v-if="
          orgState == OrgStateEnum.TRIAL || orgState == OrgStateEnum.TRIALFREEZE
        "
      >
        <section>
          <billing-upgrade-trial
            :organisation="organisation"
            :loggedOnUser="loggedOnUser"
            @paymentsuccesful="paymentSuccesFul()"
          />
        </section>
        <section>
          <billing-danger-zone-delete :organisation="organisation" />
        </section>
      </div>
      <!-- case: all others -->
      <div
        v-if="
          orgState != OrgStateEnum.TRIAL &&
          orgState != OrgStateEnum.TRIALFREEZE &&
          orgState != OrgStateEnum.BETA &&
          !loading
        "
      >
        <section>
          <billing-members :organisation="organisation" />
          <billing-current-month :organisation="organisation" :loggedOnUser="loggedOnUser"/>
          <billing-invoice-list
            :invoices="organisation.paymentDetails.invoices"
          />
          <billing-danger-zone
            :organisation="organisation"
            v-if="orgState != OrgStateEnum.CANCELLED"
          />
          <billing-danger-zone-delete
            :organisation="organisation"
            v-if="orgState == OrgStateEnum.CANCELLED"
          />
        </section>
      </div>
      <!-- end case -->
      <section class="footer" v-if="!loading">
        If you have any questions, please contact us at team@role-up.com
      </section>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { queryBilling, subscriptionUpdateBillingOrganisation } from "../apollo";
import AppHeader from "../components/AppHeader.vue";
import AppSpinner from "../components/AppSpinner.vue";
import AppOrgState from "../components/AppOrgState.vue";
import { OrgStateEnum } from "../shared/orgstate";
import BillingInvoiceList from "../components/BillingInvoiceList.vue";
import BillingCurrentMonth from "../components/BillingCurrentMonth.vue";
import BillingDangerZone from "../components/BillingDangerZone.vue";
import BillingUpgradeTrial from "../components/BillingUpgradeTrial.vue";
import BillingDangerZoneDelete from "../components/BillingDangerZoneDelete.vue";

import BillingMembers from "../components/BillingMembers.vue";

export default {
  name: "Billing",
  props: ["id"],
  components: {
    AppHeader,
    AppSpinner,
    AppOrgState,
    BillingInvoiceList,
    BillingCurrentMonth,
    BillingDangerZone,
    BillingUpgradeTrial,
    BillingMembers,
    BillingDangerZoneDelete
  },
  data() {
    return {
      OrgStateEnum: OrgStateEnum
    };
  },
  computed: {
    orgState() {
      return (
        (this.organisation.organisationState &&
          this.organisation.organisationState.derivedOrganisationState) ||
        OrgStateEnum.BETA
      );
    }
  },
  setup(props) {
    const { result, loading, error, subscribeToMore } = useQuery(queryBilling, {
      id: props.id
    });

    const organisation = useResult(result, {}, (data) => data.getOrganisation);
    const loggedOnUser = useResult(result, {}, (data) => data.getMe.user);

    subscribeToMore(() => ({
      document: subscriptionUpdateBillingOrganisation,
      variables: { id: props.id },
      updateQuery: (previous) => {
        console.log("Billing. organisation updated");
        return previous;
      }
    }));

    function paymentSuccesFul() {
      console.log("payment successful");
    }

    return {
      organisation,
      loggedOnUser,
      loading,
      error,
      paymentSuccesFul
    };

    /*
    TODO
    - validate that user is the owner of the org!

    - payment information:
      - checkout / start subscription
      - edit checkout information
      - add aditional billing email ?

    TO BE REFINED
    - Cancel account
      - cancel subscription
      - retire org information (download?)
      - merge with other org (transfer org to other org)
    */
  }
};
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: var(--viewport-height);
}
.content {
  padding: var(--spacing-content);
  padding-left: var(--padding-logo);
  display: flex;
  flex-direction: column;
}
section {
  padding-bottom: 6rem;
  max-width: 1000px;
}
/* @media screen and (min-width: 600px) {
  section {
    padding-right: var(--spacing-content);
  }
} */
/* stretch content to get footer to bottom */
.content,
.content div {
  flex-grow: 1;
  flex-basis: 100%;
}
.footer {
  padding-bottom: calc(var(--spacing-content) * 2);
}
</style>
