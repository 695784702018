<template>
  <div class="floating" :class="[labelPosition, { centered: centerLabel }]">
    <label :class="{ wrap: labelWrap }"
      >{{ labelText }}
      <div v-if="userOffline" class="light">Offline</div></label
    >
  </div>
</template>

<script>
export default {
  name: "AppFloatingLabel",
  props: {
    labelText: {
      //   type: String, --gives error
      required: true
    },
    userOffline: {
      required: false,
      default: false
    },
    labelPosition: {
      required: false
    },
    labelWrap: {
      required: false,
      default: false
    },
    centerLabel: {
      required: false,
      default: false
    }
  },
  setup() {}
};
</script>

<style scoped>
/* https://codepen.io/GreeCoon/pen/OpGjLM */
* {
  --label-height: 30px;
  --arrow-size: 6px;
}
/* default = top */
.floating.top {
  bottom: calc(100% + var(--arrow-size));
}
.floating {
  line-height: 0;
  display: block;
  padding: var(--spacing-s) var(--spacing-m);
  min-height: var(--label-height);
  position: absolute;
  background-color: var(--color-text);
  color: var(--color-card-background);
  text-align: center;
  border-radius: var(--border-radius);
  z-index: 99;
}
label {
  display: block;
  position: relative;
  line-height: 1.25;
  white-space: nowrap;
  color: var(--color-white);
}
.floating.top label::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  left: 50%;
  bottom: 0;
  border: var(--arrow-size) solid transparent;
  border-bottom: 0;
  border-top: var(--arrow-size) solid var(--color-text);
  transform: translate(
    calc(var(--arrow-size) * -1),
    calc((var(--label-height) - var(--arrow-size)) / 2)
  );
}
/* hidden */
.floating.hidden {
  display: none;
}

/* bottom */
.floating.bottom {
  bottom: unset;
  top: calc(100% + var(--arrow-size));
}
.floating.bottom label::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  left: 50%;
  top: 0;
  border: var(--arrow-size) solid transparent;
  border-top: 0;
  border-bottom: var(--arrow-size) solid var(--color-text);
  transform: translate(
    calc(var(--arrow-size) * -1),
    calc((var(--label-height) - var(--arrow-size)) / -2)
  );
}

/* right */
.floating.right {
  bottom: unset;
  top: 50%;
  left: calc(100% + var(--arrow-size));
  transform: translateY(-50%);
}
.floating.right label::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  top: 50%;
  left: 0;
  border: var(--arrow-size) solid transparent;
  border-left: 0;
  border-right: var(--arrow-size) solid var(--color-text);
  transform: translate(calc(-100% - var(--arrow-size) * 2), -50%);
}

/* left */
.floating.left {
  bottom: unset;
  left: unset;
  top: 50%;
  right: calc(100% + var(--arrow-size));
  transform: translateY(-50%);
}
.floating.left label::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  right: 0;
  top: 50%;
  border: var(--arrow-size) solid transparent;
  border-right: 0;
  border-left: var(--arrow-size) solid var(--color-text);
  transform: translate(calc(100% + var(--arrow-size) * 2), -50%);
  /* transform: translate(100%, -50%); */
}

/* text-wrapping */
label.wrap {
  white-space: normal;
}

/* centered */
.floating.top.centered,
.floating.bottom.centered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* userOnline */
.light {
  opacity: 0.5;
}
</style>
