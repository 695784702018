import {
  store,
  USER_LOGON,
  CLEAR_STATE,
  USER_LOGOFF,
  USER_ACTIVEINTEAM,
  USER_INACTIVEINTEAM,
  LOCK_FIELD,
  UNLOCK_FIELD
} from "../store";

export function clearState() {
  store.dispatch(CLEAR_STATE);
}

export function messageEventHandler(eventData) {
  const {
    userId,
    message,
    teamId,
    roleId = "",
    typeName,
    field,
    currentUsers = []
  } = eventData;

  switch (message) {
    case "currentusers":
      currentUsers.forEach((user) => {
        store.commit(USER_LOGON, {
          userId: user.userId,
          teamId: user.teamId
        });
      });
      break;
    case "userloggedon":
      store.commit(USER_LOGON, { userId, teamId: undefined });
      break;
    case "userloggedoff":
      store.commit(USER_LOGOFF, { userId, teamId: undefined });
      break;
    case "useractiveinteam":
      store.commit(USER_ACTIVEINTEAM, { userId, teamId });
      break;
    case "userinactiveinteam":
      store.commit(USER_INACTIVEINTEAM, { userId, teamId });
      break;
    case "fieldlocked":
      store.commit(LOCK_FIELD, {
        userId,
        typeName,
        teamId,
        roleId,
        field
      });
      break;
    case "fieldunlocked":
      store.commit(UNLOCK_FIELD, {
        userId,
        typeName,
        teamId,
        roleId,
        field
      });
      break;
    default:
      console.log(`unknown message ${message}`);
  }
}
