<template>
  <div class="org-teams">
    <div v-if="loading">Loading...</div>
    <!-- <div class="view-option" v-if="viewOption === 2 && showGraph"> -->
    <section
      class="cancelled"
      v-if="
        this.organisation.organisationState.derivedOrganisationState ==
        'CANCELLED'
      "
    >
      <org-claim-subscription :organisation="organisation" :loggedOnUser="loggedOnUser" :billingPage="false"/>
    </section>
    <section class="graph" v-if="!loading && showGraph">
      <org-graph :teams="graphData" />
    </section>
    <!-- </div> -->
    <section class="button-bar">
      <div class="not-full" v-if="!maxTeamsReached && !orgIsLocked">
        <app-button
          :buttonClass="'cta'"
          :buttonLabel="'New Team'"
          @buttonclicked="newTeam()"
        />
      </div>
      <div class="full" v-if="maxTeamsReached || orgIsLocked">
        <app-button :buttonClass="'cta-locked'" :buttonLabel="'New Team'" />
        <p class="limit" v-if="maxTeamsReached">
          During beta-testing, organizations are limited to 5 teams and 20
          members.
        </p>
      </div>
    </section>
    <section v-if="!loading && myTeams.length > 0">
      <h2>My teams</h2>
      <org-team
        v-for="item in myTeams"
        :key="item.id"
        :team="item"
        :userId="userId"
      />
    </section>
    <section v-if="!loading && otherTeams.length > 0">
      <h2 v-if="myTeams.length > 0">Other teams</h2>
      <h2 v-if="myTeams.length == 0">Teams</h2>
      <org-team
        v-for="item in otherTeams"
        :key="item.id"
        :team="item"
        :userId="userId"
      />
    </section>
    <!-- </div> -->
  </div>
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import AppButton from "./AppButton.vue";
import OrgTeam from "./OrgTeam.vue";
import OrgGraph from "./OrgGraph.vue";
import { mutationAddTeam } from "../apollo";
import router from "../router";
import { reactive } from "vue";
import { maxTeamsReached } from "../generic/organisationLogic";
import OrgClaimSubscription from "./OrgClaimSubscription.vue";

export default {
  components: {
    OrgTeam,
    AppButton,
    OrgGraph,
    OrgClaimSubscription
  },
  name: "OrgTeams",
  props: {
    userId: String,
    organisation: Object,
    loggedOnUser: Object,
    key: String,
    orgIsLocked: Boolean
  },
  inject: ["userCanEdit"],
  setup(props) {
    const { mutate: mutateAddTeam } = useMutation(mutationAddTeam, () => ({
      variables: {
        organisationId: props.organisation.id
      }
    }));
    function newTeam() {
      mutateAddTeam()
        .then((newOrg) => {
          const {
            data: {
              addTeam: { organisationTeams }
            }
          } = newOrg;

          const id = organisationTeams.sort(
            (a, b) => b.position - a.position
          )[0].teamId;
          console.log(`added teamId ${id}`);
          router.push({
            name: "Team",
            params: { id }
          });
        })
        .catch((e) => console.log(`error creating team ${e}`));
    }
    const loading = false;

    return {
      newTeam,
      loading
    };
  },
  computed: {
    maxTeamsReached() {
      return maxTeamsReached(this.organisation);
    },
    myTeams() {
      return this.organisation.organisationTeams
        .filter((orgTeam) =>
          orgTeam.team && orgTeam.team.teamMembers
            ? orgTeam.team.teamMembers
                .filter((member) => member.user)
                .map((member) => member.user.id)
                .includes(this.userId)
            : false
        )
        .map((orgTeam) => orgTeam.team);
    },
    otherTeams() {
      return this.organisation.organisationTeams
        .filter((orgTeam) =>
          orgTeam.team && orgTeam.team.teamMembers
            ? !orgTeam.team.teamMembers
                .filter((member) => member.user)
                .map((member) => member.user.id)
                .includes(this.userId)
            : true
        )
        .map((orgTeam) => orgTeam.team);
    },
    graphData() {
      return reactive(this.organisation.organisationTeams.map((ot) => ot.team));
    },
    teamCount() {
      return Object.keys(this.organisation.organisationTeams).length;
    },
    showGraph() {
      // only on screens over 760 px AND more than 1 team
      const screenWidth = window.innerWidth;
      return this.teamCount > 1 && screenWidth > 760;
    }
  }
};
</script>

<style scoped>
.org-teams {
  padding-right: var(--spacing-content);
  padding-bottom: var(--spacing-content);
  padding-top: 0;
  display: flex;
  flex-direction: column;
}
section {
  padding-top: calc(var(--spacing-content) * 2);
  padding-left: var(--padding-logo);
}
section.graph {
  padding-top: 0;
  padding-left: var(--spacing-content);
}
section.button-bar {
  padding-top: var(--spacing-content);
  padding-top: var(--spacing-m);
  display: flex;
  justify-content: space-between;
}
section.cancelled {
  padding-top: 0;
}
.view-option-list {
  display: flex;
}
.hint {
  padding: 0 calc(var(--spacing-content) / 2);
  line-height: var(--button-size);
}

h2 {
  margin-bottom: var(--spacing-m);
}
.limit {
  padding-top: var(--line-height);
}
</style>
