import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { createApolloProvider } from "@vue/apollo-option";
import gql from "graphql-tag";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { keycloak } from "../keycloak";

const cache = new InMemoryCache({
  typePolicies: {
    Role: {
      fields: {
        responsibilities: {
          merge(existing, incoming) {
            return incoming;
          }
        },
        roleAssignedUsers: {
          merge(existing, incoming) {
            return incoming;
          }
        }
      }
    },
    Team: {
      fields: {
        teamRoles: {
          merge(existing, incoming) {
            return incoming || [];
          },
          teamMembers: {
            merge(existing, incoming) {
              return incoming || [];
            }
          }
        }
      }
    },
    Organisation: {
      fields: {
        organisationTeams: {
          merge(existing, incoming) {
            return incoming || [];
          }
        },
        organisationMembers: {
          merge(existing, incoming) {
            return incoming || [];
          }
        }
      }
    }
  }
});

export const typeDefs = gql`
  type User {
    id: ID!
    claimsub: String
    createdDate: AWSDateTime
    firstLogonDate: AWSDateTime
    fullName: String
    email: String
    organisations: [Organisation]
  }

  type Me {
    claimsub: String
    email: String
    givenName: String
    familyName: String
    user: User
  }

  enum OrgState {
    BETA
    TRIAL
    TRIALFREEZE
    ACTIVE
    FROZEN
    CANCELLED
    ARCHIVED
    DELETED
  }

  type OrganisationState {
    derivedOrganisationState: OrgState!
    lastPaymentSuccessfull: Boolean
    dueDate: AWSDateTime
  }
  

  type Organisation {
    id: ID!
    name: String
    goalLabel: String
    responsibilityLabel: String
    organisationState: OrganisationState
    createdDate: AWSDateTime
    # be aware to keep updating the members field
    organisationMembers: [OrganisationMember]
    adminUser: User
    organisationTeams: [OrganisationTeam]
    paymentDetails: PaymentDetails
  }

  type PaymentDetails {
    cancelUrl: AWSURL
    updateUrl: AWSURL
    creditCardType: String
    creditCardExpiry: String
    creditCardLastDigits: String
    lastPaymentDate: AWSDateTime
    nextPaymentDate: AWSDateTime
    recurringPriceEUR: Float
    invoices: [PaymentInvoices]
  }

  type PaymentInvoices {
    amount: Float
    payoutDate: AWSDateTime
    status: String
    paymentInfo: String
    receiptUrl: AWSURL
    quantity: Int
  }

  type OrganisationMember {
    dateAdded: AWSDateTime
    dateRemoved: AWSDateTime
    dateEndTrial: AWSDateTime
    returnee: Boolean
    colorUI: Int
    userId: ID
    user: User
  }

  type OrganisationTeam {
    position: Int
    team: Team
  }

  type Team {
    id: ID!
    name: String
    colorUI: Int
    mission: String
    teamRoles: [TeamRole]
    teamMembers: [TeamMember]
    adminUser: User
    createdDate: AWSDateTime
    updatedDate: AWSDateTime
  }

  type TeamMember {
    dateAdded: AWSDateTime
    dateRemoved: AWSDateTime
    colorUI: Int
    userId: ID
    user: User
  }

  type TeamRole {
    position: Int
    roleId: ID
    role: Role
  }

  type Role {
    id: ID!
    position: Int
    title: String
    purpose: String
    roleAssignedUsers: [RoleAssignedUser]
    responsibilities: [Responsibility]
    createdDate: AWSDateTime
    updatedDate: AWSDateTime
  }

  type RoleAssignedUser {
    position: Int
    userId: ID
    user: User
  }

  type Responsibility {
    id: ID
    position: Int
    value: String
  }
`;

const queryOrganisationsWithTeams = gql`
  query QueryOrganisations @client {
    getMe {
      user {
        id
        claimsub
        email
        fullName
        organisations {
          id
          goalLabel
          responsibilityLabel
          name
          organisationState {
            derivedOrganisationState
          }
          createdDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          organisationMembers {
            dateAdded
            dateRemoved
            dateEndTrial
            returnee
            userId
            colorUI
            user {
              id
              claimsub
              createdDate
              firstLogonDate
              email
              fullName
            }
          }
          organisationTeams {
            teamId
            position
            createdDate
            updatedDate
            team {
              id
              colorUI
              mission
              name
              updatedDate
              adminUser {
                id
                claimsub
                email
                fullName
              }
              teamMembers {
                userId
                colorUI
                user {
                  id
                  claimsub
                  email
                  fullName
                }
              }
              organisation {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const queryOrganisations = gql`
  query QueryOrganisations @client {
    getMe {
      user {
        id
        claimsub
        email
        fullName
        organisations {
          id
          goalLabel
          responsibilityLabel
          name
          organisationState {
            derivedOrganisationState
          }
          createdDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          organisationMembers {
            dateAdded
            dateRemoved
            dateEndTrial
            returnee
            userId
            colorUI
            user {
              id
              claimsub
              createdDate
              firstLogonDate
              email
              fullName
            }
          }
        }
      }
    }
  }
`;

const queryOrganisation = gql`
  query QueryOrganisation($id: ID!) @client {
    getOrganisation(id: $id) {
      id
      goalLabel
      responsibilityLabel
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        userId
        colorUI
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
          organisation {
            id
          }
        }
      }
    }
  }
`;

const queryBilling = gql`
  query QueryBilling($id: ID!) @client {
    getMe {
      user {
        id
        claimsub
        email
        fullName
      }
    }
    getOrganisation(id: $id) {
      id
      name
      organisationState {
        derivedOrganisationState
        lastPaymentSuccessfull
        dueDate
      }
      createdDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        colorUI
        returnee
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      paymentDetails {
        cancelUrl
        updateUrl
        lastPaymentDate
        nextPaymentDate
        recurringPriceEUR
        creditCardType
        creditCardExpiry
        creditCardLastDigits
        invoices {
          status
          paymentInfo
          amount
          payoutDate
          receiptUrl
          quantity
        }
      }
    }
  }
`;

const queryMe = gql`
  query QueryMe @client {
    getMe {
      user {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const roleFragment = gql`
  fragment MyRole on Role {
    id
    title
    purpose
    roleAssignedUsers {
      position
      userId
      user {
        id
        claimsub
        email
        fullName
      }
    }
    responsibilities {
      id
      position
      value
    }
  }
`;

const teamFragment = gql`
  fragment MyTeam on Team {
    id
    colorUI
    mission
    name
    adminUser {
      id
      claimsub
      email
      fullName
    }
    teamRoles {
      position
      roleId
      role {
        id
        title
        purpose
        roleAssignedUsers {
          position
          userId
          user {
            id
            claimsub
            email
            fullName
          }
        }
        responsibilities {
          id
          position
          value
        }
      }
    }
    teamMembers {
      userId
      colorUI
      user {
        id
        claimsub
        email
        fullName
      }
    }
  }`

const queryTeam = gql`
  query GetTeam($id: ID!) @client {
    getMe {
      user {
        id
        claimsub
        email
        fullName
      }
    }
    getTeam(id: $id) {
      id
      name
      colorUI
      mission
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      organisation {
        id
        name
        organisationState {
          derivedOrganisationState
        }
        createdDate
        goalLabel
        responsibilityLabel
        organisationMembers {
          dateAdded
          dateRemoved
          dateEndTrial
          returnee
          colorUI
          userId
          user {
            id
            claimsub
            createdDate
            firstLogonDate
            email
            fullName
          }
        }
        adminUser {
          id
          claimsub
          email
          fullName
        }
      }
    }
  }
`;

// const queryTeams = gql`
//   query GetTeams @client {
//     getTeams {
//       id
//       name
//       mission
//       roles {
//         id
//       }
//     }
//   }
// `;

const mutationDeleteMe = gql`
  mutation DeleteMe @client {
    deleteMe
  }
`;

const mutationAddOrganisation = gql`
  mutation AddOrganisation @client {
    addOrganisation {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        colorUI
        returnee
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
    }
  }
`;

const mutationDeleteOrganisation = gql`
  mutation DeleteOrganisation($id: ID!) @client {
    deleteOrganisation(id: $id) {
      id
      goalLabel
      responsibilityLabel
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationUpdateOrganisationGoalLabel = gql`
  mutation UpdateOrganisationGoalLabel($id: ID!, $goalLabel: String!) @client {
    updateOrganisationGoalLabel(id: $id, goalLabel: $goalLabel) {
      id
      goalLabel
      responsibilityLabel
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        userId
        colorUI
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
          organisation {
            id
          }
        }
      }
    }
  }
`;

const mutationUpdateOrganisationResponsibilityLabel = gql`
  mutation UpdateOrganisationResponsibilityLabel(
    $id: ID!
    $responsibilityLabel: String!
  ) @client {
    updateOrganisationResponsibilityLabel(
      id: $id
      responsibilityLabel: $responsibilityLabel
    ) {
      id
      goalLabel
      responsibilityLabel
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        userId
        colorUI
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
          organisation {
            id
          }
        }
      }
    }
  }
`;

const mutationUpdateOrganisationName = gql`
  mutation UpdateOrganisationName($id: ID!, $name: String) @client {
    updateOrganisationName(id: $id, name: $name) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationUpdateMe = gql`
  mutation UpdateMe($fullName: String, $email: String) @client {
    updateMe(fullName: $fullName, email: $email) {
      id
      claimsub
      email
      fullName
    }
  }
`;

const mutationInviteUserToOrganisation = gql`
  mutation InviteUserToOrganisation($email: String!, $organisationId: ID!)
  @client {
    inviteUserToOrganisation(email: $email, organisationId: $organisationId) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationInviteUserToTeam = gql`
  mutation InviteUserToTeam($email: String!, $organisationId: ID!, $teamId: ID!)
  @client {
    inviteUserToTeam(
      email: $email
      organisationId: $organisationId
      teamId: $teamId
    ) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const mutationMoveOwnerShipOfOrganisation = gql`
  mutation MoveOwnerShipOfOrganisation($id: ID!, $userId: ID!) @client {
    moveOwnerShipOfOrganisation(id: $id, userId: $userId) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationRemoveUserFromOrganisation = gql`
  mutation RemoveUserFromOrganisation($id: ID!, $organisationId: ID!) @client {
    removeUserFromOrganisation(id: $id, organisationId: $organisationId) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
          teamRoles {
            roleId
            position
            role {
              id
              title
              purpose
              roleAssignedUsers {
                position
                userId
                user {
                  id
                  claimsub
                  email
                  fullName
                }
              }
              responsibilities {
                id
                position
                value
              }
            }
          }
        }
      }
      paymentDetails {
        cancelUrl
        updateUrl
        lastPaymentDate
        nextPaymentDate
        recurringPriceEUR
        creditCardType
        creditCardExpiry
        creditCardLastDigits
        invoices {
          status
          paymentInfo
          amount
          payoutDate
          receiptUrl
          quantity
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationRemoveUserFromTeam = gql`
  mutation RemoveUserFromTeam($id: ID!, $teamId: ID!) @client {
    removeUserFromTeam(id: $id, teamId: $teamId) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const mutationMoveOwnerShipOfTeam = gql`
  mutation MoveOwnerShipOfTeam($id: ID!, $userId: ID!) @client {
    moveOwnerShipOfTeam(id: $id, userId: $userId) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const mutationAddTeam = gql`
  mutation AddTeam($organisationId: String) @client {
    addTeam(organisationId: $organisationId) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
          organisation {
            id
          }
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationUpdateTeam = gql`
  mutation UpdateTeam($id: ID!, $name: String, $mission: String) @client {
    updateTeam(id: $id, name: $name, mission: $mission) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const mutationUpdateTeamColorUI = gql`
  mutation UpdateTeamColorUI($id: ID!, $colorUI: Int) @client {
    updateTeamColorUI(id: $id, colorUI: $colorUI) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const mutationDeleteTeam = gql`
  mutation DeleteTeam($id: ID!, $organisationId: ID!) @client {
    deleteTeam(id: $id, organisationId: $organisationId) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      createdDate
      goalLabel
      responsibilityLabel
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
        }
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
    }
  }
`;

const mutationAddResponsibility = gql`
  mutation AddResponsibility($id: ID, $roleId: String!) @client {
    addResponsibility(id: $id, roleId: $roleId) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const mutationDeleteResponsibility = gql`
  mutation DeleteResponsibility($id: ID!, $roleId: String) @client {
    deleteResponsibility(id: $id, roleId: $roleId) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const mutationMoveResponsibility = gql`
  mutation MoveResponsibility(
    $id: ID!
    $teamId: String
    $fromRoleId: String
    $toRoleId: String
    $position: Int
  ) @client {
    moveResponsibility(
      id: $id
      teamId: $teamId
      fromRoleId: $fromRoleId
      toRoleId: $toRoleId
      position: $position
    ) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        roleId
        position
        role {
          id
          title
          purpose
          roleAssignedUsers {
            userId
            position
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const mutationUpdateResponsibility = gql`
  mutation UpdateResponsibility($id: ID!, $roleId: String, $value: String)
  @client {
    updateResponsibility(id: $id, roleId: $roleId, value: $value) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const mutationAddRole = gql`
  mutation AddRole($id:ID, $teamId: String!) @client {
    addRole(id: $id, teamId: $teamId) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamRoles {
        position
        roleId
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
    }
  }
`;

const mutationDeleteRole = gql`
  mutation DeleteRole($id: ID!, $teamId: String) @client {
    deleteRole(id: $id, teamId: $teamId) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamRoles {
        position
        roleId
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
    }
  }
`;

const mutationUpdateRoleTitle = gql`
  mutation UpdateRoleTitle($id: ID!, $title: String) @client {
    updateRoleTitle(id: $id, title: $title) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const mutationUpdateRolePosition = gql`
  mutation UpdateRolePosition($id: ID!, $teamId: ID!, $position: Int) @client {
    updateRolePosition(id: $id, teamId: $teamId, position: $position) {
      id
      colorUI
      mission
      name
      updatedDate
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamRoles {
        position
        roleId
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
    }
  }
`;

const mutationUpdateRolePurpose = gql`
  mutation UpdateRolePurpose($id: ID!, $purpose: String) @client {
    updateRolePurpose(id: $id, purpose: $purpose) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const mutationAssignUserToRole = gql`
  mutation AddUserToRole($roleId: ID!, $userId: ID!) @client {
    assignUserToRole(roleId: $roleId, userId: $userId) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const mutationRemoveUserFromRole = gql`
  mutation RemoveUserFromRole($roleId: ID!, $userId: ID!) @client {
    removeUserFromRole(roleId: $roleId, userId: $userId) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const subscriptionUpdateOrganisation = gql`
  subscription OnUpdateOrganisation($id: ID!) {
    onUpdateOrganisation(id: $id) {
      id
      name
      organisationState {
        derivedOrganisationState
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      organisationTeams {
        teamId
        position
        createdDate
        updatedDate
        team {
          id
          colorUI
          mission
          name
          updatedDate
          adminUser {
            id
            claimsub
            email
            fullName
          }
          teamMembers {
            userId
            colorUI
            user {
              id
              claimsub
              email
              fullName
            }
          }
        }
      }
    }
  }
`;

const subscriptionUpdateBillingOrganisation = gql`
  subscription OnUpdateBillingOrganisation($id: ID!) {
    onUpdateBillingOrganisation(id: $id) {
      id
      name
      organisationState {
        derivedOrganisationState
        lastPaymentSuccessfull
        dueDate
      }
      adminUser {
        id
        claimsub
        email
        fullName
      }
      organisationMembers {
        dateAdded
        dateRemoved
        dateEndTrial
        returnee
        colorUI
        userId
        user {
          id
          claimsub
          createdDate
          firstLogonDate
          email
          fullName
        }
      }
      paymentDetails {
        cancelUrl
        updateUrl
        lastPaymentDate
        nextPaymentDate
        recurringPriceEUR
        creditCardType
        creditCardExpiry
        creditCardLastDigits
        invoices {
          status
          paymentInfo
          amount
          payoutDate
          receiptUrl
          quantity
        }
      }
    }
  }
`;

const subscriptionUpdateTeam = gql`
  subscription OnUpdateTeam($id: ID!) {
    onUpdateTeam(id: $id) {
      id
      name
      colorUI
      mission
      adminUser {
        id
        claimsub
        email
        fullName
      }
      teamMembers {
        userId
        colorUI
        user {
          id
          claimsub
          email
          fullName
        }
      }
      teamRoles {
        position
        roleId
        role {
          id
          title
          purpose
          roleAssignedUsers {
            position
            userId
            user {
              id
              claimsub
              email
              fullName
            }
          }
          responsibilities {
            id
            position
            value
          }
        }
      }
    }
  }
`;

const subscriptionAddRole = gql`
  subscription OnAddRole($teamId: String) {
    onAddRole(teamId: $teamId) {
      id
      teamId
      position
    }
  }
`;

const subscriptionAddRole2 = gql`
  subscription OnAddRole2($teamId: String) {
    onAddRole2(teamId: $teamId) {
      id
      teamId
      position
      title
      purpose
      assignedUsers {
        id
        claimsub
        email
        fullName
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const subscriptionUpdateRole = gql`
  subscription OnUpdateRole($id: ID!) {
    onUpdateRole(id: $id) {
      id
      title
      purpose
      roleAssignedUsers {
        position
        userId
        user {
          id
          claimsub
          email
          fullName
        }
      }
      responsibilities {
        id
        position
        value
      }
    }
  }
`;

const subscriptionAddResponsibility = gql`
  subscription OnAddResponsibility($teamId: String) {
    onAddResponsibility(teamId: $teamId) {
      id
      teamId
      position
      value
      roleId
    }
  }
`;

const subscriptionDeleteResponsibility = gql`
  subscription OnDeleteResponsibility($teamId: String) {
    onDeleteResponsibility(teamId: $teamId) {
      id
      teamId
      position
      value
      roleId
    }
  }
`;

const subscriptionUpdateResponsibility = gql`
  subscription OnUpdateResponsibility($teamId: String) {
    onUpdateResponsibility(teamId: $teamId) {
      id
      teamId
      position
      value
      roleId
    }
  }
`;

const url =
  "https://tfn6vazo4jck5mf2mzfddjpszq.appsync-api.eu-central-1.amazonaws.com/graphql";
const region = "eu-central-1";
const auth = {
  type: "OPENID_CONNECT",
  jwtToken: async () => {
    return keycloak.token;
  }
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
]);

const apolloClient = new ApolloClient({
  link,
  cache,
  typeDefs,
  resolvers: {}
});

const apolloProvider = createApolloProvider({
  default: apolloClient
});

export {
  mutationAddResponsibility,
  mutationUpdateResponsibility,
  mutationMoveResponsibility,
  mutationDeleteResponsibility,
  mutationMoveOwnerShipOfTeam,
  mutationAddTeam,
  mutationUpdateTeam,
  mutationUpdateTeamColorUI,
  mutationDeleteTeam,
  mutationAddRole,
  mutationUpdateRoleTitle,
  mutationUpdateRolePurpose,
  mutationUpdateRolePosition,
  mutationDeleteRole,
  mutationUpdateMe,
  mutationAssignUserToRole,
  mutationRemoveUserFromRole,
  mutationRemoveUserFromTeam,
  mutationAddOrganisation,
  mutationDeleteOrganisation,
  mutationUpdateOrganisationGoalLabel,
  mutationUpdateOrganisationResponsibilityLabel,
  mutationRemoveUserFromOrganisation,
  mutationUpdateOrganisationName,
  mutationMoveOwnerShipOfOrganisation,
  mutationDeleteMe,
  mutationInviteUserToOrganisation,
  mutationInviteUserToTeam,
  queryOrganisations,
  queryOrganisation,
  queryOrganisationsWithTeams,
  queryBilling,
  queryMe,
  roleFragment,
  teamFragment,
  queryTeam,
  subscriptionUpdateOrganisation,
  subscriptionUpdateBillingOrganisation,
  subscriptionUpdateTeam,
  subscriptionAddRole,
  subscriptionAddRole2,
  subscriptionUpdateRole,
  subscriptionAddResponsibility,
  subscriptionDeleteResponsibility,
  subscriptionUpdateResponsibility,
  apolloProvider,
  apolloClient
};
