<template>
  <div class="org-header">
    <div class="logo-container">
      <app-logo />
    </div>
    <div class="org-info">
      <h1>
        <app-textarea
          :inputValue="org.name"
          :labelText="'Organization name'"
          :placeholderText="'My organization'"
          :labelPosition="'bottom'"
          :id="{ id: org.id }"
          :maxLength="36"
          @update:apptextarea="updateOrgName"
        />
      </h1>
    </div>
    <div class="right-menu">
      <div class="org-members">
        <org-header-members
          :memberCount="colorMembers.length"
          @toggleUserList="showUsersModal = !showUsersModal"
        >
          <template v-slot:add>
            <app-icon-button
              v-if="!orgIsLocked || (orgIsLocked && userCanEdit)"
              :icon="'plus'"
              :buttonClass="'plus'"
              :labelPosition="'bottom'"
              :labelText="'Invite'"
              :labelWrap="true"
              @click="showInviteModal = !showInviteModal"
            />
          </template>
        </org-header-members>
        <!-- user list modal -->
        <transition name="modal">
          <app-modal
            v-if="showUsersModal"
            @close="showUsersModal = false"
            :fixPos="true"
          >
            <template v-slot:header>
              <h3>Members of {{ org.name }}</h3>
            </template>
            <template v-slot:body>
              <org-member-list :user="user" :organisation="org" />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
        <!-- invite modal -->
        <transition name="modal">
          <org-invite-modal
            v-if="showInviteModal"
            @email="(value) => (lastEmail = value.toLowerCase())"
            @close="showInviteModal = false"
            @next="(showInviteModal = false), (showInviteSuccesModal = true)"
            :org="org"
          />
        </transition>
        <!-- end modal -->
        <!-- invite succes modal -->
        <transition name="succes-modal">
          <app-modal
            v-if="showInviteSuccesModal"
            @close="(showInviteSuccesModal = false), (lastEmail = '')"
          >
            <template v-slot:header>
              <h3>Invite Sent</h3>
            </template>
            <template v-slot:body>
              <div class="spaced-top" v-if="this.hasAccount(this.lastEmail)">
                <!-- has account -->
                <p>
                  {{ this.lastEmail }} already has a Role-up account<br />
                  and is now a member of this organization.
                </p>
              </div>
              <div class="spaced-top" v-if="!this.hasAccount(this.lastEmail)">
                <!-- no account found -->
                <p>
                  Mail servers can delay invitations, or place them in spam.<br />
                  You can send this link yourself, if you want.
                </p>
                <p class="spaced-top url">
                  https://app.role-up.com/signup?email={{ this.lastEmail }}
                </p>
                <p class="spaced-top">
                  You can always retrieve this link from the members list.
                </p>
              </div>
            </template>
            <template v-slot:footer>
              <app-button
                :buttonLabel="'Close'"
                @click="showInviteSuccesModal = false"
              />
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
      </div>
      <div class="org-settings">
        <app-icon-button
          v-if="userCanEdit"
          :icon="'tune-vertical'"
          :labelText="'Organization Settings'"
          :labelPosition="'bottom'"
          @click="showSettingsModal = !showSettingsModal"
        />
        <!-- settings modal -->
        <transition name="modal">
          <app-modal
            v-if="showSettingsModal"
            @close="showSettingsModal = false"
          >
            <template v-slot:header>
              <h3>{{ org.name }} settings</h3>
            </template>
            <template v-slot:body>
              <app-line-item>
                <template v-slot:label>Label for role objective</template>
                <template v-slot:actions>
                  <app-select
                    :optionsArray="[
                      'Aim',
                      'Goal',
                      'Mission',
                      'Objective',
                      'Target'
                    ]"
                    :optionSelected="org.goalLabel || 'Goal'"
                    :rightAligned="true"
                    @change="updateLabelCopyGoal($event)"
                  />
                </template>
              </app-line-item>
              <app-line-item>
                <template v-slot:label
                  >Label for role responsibilities</template
                >
                <template v-slot:actions>
                  <app-select
                    :optionsArray="['Accountabilities', 'Responsibilities']"
                    :optionSelected="
                      org.responsibilityLabel || 'Responsibilities'
                    "
                    :rightAligned="true"
                    @change="updateLabelCopyResponsibilities($event)"
                  />
                </template>
              </app-line-item>
            </template>
          </app-modal>
        </transition>
        <!-- end modal -->
      </div>
      <div class="account">
        <app-icon-button
          :icon="'account-outline'"
          :labelText="'My Account'"
          :labelPosition="'bottom'"
          :labelWrap="true"
          @click="showAccountModal = !showAccountModal"
        />
        <!-- account modal -->
        <transition name="modal">
          <app-account-modal
            v-if="showAccountModal"
            @close="showAccountModal = false"
            :user="user"
            :organisation="org"
          />
        </transition>
        <!-- end modal -->
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from "./AppLogo.vue";
import AppButton from "./AppButton.vue";
import AppIconButton from "./AppIconButton.vue";
import AppAccountModal from "./AppAccountModal.vue";
import OrgHeaderMembers from "./OrgHeaderMembers.vue";
import OrgInviteModal from "./OrgInviteModal.vue";
import AppModal from "./AppModal.vue";
import OrgMemberList from "./OrgMemberList.vue";
import appTextarea from "./AppTextarea.vue";
import AppLineItem from "./AppLineItem.vue";
import { useMutation } from "@vue/apollo-composable";
import {
  mutationUpdateOrganisationName,
  mutationDeleteOrganisation,
  mutationUpdateOrganisationGoalLabel,
  mutationUpdateOrganisationResponsibilityLabel
} from "../apollo";
import AppSelect from "./AppSelect.vue";
import router from "../router";

export default {
  components: {
    AppLogo,
    AppButton,
    AppIconButton,
    AppAccountModal,
    OrgHeaderMembers,
    OrgInviteModal,
    AppModal,
    OrgMemberList,
    appTextarea,
    AppLineItem,
    AppSelect
  },
  name: "OrgHeader",
  props: {
    org: Object,
    user: Object,
    orgIsLocked: Boolean
  },
  inject: ["userCanEdit", "websocket"],
  data() {
    return {
      showAccountModal: false,
      showInviteModal: false,
      showInviteSuccesModal: false,
      showUsersModal: false,
      showSettingsModal: false,
      orgId: undefined,
      lastEmail: ""
    };
  },
  mounted: function () {
    console.log("[OrgHeader] setup connection", this.user.user.id);
    this.websocket.startConnection(this.user.user.id, this.org.id, undefined);
  },
  watch: {
    org(newValue) {
      if (this.orgId != newValue.id) {
        console.log(`[OrgHeader] from ${this.orgId} to ${newValue.id}`);
        this.websocket.startConnection(
          this.user.user.id,
          this.org.id,
          undefined
        );
        this.orgId = newValue.id;
      }
    }
  },
  computed: {
    colorMembers() {
      return this.org.organisationMembers.filter(
        (m) => m.user && m.user.claimsub && !m.dateRemoved
      );
    }
  },
  setup(props) {
    const { mutate: mutateOrgName } = useMutation(
      mutationUpdateOrganisationName
    );

    const { mutate: mutateGoalLabel } = useMutation(
      mutationUpdateOrganisationGoalLabel
    );

    const { mutate: mutateResponsibilityLabel } = useMutation(
      mutationUpdateOrganisationResponsibilityLabel
    );

    const { mutate: mutateDeleteOrg } = useMutation(
      mutationDeleteOrganisation,
      () => ({
        variables: { id: props.org.id },
        update: (cache) => {
          const normalizedId = cache.identify({
            id: props.org.id,
            __typename: "Organisation"
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      })
    );

    function updateOrgName(value) {
      console.log(`updateOrgName ${JSON.stringify(value)}`);
      mutateOrgName({ id: value.id.id, name: value.value })
        .then((r) => console.log(`successfully update org name ${r.data}`))
        .catch((error) => console.log(`failed to update org name ${error}`));
    }
    function deleteThisOrg() {
      console.log("[OrgHeader]: this org and all its teams are deleted.");
      mutateDeleteOrg()
        .then((r) => {
          console.log(`successfully delete org ${JSON.stringify(r.data)}`);
          router.push({
            name: "Orgs"
          });
        })
        .catch((error) => console.log(`failed to delete org  ${error}`));
    }
    function updateLabelCopyGoal(event) {
      mutateGoalLabel({ id: props.org.id, goalLabel: event.target.value })
        .then((r) =>
          console.log(`successfully update org goal label ${JSON.stringify(r)}`)
        )
        .catch((error) => console.log(`failed to update org label ${error}`));
    }
    function updateLabelCopyResponsibilities(event) {
      mutateResponsibilityLabel({
        id: props.org.id,
        responsibilityLabel: event.target.value
      })
        .then((r) =>
          console.log(`successfully update org goal label ${JSON.stringify(r)}`)
        )
        .catch((error) => console.log(`failed to update org label ${error}`));
    }
    function hasAccount(email) {
      return (
        this.org.organisationMembers.filter(
          (m) => m.user.email == email && m.user.claimsub
        ).length > 0
      );
    }

    return {
      updateOrgName,
      deleteThisOrg,
      updateLabelCopyGoal,
      updateLabelCopyResponsibilities,
      hasAccount
    };
  }
};
</script>

<style scoped>
.org-header {
  padding: var(--spacing-content);
  /* padding-bottom: var(--spacing-s); */
  display: flex;
  align-items: stretch;
  /* border-bottom: 1px solid var(--color-1-light); */
}
.org-info {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  padding-right: var(--spacing-content);
  padding-left: 16px;
  margin-left: 16px;
}
.org-info::before {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% - 9px);
  position: absolute;
  left: 0;
  top: 4px;
  background-color: var(--color-text);
}
.right-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-end;
}
.spaced-top {
  margin-top: var(--line-height);
}
.url {
  color: var(--color-link);
}

/* MOBILE VERSION */
@media screen and (max-width: 767px) {
  .org-header {
    padding: 0;
    padding-top: var(--spacing-s);
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 2px solid var(--color-container-background);
  }
  .org-info {
    flex-basis: 90%;
    padding-left: var(--spacing-content);
    padding-right: var(--spacing-content);
    padding-bottom: 0;
    padding-top: 2rem;
    margin-left: 0;
    order: 9;
  }
  .org-info::before {
    display: none;
  }
  .right-menu,
  .logo-container {
    padding: var(--spacing-s) var(--spacing-m);
  }
  .logo-container {
    padding-left: var(--spacing-content);
  }
}
</style>
