<template>
  <h2>Danger Zone</h2>
  <section>
    <p class="spaced-bottom">
      When you delete your organization, all data will be removed from our
      servers.
    </p>
    <br />
    <br />
    <app-button
      :buttonLabel="'Delete Organization'"
      :buttonClass="'destroy-secondary'"
      @click="showDeleteOrgModal = true"
    />
  </section>

  <!-- confirmDeleteModal -->
  <app-modal v-if="showDeleteOrgModal">
    <template v-slot:header>
      <h3>Delete Organization</h3>
    </template>
    <template v-slot:body>
      <p class="spaced-bottom">
        Are you sure you want to delete this organization and all its data from
        our server?
      </p>
      <p>This action cannot be undone.</p>
    </template>
    <template v-slot:footer>
      <app-button :buttonLabel="'Cancel'" @click="showDeleteOrgModal = false" />
      <app-button
        :buttonLabel="'Delete organization'"
        :buttonClass="'destroy'"
        @click="(showDeleteOrgModal = false), deleteThisOrg()"
      />
    </template>
  </app-modal>
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import { mutationDeleteOrganisation } from "../apollo";
import router from "../router";
import AppButton from "./AppButton.vue";
import AppModal from "./AppModal.vue";

export default {
  name: "BillingDangerZoneDelete",
  components: {
    AppButton,
    AppModal
  },
  props: {
    organisation: {
      required: true
    }
  },
  data() {
    return {
      showDeleteOrgModal: false
    };
  },
  setup(props) {
    const { mutate: mutateDeleteOrg } = useMutation(
      mutationDeleteOrganisation,
      () => ({
        variables: { id: props.organisation.id },
        update: (cache) => {
          const normalizedId = cache.identify({
            id: props.organisation.id,
            __typename: "Organisation"
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      })
    );
    function deleteThisOrg() {
      console.log(
        "[BillingDangerZoneTrial]: this org and all its teams are deleted."
      );
      mutateDeleteOrg()
        .then((r) => {
          console.log(`successfully delete org ${JSON.stringify(r.data)}`);
          router.push({
            name: "Orgs"
          });
        })
        .catch((error) => console.log(`failed to delete org  ${error}`));
    }

    return {
      deleteThisOrg
    };
  }
};
</script>

<style scoped>
section {
  padding: var(--line-height) 0;
}
section.spaced-bottom {
  padding-bottom: 4.75rem;
}
.spaced-bottom {
  margin-bottom: var(--line-height);
}
</style>
