<template>
  <div v-if="!isCancelled">
    <h2>Next invoice</h2>
    <section>
      <div class="metric-bar blocks">
        <div class="metric-block">
          <span>Next invoice issue date</span>
          <h3>
            <app-format-date
              :dateString="organisationMembers.nextInvoiceDate"
            />
          </h3>
        </div>
        <div class="metric-block">
          <span>Members</span>
          <h3>{{ organisationMembers.quantity }}</h3>
        </div>
        <div class="metric-block">
          <span>Projected total</span>
          <h3>
            € {{ (organisationMembers.quantity * pricePerSeat).toFixed(2) }}
          </h3>
        </div>
      </div>
      <p v-if="freeRides" class="explanation marked">
        You are not billed for new members that have free days left at the next
        invoice issue date.
      </p>
    </section>
    <section v-if="paymentFailedSoft">
      <div class="problem">
        Your payment was unsuccessful. This could be because:
        <ul>
          <li>You have insufficient funds in your account</li>
          <li>Your payment card has expired</li>
          <li>There's a problem with your bank</li>
        </ul>
        <p>
          We will attempt to charge you again automatically after a few days.<br />Please
          update your payment information if necessary.
        </p>
      </div>
    </section>
    <section v-if="paymentFailedHard">
      <div class="problem">
        Your payment failed. This could be because:
        <ul>
          <li>You have insufficient funds in your account</li>
          <li>Your payment card has expired</li>
          <li>There's a problem with your bank</li>
        </ul>
        <p>
          All three attempts were unsuccessful. We have paused your
          subscription.<br />
          Please update your payment information.
        </p>
      </div>
    </section>
    <section>
      <app-button
        :buttonLabel="'Open Payment Details'"
        v-if="!paymentFailedSoft && !paymentFailedHard && !isCancelled"
        @click="updateSubscription()"
      />
      <app-button
        :buttonLabel="'Update Payment Information'"
        :buttonClass="'cta'"
        v-if="paymentFailedSoft || paymentFailedHard"
        @click="updateSubscription()"
      />
    </section>
    <section v-if="paymentFailedHard">
      <div class="problem">
        <p class="spaced-bottom">
          If you do nothing, your subscription will be canceled on
          <app-format-date
            :dateString="this.organisation.organisationState.dueDate"
          ></app-format-date>
        </p>
        <p>
          When a subscription is canceled, any member in your organization can
          claim ownerwhip by making a successful payment.
        </p>
      </div>
    </section>
  </div>
  <org-claim-subscription
    :billingPage="true"
    :organisation="this.organisation"
    :loggedOnUser="this.loggedOnUser"
    v-if="isCancelled"
  />
  <section class="spacer"></section>
</template>

<script>
import AppButton from "./AppButton.vue";
import AppFormatDate from "./AppFormatDate.vue";
import { retrieveMembers } from "../generic/organisationLogic";
import OrgClaimSubscription from "./OrgClaimSubscription.vue";

export default {
  name: "BillingCurrentMonth",
  components: {
    AppButton,
    AppFormatDate,
    OrgClaimSubscription
  },
  props: {
    organisation: {
      type: Object,
      required: true
    },
    loggedOnUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    organisationMembers() {
      return retrieveMembers(this.organisation);
    },
    freeRides() {
      const freeCount = retrieveMembers(this.organisation).newMembers.filter(
        (member) => member.expectedOnInvoice == false
      ).length;
      return freeCount > 0;
    },
    pricePerSeat() {
      return this.organisation.paymentDetails.recurringPriceEUR;
    },
    paymentFailedSoft() {
      return (
        !this.organisation.organisationState.lastPaymentSuccessfull &&
        this.organisation.organisationState.derivedOrganisationState == "ACTIVE"
      );
    },
    isCancelled() {
      return (
        this.organisation.organisationState.derivedOrganisationState ==
        "CANCELLED"
      );
    },
    paymentFailedHard() {
      return (
        !this.organisation.organisationState.lastPaymentSuccessfull &&
        this.organisation.organisationState.derivedOrganisationState == "FROZEN"
      );
    }
  },
  mounted() {
    if (process.env.VUE_APP_PADDLE_SANDBOX == "true") {
      console.log("PADDLE SANDBOX SET");
      // eslint-disable-next-line no-undef
      Paddle.Environment.set("sandbox");
    }

    // eslint-disable-next-line no-undef
    Paddle.Setup({
      vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID)
    });
  },
  setup(props) {
    function cancelSubscription() {
      const {
        organisation: {
          paymentDetails: { cancelUrl }
        }
      } = props;
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        override: cancelUrl
      });
    }
    function updateSubscription() {
      const {
        organisation: {
          paymentDetails: { updateUrl }
        }
      } = props;
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        override: updateUrl
      });
    }
    return {
      cancelSubscription,
      updateSubscription
    };
  }
};
</script>

<style scoped>
section {
  padding: var(--line-height) 0;
}
section.spaced-bottom {
  padding-bottom: 4.75rem;
}
section.spacer {
  padding: unset;
  padding-bottom: 4.75rem;
}

.metric-bar {
  display: flex;
  align-items: center;
}
.metric {
  display: inline-block;
  line-height: 2.3rem;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid var(--color-text);
  margin-right: 0.5rem;
}
.spaced-bottom {
  margin-bottom: var(--line-height);
}
.metric-bar.blocks {
  align-items: stretch;
}
.metric-block {
  flex-grow: 1;
  flex-shrink: 1;
  border: 1px solid var(--color-4-light);
  padding: var(--spacing-m);
}
.metric-block:not(:last-of-type) {
  border-right: 0px;
}
.metric-block h3 {
  margin-top: calc(var(--line-height) / 2);
}
.explanation {
  margin-top: var(--line-height);
}
.marked {
  position: relative;
}
.marked::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  /* background-color: var(--color-4); */
  background-color: #92f0b8;
  display: block;
  position: absolute;
  left: -24px;
  top: 2px;
}

.problem {
  border-left: 4px solid var(--color-2);
  /* border: 1px solid var(--color-2); */
  /* padding: var(--spacing-m); */
  padding-left: var(--spacing-m);
  color: var(--color-destroy);
}
ul li {
  list-style-type: disc;
  list-style-position: inside;
}
ul {
  padding: var(--line-height) 0;
}
</style>
