<template>
  <div class="modal-mask" @click.self="$emit('close')">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <app-user-badge :user="user" class="badge" :allowLabel="false" />
        </div>
        <div class="modal-body">
          <div class="account-info">
            <h3>{{ user.user.fullName }}</h3>
            <span class="small">{{ user.user.email }}</span>
          </div>
          <app-menu-item
            :icon="'tune-vertical'"
            :menuItemText="'Account Settings'"
            @click="gotoSettings()"
          />
          <app-menu-item
            :icon="'logout-variant'"
            :menuItemText="'Log out'"
            @click="logOut"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppUserBadge from "./AppUserBadge.vue";
import AppMenuItem from "./AppMenuItem.vue";
import router from "../router";

export default {
  name: "AppAccountModal",
  components: {
    AppUserBadge,
    AppMenuItem
  },
  props: {
    user: {
      type: Object
    },
    organisation: {
      type: Object,
      required: true
    }
  },
  emits: ["close"],
  setup() {
    function logOut() {
      console.log("logOut called");
      router.push({ name: "Logout" });
    }
    function gotoSettings() {
      router.push({ name: "Account" });
    }

    return { logOut, gotoSettings };
  }
};
</script>

<style scoped>
/* looks as other modals, except position etc. */
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--viewport-height);
  display: table;
  background-color: rgba(220, 217, 240, 0.6);
  backdrop-filter: blur(6px);
}
@-moz-document url-prefix() {
  .modal-mask {
    background-color: rgba(220, 217, 240, 0.9);
  }
}
.modal-wrapper {
  position: relative;
}
.modal-container {
  position: absolute;
  top: 80px;
  right: 8px;
  width: 90%;
  max-width: 280px;
  padding: var(--spacing-content);
  background-color: var(--color-card-background);
  border-radius: var(--border-radius-l);
  text-align: center;
}
.modal-body {
  margin-top: var(--line-height);
}

/* specific styles for account modal */
.account-info {
  padding-bottom: var(--line-height);
}
.modal-header,
.modal-footer {
  display: flex;
  justify-content: center;
}
/* close button */
/* .modal-header {
  position: relative;
}
.close-button {
  position: absolute;
  top: -22px;
  right: -22px;
} */

/* standard-vue transition on enter and leave */
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.modal-enter-active,
.modal-leave-active {
  transition: all 0.2s ease;
}
</style>
