<template>
  <div class="container">
    <!-- <app-floating-lable :labelPosition="'bottom'" :labelText="'Add role'" /> -->
    <app-icon-button
      v-if="userCanEdit"
      :icon="'plus'"
      :buttonClass="'white'"
      :labelText="'Add role'"
      :labelPosition="'bottom'"
    />
  </div>
</template>

<script>
import AppIconButton from "./AppIconButton.vue";

export default {
  components: { AppIconButton },
  name: "TeamRoleContainerAddRole",
  inject: ["userCanEdit"]
};
</script>

<style scoped>
.container {
  height: calc(var(--spacing-content) * 2 + var(--button-size));
  padding: var(--spacing-content);
}
</style>
