<template>
  {{ message }}
</template>

<script>
export default {
  name: "AppUserInitials",
  props: {
    fullName: {
      type: String,
      default: "- -"
    }
  },
  computed: {
    message() {
      let message = this.fullName.charAt(0);
      let words = this.fullName.split(" ");
      // if there are multiple words add first letter of last word
      if (words.length != 0) {
        const lastWord = words[words.length - 1];
        message += lastWord.charAt(0);
      }
      return message.toUpperCase();
    }
  }
};
</script>
