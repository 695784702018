<template>
  <div class="orgs">
    <app-org-badge
      v-for="(item, index) in orgs"
      :key="item.id"
      :org="item"
      :index="index"
      :activeOrg="item.id == activeOrgId"
      @click="switchToOrganisation(item.id)"
      class="org-badge"
    />
    <app-icon-button
      :icon="'plus'"
      :labelText="'Add Organization'"
      :buttonClass="'plus'"
      :labelPosition="'right'"
      @click="addOrganisation()"
      class="add-icon"
    />
  </div>
</template>

<script>
import AppOrgBadge from "./AppOrgBadge.vue";
import AppIconButton from "./AppIconButton.vue";
import router from "../router";
import { mutationAddOrganisation, queryOrganisations } from "../apollo";
import { useMutation } from "@vue/apollo-composable";

export default {
  components: { AppOrgBadge, AppIconButton },
  name: "OrgNavigation",
  props: {
    orgs: {
      type: Object
    },
    activeOrgId: String
  },
  setup() {
    const { mutate: mutateAddOrg } = useMutation(
      mutationAddOrganisation,
      () => ({
        variables: {},
        update: (cache, { data: { addOrganisation } }) => {
          const data = cache.readQuery({
            query: queryOrganisations,
            variables: {}
          });
          const {
            getMe: {
              user: { organisations }
            }
          } = data;
          cache.writeQuery({
            query: queryOrganisations,
            variables: {},
            data: {
              getMe: {
                user: {
                  ...data.getMe.user,
                  organisations: [...organisations, addOrganisation]
                }
              }
            }
          });
        }
      })
    );

    function addOrganisation() {
      console.log("addOrg");
      mutateAddOrg()
        .then((d) => {
          const {
            data: {
              addOrganisation: { id }
            }
          } = d;
          console.log(`added org with id ${id}`);
          router.push({
            name: "Orgs",
            params: { id }
          });
        })
        .catch((e) => console.log(`failed to add org error ${e}`));
    }

    function switchToOrganisation(value) {
      console.log(`switch to org [${value}]`);
      router.push({
        name: "Orgs",
        params: { id: value }
      });
    }
    return { addOrganisation, switchToOrganisation };
  }
};
</script>

<style scoped>
.orgs {
  /* padding: var(--spacing-content) var(--spacing-m); */
  padding: var(--spacing-content);
  padding-right: 0;
}
.org-badge,
.add-icon {
  margin-bottom: var(--spacing-m);
}

/* MOBILE VERSION */
@media screen and (max-width: 767px) {
  .orgs {
    display: flex;
    flex-wrap: nowrap;
    padding: var(--spacing-m);
    padding-bottom: var(--spacing-content);
    max-width: 100vw;
    overflow-x: scroll;
    min-height: 68px;
  }
  .org-badge,
  .add-icon {
    margin-bottom: 0;
    margin-right: var(--spacing-s);
    flex-shrink: 0;
  }
  .add-icon {
    margin-right: var(--spacing-content);
  }
}
</style>
