<template>
  <!-- if not me -->
  <!-- :class="'data-viz-' + (user.colorUI % maxColors)" -->
  <div
    class="user-badge small"
    :class="'data-viz-' + userColor"
    @mouseenter="showLabel = true"
    @mouseleave="showLabel = false"
  >
    <app-user-initials :fullName="user.user.fullName" />
    <div class="online-indicator" v-if="user.online"></div>
    <app-floating-lable
      v-if="allowLabel"
      v-show="showLabel"
      :labelText="user.user.fullName"
      :userOffline="!user.online"
      :labelPosition="'bottom'"
      :labelWrap="true"
      :centerLabel="true"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { apolloClient } from "../apollo";
import AppFloatingLable from "./AppFloatingLable.vue";
import AppUserInitials from "./AppUserInitials.vue";

export default {
  components: { AppFloatingLable, AppUserInitials },
  name: "AppUserBadge",
  props: {
    user: {
      type: Object,
      required: true
    },
    allowLabel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isMe() {
      const {
        getMe: {
          user: { id: meId }
        }
      } = apolloClient.cache.readQuery({
        query: gql`
          query GetMe {
            getMe {
              user {
                id
              }
            }
          }
        `
      });
      const { userId } = this.user;

      return meId == userId;
    },
    userColor() {
      if (this.isMe) {
        return "me";
      } else {
        return this.user.colorUI % this.maxColors;
      }
    }
  },
  data() {
    return {
      showLabel: false,
      maxColors: 20
    };
  }
};
</script>

<style sccoped>
@import "../assets/css/DataVizColors.css";

.user-badge {
  --badge-size: 40px;
}
.user-badge {
  display: block;
  position: relative;
  height: var(--badge-size);
  width: var(--badge-size);
  border-radius: 50%;
  line-height: var(--badge-size);
  text-align: center;
  box-shadow: 0 0 0 2px var(--color-white);
}
.online-indicator {
  position: absolute;
  top: 2px;
  left: 2px;
  /* transform: translateX(-50%); */
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  opacity: 0.5;
}
</style>
