<template>
  <section>
    <h2 class="spaced-bottom">Members</h2>
    <!-- info about new members -->
    <div v-if="organisationMembers.newMembers.length > 0">
      <p>
        Members that join your organization for the first time, get a 30 day
        free period.<br />
        You will not be billed for members that you remove during this free
        period.
      </p>
    </div>
    <ul>
      <li>
        <div class="info labels"></div>
      </li>
      <li
        v-for="member in newMembersThatDidntLeave"
        :key="member.id"
        :class="{ marked: !member.expectedOnInvoice }"
      >
        <app-line-item>
          <template v-slot:label>
            <div class="info">
              <span>{{ member.fullName }}</span>
              <span class="email small">
                <a class="no-line" :href="'mailto:' + member.email">{{
                  member.email
                }}</a></span
              >
              <app-chart-bar
                :label="'Free until'"
                :value="formatDate(member.trialEndDate)"
                :progressPercentage="member.daysLeft / member.totalFreeDays"
                :progressColor="'#92f0b8'"
                :bgColor="'#E9FCF0'"
                :invertDirection="true"
                class="mob-grid"
              />
            </div>
          </template>
          <template v-slot:actions>
            <app-button
              :buttonLabel="'Remove'"
              :buttonClass="'text-only'"
              @click="(showConfirmRemove = true), (memberToRemove = member)"
          /></template>
        </app-line-item>
      </li>
    </ul>
    <!-- paid and left members -->
    <div>
      <ul>
        <li
          v-for="(member, index) in [
            ...organisationMembers.paidMembers,
            ...organisationMembers.newMembers.filter((m) => m.expectedOnInvoice)
          ]"
          :key="member.id"
        >
          <app-line-item v-if="showAllMembers || index < memberDisplayCount">
            <template v-slot:label>
              <div class="info">
                <span>{{ member.fullName }}</span>
                <span class="email small">
                  <a class="no-line" :href="'mailto:' + member.email">{{
                    member.email
                  }}</a></span
                >
              </div>
            </template>
            <template v-slot:actions>
              <!-- org owner should NOT have a button -->
              <div
                v-if="organisation.adminUser.id == member.id"
                class="spacer"
              ></div>
              <app-button
                v-if="organisation.adminUser.id != member.id"
                :buttonLabel="'Remove'"
                :buttonClass="'text-only'"
                @click="(showConfirmRemove = true), (memberToRemove = member)"
            /></template>
          </app-line-item>
        </li>
        <!-- left members -->
        <li v-for="member in organisationMembers.leftMembers" :key="member.id">
          <app-line-item
            v-if="
              showAllMembers ||
              organisationMembers.paidMembers.length < memberDisplayCount
            "
          >
            <template v-slot:label>
              <div class="info">
                <span>{{ member.fullName }}</span>
                <span class="email small">
                  <a class="no-line" :href="'mailto:' + member.email">{{
                    member.email
                  }}</a></span
                >
                <span class="align-right mob-grid"
                  >Left {{ formatDate(member.leaveDate) }}</span
                >
              </div>
            </template>
            <template v-slot:actions><div class="spacer"></div></template>
          </app-line-item>
        </li>
      </ul>
    </div>
    <br />
    <app-button
      v-if="
        !showAllMembers &&
        organisationMembers.paidMembers.length +
          organisationMembers.leftMembers.length >
          memberDisplayCount
      "
      :buttonLabel="'Show all members'"
      @click="showAllMembers = true"
    />
    <app-button
      v-if="showAllMembers"
      :buttonLabel="'Show less members'"
      @click="showAllMembers = false"
    />

    <!-- modal confirm remove member -->
    <app-modal v-if="showConfirmRemove" @close="showConfirmRemove = false">
      <template v-slot:header
        ><h3>Remove {{ memberToRemove.fullName }}</h3></template
      >
      <template v-slot:body>
        Are you sure you want to remove <br />{{ memberToRemove.fullName }} from
        this organization?
      </template>
      <template v-slot:footer>
        <app-button
          :buttonLabel="'Cancel'"
          @click="showConfirmRemove = false"
        />
        <app-button
          :buttonLabel="'Remove'"
          :buttonClass="'destroy'"
          @click="removeMember(memberToRemove), (showConfirmRemove = false)"
        />
      </template>
    </app-modal>
    <!-- end confirm remove member modal -->
  </section>
</template>

<script>
import AppLineItem from "./AppLineItem.vue";
import AppChartBar from "./AppChartBar.vue";
import AppButton from "./AppButton.vue";
import { retrieveMembers } from "../generic/organisationLogic";
import AppModal from "./AppModal.vue";
import { useMutation } from "@vue/apollo-composable";
import { mutationRemoveUserFromOrganisation } from "../apollo";

export default {
  name: "BillingMembers",
  components: {
    AppLineItem,
    AppChartBar,
    AppButton,
    AppModal
    //  AppFormatDate
  },
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAllMembers: false,
      showConfirmRemove: false,
      memberDisplayCount: 3,
      memberToRemove: ""
    };
  },
  computed: {
    organisationMembers() {
      return retrieveMembers(this.organisation);
    },
    newMembersThatDidntLeave() {
      return retrieveMembers(this.organisation).newMembers.filter(
        (member) => !(member.leaveDate < member.trialEndDate)
      );
    }
  },
  setup(props) {
    const { mutate: leaveOrg } = useMutation(
      mutationRemoveUserFromOrganisation
    );
    function formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "numeric" };

      return date.toLocaleDateString("en-US", options);
    }

    function removeMember(member) {
      console.log(
        "BillingMembers.vue: member " +
          member.id +
          " needs to be removed from org " +
          props.organisation.id
      );
      leaveOrg({ id: member.id, organisationId: props.organisation.id })
        .then((s) => {
          console.log(
            `OrgMemberList. User succesfully removed from organisation ${JSON.stringify(
              s
            )}`
          );
        })
        .catch((error) => {
          console.log(`failed to remove user from organisation ${error}`);
          // TODO: should return something here
        });
    }

    return {
      formatDate,
      removeMember
    };
  }
};
</script>

<style scoped>
section {
  padding: var(--line-height) 0;
}
.info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-content);
}
@media screen and (max-width: 800px) {
  .info {
    grid-template-columns: 1fr 1fr;
  }
  .info *:nth-child(2) {
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .mob-grid {
    grid-column-end: span 2;
  }
}

.labels {
  margin-top: var(--line-height);
  border-bottom: 1px solid var(--color-4-light);
}
.email:hover {
  color: var(--color-labels);
  /* opacity: 0.7; */
}
.spaced-bottom {
  margin-bottom: var(--line-height);
}

.marked {
  position: relative;
}
.marked::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  /* background-color: var(--color-4); */
  background-color: #92f0b8;
  display: block;
  position: absolute;
  left: -24px;
  top: 14px;
}

.spacer {
  width: 85px;
}
</style>
