<template>
  {{ message }}
</template>

<script>
export default {
  name: "AppFormatDate",
  props: {
    dateString: {
      required: true
    }
  },
  computed: {
    message() {
      const date = new Date(this.dateString);
      const options = { year: "numeric", month: "long", day: "numeric" };

      return date.toLocaleDateString("en-US", options);
    }
  }
};
</script>
