<template>
  <div
    class="micro-badge micro"
    @mouseenter="showLabel = true"
    @mouseleave="showLabel = false"
  >
    <app-user-initials :fullName="user.user.fullName" />
    <app-floating-lable
      v-if="allowLabel"
      v-show="showLabel"
      :labelText="user.user.fullName"
      :labelPosition="'bottom'"
      :labelWrap="true"
      :centerLabel="true"
    />
  </div>
</template>

<script>
import AppFloatingLable from "./AppFloatingLable.vue";
import AppUserInitials from "./AppUserInitials.vue";

export default {
  components: { AppFloatingLable, AppUserInitials },
  name: "AppUserBadgeMicro",
  props: {
    user: {
      type: Object,
      required: true
    },
    allowLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showLabel: false
    };
  },
  setup() {}
};
</script>

<style sccoped>
.micro-badge {
  --badge-size: 30px;
}
.micro-badge {
  display: block;
  position: relative;
  height: var(--badge-size);
  width: var(--badge-size);
  border-radius: 50%;
  line-height: var(--badge-size);
  text-align: center;
  background-color: var(--color-1-light);
  /* background-color: var(--color-1); */
  /* color: var(--color-white); */
  margin-right: var(--spacing-xs);
}
</style>
