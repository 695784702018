<template>
  <ul class="users">
    <li v-if="user">
      <app-user-list-item v-if="isUserTeamMember" :user="user">
        <!-- actions if not owner -->
        <template v-slot:actions>
          <app-button
            v-if="!userIsOwner"
            :buttonLabel="'Leave Team'"
            :buttonClass="'text-only'"
            @click="$emit('leaveThisTeam')"
          />
          <span class="owner" v-if="userIsOwner">Owner</span>
        </template>
      </app-user-list-item>
    </li>
    <li v-for="item in otherMembers" :key="item.id">
      <app-user-list-item :user="item">
        <!-- actions if owner -->
        <template v-slot:actions>
          <app-button
            v-if="userIsOwner"
            :buttonLabel="'Make owner'"
            :buttonClass="'text-only'"
            @click="makeOwner(item)"
          />
          <app-button
            v-if="userIsOwner"
            :buttonLabel="'Remove'"
            :buttonClass="'text-only'"
            @click="removeMember(item)"
          />
        </template>
      </app-user-list-item>
    </li>
  </ul>
  <!-- pending invitations -->
  <div v-if="pendingInvites.length > 0">
    <h3 class="spaced-top">Sent invitations</h3>
    <ul>
      <li v-for="item in pendingInvites" :key="item.id">
        <app-line-item>
          <template v-slot:label>
            {{ item.user.email }}<br />
            <span class="small url"
              >https://app.role-up.com/signup?email={{ item.user.email }}</span
            >
          </template>
          <template v-slot:actions>
            <app-button
              v-if="userIsOwner"
              :buttonLabel="'Remove'"
              :buttonClass="'text-only'"
              @click="removeMember(item)"
            />
          </template>
        </app-line-item>
      </li>
    </ul>
  </div>
  <p class="spaced-top" v-if="pendingInvites.length == 0">
    There are no pending invitations for this team.
  </p>
  <!-- actions if owner -->
  <div class="owner-actions" v-if="userIsOwner">
    <app-user-list-item>
      <template v-slot:icon>
        <app-icon :icon="'delete-outline'" :iconClass="'filled'" />
      </template>
      <template v-slot:label>Delete this team and all it's content</template>
      <template v-slot:actions>
        <app-button
          v-if="userIsOwner"
          :buttonLabel="'Delete'"
          :buttonClass="'text-only'"
          @click="$emit('deleteTeam')"
        />
      </template>
    </app-user-list-item>
  </div>
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import {
  mutationMoveOwnerShipOfTeam,
  mutationRemoveUserFromTeam
} from "../apollo";
import AppButton from "./AppButton.vue";
import AppUserListItem from "./AppUserListItem.vue";
import AppIcon from "./AppIcon.vue";
import AppLineItem from "./AppLineItem.vue";

export default {
  components: { AppUserListItem, AppButton, AppIcon, AppLineItem },
  name: "TeamMemberList",
  props: {
    team: { type: Object },
    user: { type: Object }
  },
  emits: ["leaveThisTeam", "deleteTeam"],
  inject: ["userIsOwner"],
  computed: {
    isUserTeamMember() {
      return (
        this.team.teamMembers.filter(
          (member) => member.userId == this.user.userId
        ).length > 0
      );
    },
    otherMembers() {
      return this.team.teamMembers.filter(
        (member) => member.userId != this.user.userId && member.user.claimsub
      );
    },
    pendingInvites() {
      return this.team.teamMembers.filter(
        (member) => member.userId != this.user.userId && !member.user.claimsub
      );
    }
  },
  setup(props) {
    const { mutate: leaveTeam } = useMutation(mutationRemoveUserFromTeam);
    const { mutate: moveAdminUser } = useMutation(mutationMoveOwnerShipOfTeam);

    function removeMember(member) {
      console.log(
        `Remove userId [${member.userId}] from teamId [${props.team.id}] in orgId [${props.team.organisation.id}]`
      );
      leaveTeam({
        id: member.userId,
        teamId: props.team.id
      })
        .then((s) =>
          console.log(`User succesfully removed from team ${JSON.stringify(s)}`)
        )
        .catch((error) => {
          console.log(`failed to remove user from team ${error}`);
        });
    }
    function makeOwner(member) {
      console.log(
        "[TeamMemberList]: " +
          member.user.fullName +
          " is the new owner of team " +
          props.team.name
      );
      moveAdminUser({ id: props.team.id, userId: member.userId })
        .then((s) =>
          console.log(
            `User succesfully ${member.fullName} became admin of team ${
              props.team.name
            } ${JSON.stringify(s)}`
          )
        )
        .catch((error) => {
          console.log(
            `failed to make user ${member.fullname} of team ${error}`
          );
        });
    }

    return {
      removeMember,
      makeOwner
    };
  }
};

/*
TODO
- if I am NOT the owner
    show who is the owner
*/
</script>
<style scoped>
.spaced-top {
  margin-top: 2.5rem;
}
.owner-actions {
  padding: 4rem 0 0 0;
}
.owner {
  padding-right: var(--spacing-s);
}
.url {
  opacity: 0.4;
}
</style>
