<template>
  <div class="app-header">
    <router-link
      :to="{ name: logoLink, params: { id: logoLinkId } }"
      class="no-line"
      aria-label="Back to Organization"
    >
      <div class="logo-container">
        <app-logo />
      </div>
    </router-link>
    <div class="page-info">
      <h1>
        <slot name="title">Page Title</slot>
      </h1>
      <p class="subtitle">
        <slot name="subtitle">&nbsp;</slot>
      </p>
    </div>
  </div>
</template>

<script>
import AppLogo from "./AppLogo.vue";

export default {
  components: {
    AppLogo
  },
  name: "AppHeader",
  props: {
    logoLink: {
      type: String,
      required: false,
      default: "Orgs"
    },
    logoLinkId: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>
.app-header {
  padding: var(--spacing-content);
  /* padding-bottom: var(--spacing-s); */
  display: flex;
  align-items: stretch;
  /* border-bottom: 1px solid var(--color-1-light); */
}
.page-info {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  padding-right: var(--spacing-content);
  padding-left: 16px;
  margin-left: 16px;
  overflow: hidden;
}
.page-info::before {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% - 7px);
  position: absolute;
  left: 0;
  top: 4px;
  background-color: var(--color-text);
}
@media screen and (max-width: 767px) {
  .page-info::before {
    height: calc(100% - 24px);
  }
}

.subtitle {
  margin-top: 9px;
}
</style>
