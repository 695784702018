import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Orgs from "../views/Orgs.vue";
import Team from "../views/Team.vue";
import Account from "../views/Account.vue";
import VersionInfo from "../views/VersionInfo.vue";
import { keycloak, redirectUri } from "../keycloak";
import Poc from "../views/Poc-2.vue";
import Billing from "../views/Billing.vue";

const routes = [
  {
    path: "/poc",
    name: "POC",
    component: Poc,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/versioninfo",
    name: "VersionInfo",
    component: VersionInfo,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/orgs/:id?",
    name: "Orgs",
    component: Orgs,
    meta: {
      isAuthenticated: true
    },
    props: true
  },
  {
    path: "/billing/:id",
    name: "Billing",
    component: Billing,
    meta: {
      isAuthenticated: true
    },
    props: true
  },
  {
    path: "/team/:id",
    name: "Team",
    component: Team,
    meta: {
      isAuthenticated: true
    },
    props: true
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/logout",
    name: "Logout",
    component: Home,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Home,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    //always scroll to top
    return { top: 0 };
  },
  routes
});

router.beforeEach((to, _from, next) => {
  // const basePath = window.location.toString();
  // console.log(
  //   `beforeEach basepath [${basePath}] target [${to.path}] authorization required [${to.meta.isAuthenticated}] authenticated [${keycloak.authenticated}]`
  // );
  if (keycloak.authenticated) {
    console.log(
      `token valid for ${Math.round(
        keycloak.tokenParsed.exp +
          keycloak.timeSkew -
          new Date().getTime() / 1000
      )} seconds`
    );
  }

  if (to.path == "/logout") {
    keycloak
      .logout({ redirectUri })
      .then((success) => {
        // never arrive here...
        console.log("--> logout success ", success);
        next("/");
      })
      .catch((error) => {
        console.log("--> logout error ", error);
      });
  } else if (to.meta.isAuthenticated && !keycloak.authenticated) {
    keycloak
      .init({
        onLoad: "login-required",
        redirectUri: `${redirectUri}/${to.path}`
      })
      .then((r) => {
        console.log(
          `login-required [${to.meta.isAuthenticated}] succesfully authenticated [${r}] seconds`
        );
        if (r) {
          next();
        } else {
          next("/");
        }
      })
      .catch((e) => {
        console.error(`failed to login`);
        console.error(e);
      });

    keycloak.onTokenExpired = () => {
      console.log("token expired");
      keycloak
        .updateToken(30)
        .then(() => {
          console.log(
            `successfully get a new token, valid for ${Math.round(
              keycloak.tokenParsed.exp +
                keycloak.timeSkew -
                new Date().getTime() / 1000
            )} seconds`
          );
        })
        .catch((e) => {
          console.log(`failed to updateToken ${e}`);
        });
    };
  } else if (!to.meta.isAuthenticated) {
    console.log(
      `authentication not required, authenticated [${keycloak.authenticated}]`
    );
    keycloak
      .init({
        onLoad: "check-sso"
      })
      .then((r) =>
        console.log(
          `login-required [${to.meta.isAuthenticated}] succesfully authenticated [${r}]`
        )
      );
    if (to.path == "/signup") {
      console.log(`signup new user ${to.query.email}`);
      keycloak
        .register({
          redirectUri,
          loginHint: to.query.email
        })
        .then((s) => console.log(`successfully registered ${s}`))
        .catch((e) => console.log(`failed to register ${e}`));
    }

    next();
  } else {
    next();
  }
});

export default router;
