<template>
  <details>
    <summary>
      <slot name="question">The question</slot>
      <div class="icon">+</div>
    </summary>
    <div>
      <p>
        <slot name="answer">The answer</slot>
      </p>
    </div>
  </details>
</template>

<script>
export default {
  name: "AppFaq",
  setup() {}
};
</script>

<style scoped>
/* resets */
summary:focus {
  outline: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

details {
  width: 100%;
  position: relative;
}
details > summary {
  cursor: pointer;
  color: var(--color-link);
}
details > summary,
details > div {
  padding: var(--line-height) 3rem var(--line-height) 0;
  width: 100%;
  text-align: left;
}
details > div {
  /* padding-bottom: var(--line-height); */
  padding: 0 3rem calc(var(--line-height) * 2) 0;
}
details > summary .icon {
  display: block;
  list-style: none;
  font-weight: 200;
  font-size: 2rem;
  line-height: 1.25rem;
}
details > summary .icon {
  position: absolute;
  right: 0;
  top: 1rem;
  background-color: transparent;
  transform-origin: 50% 60%;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}
details[open] > summary .icon {
  transform: rotate(45deg);
}
details:not(:last-of-type) {
  border-bottom: 1px solid var(--color-4-light);
}
details > summary {
  list-style: none;
}
</style>
