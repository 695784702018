<template>
  <div class="user-list-item">
    <div class="user-badge">
      <slot name="icon">
        <app-user-badge :user="user" :allowLabel="false" />
      </slot>
    </div>
    <div class="lined">
      <div class="user-name">
        <slot name="label"
          ><span class="label truncated">{{ user.user.fullName }}</span>
          <a class="no-line" :href="'mailto:' + user.user.email"
            ><span class="email small">{{ user.user.email }}</span></a
          >
        </slot>
      </div>
      <span class="actions">
        <slot name="actions"></slot>
      </span>
    </div>
  </div>
</template>

<script>
import AppUserBadge from "./AppUserBadge.vue";
export default {
  name: "AppUserListItem",
  components: { AppUserBadge },
  props: {
    user: {
      type: Object,
      required: false
    }
  }
};
</script>

<style scoped>
.user-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 3rem;
}
.user-badge {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: var(--spacing-m);
}
.lined {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--color-4-light);
  min-height: 3rem;
}
.user-name {
  flex-grow: 1;
  text-align: left;
  padding: var(--spacing-m) 0;
}
.label {
  display: block;
  max-width: 300px;
}
.email {
  display: block;
  opacity: 0.4;
  transition: all 0.2s ease-in-out;
}
.email:hover {
  color: var(--color-labels);
  opacity: 0.7;
}
.actions {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-grow: 1;
  /* white-space: nowrap; */
}
</style>
