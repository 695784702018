<template>
  <div
    class="menu-item"
    :class="{
      disabled: isDisabled,
      inverted: inverted,
      flexInverse: iconPos == 'right',
      noline: suppressLine
    }"
  >
    <div
      class="icon"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/icons/' + icon + '.svg') + ')'
      }"
    ></div>
    <div class="menu-text">
      {{ menuItemText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AppMenuItem",
  props: {
    icon: {
      type: String,
      default: "plus"
    },
    menuItemText: {
      type: String,
      default: "Hit me baby!"
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    iconPos: {
      type: String,
      default: "left"
    },
    suppressLine: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
};
</script>

<style scoped>
* {
  --menu-item-height: 48px;
}
.menu-item {
  cursor: pointer;
  width: 100%;
  min-height: var(--menu-item-height);
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-4-light);
}
.menu-item:hover {
  background-color: var(--color-4-superlight);
}
.flexInverse {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.icon {
  flex-basis: var(--menu-item-height);
  height: var(--menu-item-height);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.menu-text {
  padding-left: var(--spacing-m);
}

/* locked */
.disabled:hover {
  background-color: transparent;
}
.disabled .icon,
.disabled .menu-text {
  opacity: 0.3;
}

/* inverted */
.inverted {
  border-top: 1px solid #39315b;
}
.inverted:hover {
  background-color: var(--color-4-text);
  background-color: #39315b;
}
.inverted .icon {
  transform: scale(0.9);
}

.noline {
  border-top: none;
}
</style>
