<template>
  <div class="card">
    <div class="content">
      <!-- <h4>Role</h4> -->
      <h2>
        <app-textarea
          :inputValue="role.title"
          :editingUser="titleLockedBy"
          :labelText="'Role'"
          :placeholderText="exampleRoles[position % exampleRoles.length].title"
          :id="{ id: role.id, position, purpose: role.purpose }"
          :labelPosition="'bottom'"
          :maxLength="36"
          @update:apptextarea="updateTitle"
          @lock="lockField($event, 'title')"
        />
      </h2>
      <!-- read only version -->
      <app-user-badge-container
        :users="roleAssignedUsersWithColors"
        :overlapBadges="true"
        :placeholderText="''"
        v-if="!userCanEdit"
      >
      </app-user-badge-container>
      <!-- userCanEdit version -->
      <app-user-badge-container
        :users="roleAssignedUsersWithColors"
        :overlapBadges="true"
        :placeholderText="'Assign Team Members'"
        v-if="userCanEdit"
      >
        <template v-slot:add>
          <app-icon-button
            :icon="'pencil-outline'"
            :labelPosition="'bottom'"
            :labelText="'Edit'"
            :labelWrap="true"
            @click="showModal = true"
          />
        </template>
      </app-user-badge-container>
      <!-- modal component assign -->
      <transition name="modal">
        <app-modal v-if="showModal" @close="showModal = false">
          <template v-slot:header>
            <h3>Who is {{ role.title }}</h3>
          </template>
          <template v-slot:body>
            <team-role-assign :role="role" :members="members" />
          </template>
        </app-modal>
      </transition>
      <!-- end modal -->
      <span class="column-label spaced-top">{{ goalLabel || "Goal" }}</span>
      <p class="mission">
        <app-textarea
          :inputValue="role.purpose"
          :editingUser="purposeLockedBy"
          :labelText="goalLabel || 'Goal'"
          :labelPosition="'top'"
          :placeholderText="exampleRoles[position % exampleRoles.length].goal"
          :id="{ id: role.id, position, title: role.title }"
          :maxLength="140"
          @update:apptextarea="updatePurpose"
          @lock="lockField($event, 'purpose')"
        />
      </p>
      <span class="column-label spaced-top">
        {{ responsibilityLabel || "Responsibilities" }}
      </span>
      <ul v-if="userCanEdit" class="responsibilities bullits">
        <transition-group name="list">
          <li
            class="lined"
            v-for="(item, i) in sortedResponsibilities"
            :key="item.id"
            :ref="
              (el) => {
                divs[i] = el;
              }
            "
            draggable="true"
            @dragend.prevent
            @dragstart="startDrag($event, item), onDragStart(i)"
            @dragend="onDragEnd(i)"
            @dragover.prevent
            @dragenter.prevent
            @dragleave.prevent
            @drop="cleanClasses(), onDrop($event, item)"
            @dragenter="onDragEnter(i)"
            @dragleave="onDragLeave($event, i)"
            @mouseover="onHover(i)"
            @mouseout="onHoverOut(i)"
          >
            <!-- <br />{{ item.value }}<br />&nbsp; -->
            <app-textarea
              :inputValue="item.value"
              :labelText="respLabelText"
              :editingUser="item.responsibilityLockedBy"
              :labelPosition="'hidden'"
              :displayPadded="true"
              :id="{ id: item.id, position: item.position }"
              :forceFocus="item.id == forceFocus"
              @update:apptextarea="updateResponsibilityValue"
              @lock="lockField($event, 'responsibility'), pushIcons(i)"
              @blurred="unpushIcons(i)"
            />
            <div class="responsibility-actions">
              <app-icon-button
                v-if="userCanEdit && item.responsibilityLockedBy == undefined"
                :icon="'move'"
                :buttonClass="'white'"
                :labelText="'Move'"
                :labelPosition="'left'"
              />
              <app-icon-button
                v-if="userCanEdit && item.responsibilityLockedBy == undefined"
                :icon="'delete-outline'"
                :buttonClass="'white'"
                :labelText="'Delete'"
                :labelPosition="'left'"
                @click="
                  deleteResponsibility({
                    id: { roleId: role.id, respId: item.id }
                  })
                "
              />
            </div>
          </li>
        </transition-group>
        <li
          class="add-button"
          ref="bottomdrop"
          @dragover.prevent
          @dragenter.prevent
          @dragleave.prevent
          @drop="
            onDrop($event, { id: 'none', position: lastRolePosition }),
              onDragLeaveBottom()
          "
          @dragenter="onDragEnterBottom()"
          @dragleave="onDragLeaveBottom()"
        >
          <app-icon-button
            v-if="userCanEdit"
            class="spaced-m"
            :icon="'plus'"
            :buttonClass="'plus'"
            :labelText="'Add responsibility'"
            :labelPosition="'right'"
            @click="addResponsibility()"
          />
        </li>
      </ul>
      <!-- non draggable version -->
      <ul v-if="!userCanEdit" class="responsibilities bullits">
        <li class="lined" v-for="item in sortedResponsibilities" :key="item.id">
          <app-textarea
            :inputValue="item.value"
            :editingUser="item.responsibilityLockedBy"
            :labelText="respLabelText"
            :labelPosition="'top'"
            :displayPadded="true"
            :id="{ id: item.id, position: item.position }"
            @update:apptextarea="updateResponsibilityValue"
            @lock="lockField($event, 'responsibility')"
          />
        </li>
      </ul>
      <!-- end non draggable version -->
    </div>
    <team-role-button-bar
      v-if="userCanEdit && !roleLocked"
      class="footer"
      :roleTitle="role.title"
      @delete:role="deleteRole()"
    />
  </div>
</template>

<script>
import AppIconButton from "./AppIconButton.vue";
import AppTextarea from "./AppTextarea.vue";
import TeamRoleButtonBar from "./TeamRoleButtonBar.vue";
import AppUserBadgeContainer from "./AppUserBadgeContainer.vue";
import AppModal from "./AppModal.vue";
import TeamRoleAssign from "./TeamRoleAssign.vue";
import {
  mutationAddResponsibility,
  mutationDeleteResponsibility,
  mutationUpdateResponsibility,
  mutationUpdateRolePurpose,
  mutationUpdateRoleTitle,
  roleFragment,
  subscriptionUpdateRole
} from "../apollo";
import { useMutation, useSubscription } from "@vue/apollo-composable";
import { apolloClient } from "../apollo";
import { inject, onBeforeUpdate, ref } from "vue";
import { store } from "../store";
import {v4 as uuidv4} from 'uuid';
export default {
  name: "TeamRole",
  components: {
    AppTextarea,
    TeamRoleButtonBar,
    AppIconButton,
    AppUserBadgeContainer,
    AppModal,
    TeamRoleAssign
  },
  props: {
    position: Number,
    teamId: String,
    goalLabel: String,
    responsibilityLabel: String,
    members: {
      type: Array
    },
    role: {
      type: Object,
      required: true
    }
  },
  inject: ["userCanEdit"],
  emits: ["dragResponsibility","deleteRole"],
  data() {
    return {
      showModal: false,
      exampleRoles: [
        {
          title: "Rocket Engineer",
          goal: "Build a spacecraft that can fly to the moon"
        },
        {
          title: "Pilot",
          goal: "Fly our spacecraft to the moon"
        },
        {
          title: "Moonwalker",
          goal: "Make a lunar selfie"
        }
      ]
    };
  },

  computed: {
    roleLocked() {
      return (
        this.purposeLockedBy ||
        this.titleLockedBy ||
        this.sortedResponsibilities.filter((sr) => sr.responsibilityLockedBy)
          .length > 0
      );
    },
    titleLockedBy() {
      const titleLockDetailsUserId = store.getters.fieldLockedByUserId(
        this.teamId,
        "Role",
        this.role.id,
        "title"
      );
      return this.members.find((u) => u.userId === titleLockDetailsUserId);
    },
    purposeLockedBy() {
      const titleLockDetailsUserId = store.getters.fieldLockedByUserId(
        this.teamId,
        "Role",
        this.role.id,
        "purpose"
      );
      return this.members.find((u) => u.userId === titleLockDetailsUserId);
    },
    roleAssignedUsersWithColors() {
      // get color and online from teamMember
      var map = new Map();
      this.members.forEach((m) =>
        map.set(m.userId, { colorUI: m.colorUI, online: m.online })
      );
      const rc = this.role.roleAssignedUsers
        .map((r) => ({
          ...r,
          ...map.get(r.userId)
        }))
        .sort((a, b) => a.position - b.position);
      return rc;
    },
    lastRolePosition() {
      return Math.max(
        0,
        ...this.role.responsibilities.map((resp) => resp.position)
      );
    },
    respLabelText() {
      if (
        this.responsibilityLabel &&
        this.responsibilityLabel.indexOf("ies") > -1
      ) {
        return `${this.responsibilityLabel.substr(
          0,
          this.responsibilityLabel.indexOf("ies")
        )}y`;
      } else {
        return "Responsibility";
      }
    },
    sortedResponsibilities() {
      return [...this.role.responsibilities]
        .sort((a, b) => a.position - b.position)
        .map((resp) => {
          const r = store.getters.fieldLockedByUserId(
            this.teamId,
            "Responsibility",
            resp.id,
            "responsibility"
          );
          return {
            ...resp,
            responsibilityLockedBy: r
              ? this.members.find((m) => m.userId == r)
              : undefined
          };
        });
    }
  },
  setup(props, { emit }) {
    const ws = inject("websocket");
    function lockField(value, field) {
      if (field == "responsibility") {
        ws.lockField(
          "Responsibility",
          props.teamId,
          value.id.id,
          field,
          value.lock
        );
      } else {
        ws.lockField("Role", props.teamId, props.role.id, field, value.lock);
      }
    }

    const { onResult: roleUpdateSubscriptionResult } = useSubscription(
      subscriptionUpdateRole,
      { id: props.role.id }
    );

    roleUpdateSubscriptionResult(() => {
      console.log(`subscription triggered for role ${props.role.title}`);
    });

    const { mutate: mutateResponsibility } = useMutation(
      mutationUpdateResponsibility
    );
    const { mutate: mutateUpdateRoleTitle } = useMutation(
      mutationUpdateRoleTitle
    );
    const { mutate: mutateUpdateRolePurpose } = useMutation(
      mutationUpdateRolePurpose
    );

    const forceFocus = ref(null);

    const { mutate: mutateAddResponsibility } = useMutation(mutationAddResponsibility)

    const { mutate: mutateDeleteResponsibility } = useMutation(
      mutationDeleteResponsibility,
      () => ({
        variables: {}
      })
    );
    function updateTitle(value) {
      mutateUpdateRoleTitle({
        id: value.id.id,
        title: value.value
      })
        .then(() => console.log(`roleTitle changed`))
        .catch((e) => console.error(`failed to update role title ${e}`));
    }
    function updatePurpose(value) {
      mutateUpdateRolePurpose({
        id: value.id.id,
        purpose: value.value
      })
        .then(() => console.log(`rolePurpose changed`))
        .catch((e) => console.error(`failed to update role purpose ${e}`));
    }

    function updateResponsibilityValue(value) {
      mutateResponsibility({
        id: value.id.id,
        roleId: props.role.id,
        value: value.value
      }).then(
        ({
          data: {
            updateResponsibility: { id, title }
          }
        }) => console.log(`Responsibility ${id} ${title} updated`)
      );
    }
    function deleteRole() {
      emit("deleteRole");
    }

    function addResponsibility() {
      const newRespId = uuidv4()
      const newResponsibilities = [...props.role.responsibilities, {
                 __typename: 'Responsibility',
                id: newRespId, position: props.role.responsibilities.length, 
                value: ""
              }]
      apolloClient.cache.writeFragment({
            id: `Role:${props.role.id}`,
            fragment: roleFragment,
            data: {
              ...props.role, 
              responsibilities: newResponsibilities}
          });
      forceFocus.value = [...newResponsibilities].sort(
            (a, b) => b.position - a.position
          )[0].id;
      mutateAddResponsibility({
        id: newRespId,
        roleId: props.role.id
      })
        .then(() => {
          console.log(`Added responsibility on server`);
        })
        .catch((e) => console.error(`Failed to add responsibility ${e}`));
    }
    function deleteResponsibility(value) {
      apolloClient.cache.writeFragment({
            id: `Role:${props.role.id}`,
            fragment: roleFragment,
            data: {
              ...props.role, 
              responsibilities: props.role.responsibilities.filter(r => r.id != value.id.respId)}
          });
      mutateDeleteResponsibility({
        id: value.id.respId,
        roleId: props.role.id
      });
    }

    function startDrag(evt, item) {
      console.log(
        `startDrag resp ${item.id}/${item.value} from role ${props.role.id}`
      );
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("responsibilityId", item.id);
      evt.dataTransfer.setData("originRoleId", props.role.id);
      evt.dataTransfer.setData("value", item.value);
    }

    function onDrop(evt, item) {
      const responsibilityId = evt.dataTransfer.getData("responsibilityId");
      const originRoleId = evt.dataTransfer.getData("originRoleId");
      console.log(
        `onDrop move resp ${responsibilityId} to role ${props.role.id} on item ${item.id} position ${item.position} value ${item.value}`
      );
      emit("dragResponsibility", {
        id: responsibilityId,
        originRoleId,
        targetRoleId: props.role.id,
        position: item.position,
        value: item.value
      });
    }

    // Drag and drop styling
    const bottomdrop = ref(null);
    const divs = ref([]);
    onBeforeUpdate(() => {
      divs.value = [];
    });

    function cleanClasses() {
      const classedElements = document.querySelectorAll(".dragged-over");
      for (const item of classedElements) {
        item.classList.remove("dragged-over");
      }
    }
    function onDragEnter(i) {
      // remove class from all others!
      cleanClasses();
      if (divs.value && divs.value[i]) {
        const el = divs.value[i];
        el.classList.add("dragged-over");
      }
    }
    function onDragLeave(event, i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i];
        // determine if dragleave was caused by a child element
        const isChild = el.contains(event.target);
        if (!isChild) {
          el.classList.remove("dragged-over");
        }
      }
    }

    function onDragStart(i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i];
        el.classList.add("dragged");
        setTimeout(function () {
          el.classList.add("hide");
        });
      }
    }
    function onDragEnd(i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i];

        el.classList.remove("dragged");
        el.classList.remove("hide");
      }
    }
    // bottom li with add button
    function onDragEnterBottom() {
      cleanClasses();
      bottomdrop.value.classList.add("dragged-over");
    }
    function onDragLeaveBottom() {
      bottomdrop.value.classList.remove("dragged-over");
    }

    // solve hover in class to handle drag and drop glitches on unrelated items
    function onHover(i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i];
        el.classList.add("hovered");
      }
    }
    function onHoverOut(i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i];
        el.classList.remove("hovered");
      }
    }

    // when textarea is activated
    function pushIcons(i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i].lastChild;
        el.classList.add("typing");
      }
    }
    function unpushIcons(i) {
      if (divs.value && divs.value[i]) {
        const el = divs.value[i].lastChild;
        el.classList.remove("typing");
      }
    }

    return {
      updateTitle,
      updatePurpose,
      updateResponsibilityValue,
      lockField,
      deleteRole,
      addResponsibility,
      deleteResponsibility,
      forceFocus,
      startDrag,
      onDrop,
      divs,
      bottomdrop,
      cleanClasses,
      onDragEnter,
      onDragLeave,
      onDragStart,
      onDragEnd,
      onDragEnterBottom,
      onDragLeaveBottom,
      onHover,
      onHoverOut,
      pushIcons,
      unpushIcons
    };
  }
};
</script>

<style scoped>
/* layout of the card as object in container is defined in container object */
/* layout within card */
.spaced-top {
  display: block;
  margin-top: var(--line-height);
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: var(--spacing-content);
  padding-right: var(--spacing-content);
  padding-bottom: var(--spacing-content);
  padding-top: var(--spacing-m);
}
.responsibilities {
  padding-top: var(--spacing-xs);
}
.responsibilities li:not(.add-button) {
  position: relative;
  margin-top: 0;
  margin-top: 2px;
  background-color: var(--color-input-filled);
  border-radius: var(--border-radius);
  margin-bottom: 2px;
}
.responsibilities li:not(.add-button).hovered {
  /* this is managed in the textarea component */
  background-color: var(--color-input-background-active);
}
/* enlarging container of add-button */
.content,
.responsibilities {
  display: flex;
  flex-direction: column;
}
.content,
.responsibilities,
.add-button {
  flex-grow: 1;
}
.tight-bottom {
  margin-bottom: 2px;
}
.spaced-bottom {
  margin-bottom: 4px;
}

/* buttons for responsibility */
.responsibilities li .responsibility-actions {
  display: none;
}
.responsibilities li.hovered .responsibility-actions {
  position: absolute;
  right: -1rem;
  bottom: -10px;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  transition: transform 0.4s ease-in-out;
}
.typing {
  transform: translateY(18px);
}

.spaced-m {
  margin-top: var(--spacing-m);
}

/* drag & drop */
.responsibilities li.dragged {
}
/* 
https://github.com/atlassian/react-beautiful-dnd/issues/961
https://codesandbox.io/s/react-beautiful-dnd-avoid-hovering-other-elements-demo-lmjo8 
*/

.responsibilities li:not(.add-button).hide {
  transform: translateX(-9999px);
  opacity: 0.2;
  height: 0;
}
.dragged-over {
  border-top: 48px solid var(--color-white);
  border-top-color: var(--color-white);
  border-top-width: 48px;
  transition: border-top-width 0.2s ease-in-out;
}
.dragged-over * {
  pointer-events: none;
}

/* transition group responsibilities */
.list-enter-active,
.list-move {
  transition: all 0.4s ease;
}
.list-enter-from {
  transform: scaleY(0);
  opacity: 0.3;
  background-color: var(--color-4);
}

.footer {
  margin-top: calc(var(--spacing-content) * 2);
}
</style>
