<template>
  <div class="color-picker" @mouseleave="active = false">
    <div
      class="color"
      @click="active = !active"
      :style="{ background: colorOptions[selectedColor] }"
    ></div>
    <Transition>
      <ul class="dropdown" v-show="active">
        <li
          v-for="color in colorOptions"
          :key="color"
          @click="
            {
              (selectedColor = colorOptions.indexOf(color)),
                (active = false),
                emitValue(colorOptions.indexOf(color));
            }
          "
        >
          <div
            v-if="colorOptions.indexOf(color) != selectedColor"
            class="color"
            :style="{ background: color }"
          ></div>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "AppColorSelector",
  props: {
    currentColor: {
      type: Number
    }
  },
  emits: ["update:color"],
  data() {
    return {
      active: false,
      selectedColor: this.currentColor || 0,
      colorOptions: [
        "#D1E3FF",
        "#FFDC2E",
        "#9DD8F9",
        "#92F0B8",
        "#C1B8FC",
        "#FEBDFF",
        "#4BD4CC",
        "#DFEDA7",
        "#BDD16B",
        "#FE993C"
      ]
    };
  },
  computed: {},
  methods: {
    emitValue(value) {
      this.$emit("update:color", value);
    }
  }
};
</script>

<style scoped>
.color-picker,
.dropdown {
  display: flex;
  align-items: flex-end;
}
.dropdown {
  background-color: var(--color-white);
  transform-origin: left;
  flex-wrap: wrap-reverse;

  align-content: flex-end;
}
.color {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scaleX(0);
}

@media screen and (max-width: 400px) {
  .dropdown {
    max-width: 140px;
    flex-direction: row-reverse;
  }
  .color {
    margin: var(--spacing-s);
  }
}
</style>
