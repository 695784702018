<template>
  <!-- draggable version -->
  <div ref="container" class="card-container" v-if="userCanEdit">
    <div class="left-marker" ref="leftMarker"></div>

    <transition-group>
      <TeamRole
        v-for="item in sortedRoles"
        :key="item.roleId"
        :role="item.role"
        :teamId="team.id"
        :members="team.teamMembers"
        :goalLabel="team.organisation.goalLabel"
        :position="item.position"
        :responsibilityLabel="team.organisation.responsibilityLabel"
        @dragResponsibility="dragResponsibility"
        @deleteRole="deleteRole(item.roleId)"
      />
      <!-- @dragend="onDropped($event)" -->
      <team-role-container-add-role
        :key="'add'"
        @click="addRoleAtEnd(), (showHelpMenu = false)"
        class="ignore"
      />
    </transition-group>
    <div class="right-marker" ref="rightMarker"></div>
  </div>

  <!-- Role Order Modal -->
  <transition name="modal">
    <app-modal v-if="showRoleOrderModal" @close="showRoleOrderModal = false">
      <template v-slot:header>
        <h3>Reorder Roles</h3>
      </template>
      <template v-slot:body>
        <div class="alert" v-if="isAnythingLocked">
          Roles can not be reordered while others are editing.
        </div>
        <div class="locked" v-if="isAnythingLocked">
          <team-role-drag-item
            v-for="item in sortedRoles"
            :key="item.roleId"
            :role="item.role"
            :locked="true"
          />
        </div>
        <vue-draggable-next
          v-if="!isAnythingLocked"
          :list="sortedRoles"
          :sort="true"
          @change="finish"
        >
          <transition-group>
            <team-role-drag-item
              v-for="item in sortedRoles"
              :key="item.roleId"
              :role="item.role"
            />
          </transition-group>
        </vue-draggable-next>
      </template>
    </app-modal>
  </transition>
  <!-- end modal -->

  <app-icon-button
    v-if="userCanEdit && sortedRoles.length > 1"
    class="reorder-button"
    :icon="'move-inverted'"
    :buttonClass="'inverted bordered'"
    :labelText="'Re-order Roles'"
    :labelPosition="'left'"
    @click="showRoleOrderModal = true"
  />

  <app-icon-button
    class="help-center-button"
    :icon="'question-mark-white'"
    :buttonClass="'inverted bordered'"
    :labelText="'Help & Resources'"
    :labelPosition="'left'"
    @click="showHelpMenu = !showHelpMenu"
  />

  <!-- help menu -->
  <app-help-menu v-if="showHelpMenu == true" class="help-menu" />

  <!-- non editable version -->
  <div ref="container" class="card-container" v-if="!userCanEdit">
    <div class="left-marker" ref="leftMarker"></div>
    <div :list="sortedRoles" class="draggable-roles">
      <TeamRole
        v-for="item in sortedRoles"
        :key="item.roleId"
        :role="item.role"
        :teamId="team.id"
        :members="team.teamMembers"
        :goalLabel="team.organisation.goalLabel"
        :position="item.position"
        :responsibilityLabel="team.organisation.responsibilityLabel"
      />
      <div class="right-marker" ref="rightMarker"></div>
    </div>
  </div>

  <app-icon-button
    class="scroll-previous"
    :icon="'arrow-left-inverted'"
    :buttonClass="'inverted bordered'"
    :labelText="'Scroll roles to the left'"
    @click="scrollHorizontal(-300)"
    v-if="!prevVisible"
  />
  <app-icon-button
    class="scroll-next"
    :icon="'arrow-right-inverted'"
    :buttonClass="'inverted bordered'"
    :labelText="'Scroll roles to the right'"
    @click="scrollHorizontal(300)"
    v-if="!nextVisible"
  />
</template>

<script>
import { onMounted, ref } from "vue";
import TeamRole from "./TeamRole.vue";
import AppIconButton from "./AppIconButton.vue";
import TeamRoleContainerAddRole from "./TeamRoleContainerAddRole.vue";
import { VueDraggableNext } from "vue-draggable-next";
import {
  apolloClient,
  mutationAddRole,
  mutationDeleteRole,
  mutationMoveResponsibility,
  mutationUpdateRolePosition,
  teamFragment
} from "../apollo";
import { useMutation } from "@vue/apollo-composable";
import AppModal from "./AppModal.vue";
import TeamRoleDragItem from "./TeamRoleDragItem.vue";
import { store } from "../store";
import AppHelpMenu from "./AppHelpMenu.vue";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "TeamRoleContainer",
  components: {
    TeamRole,
    AppIconButton,
    TeamRoleContainerAddRole,
    VueDraggableNext,
    AppModal,
    TeamRoleDragItem,
    AppHelpMenu
  },
  props: {
    team: {
      type: Object
    }
  },
  data() {
    return {
      showRoleOrderModal: false,
      showHelpMenu: this.team.teamRoles.length == 0
    };
  },
  inject: ["userCanEdit"],
  computed: {
    sortedRoles: {
      get() {
        return [...this.team.teamRoles].sort((a, b) => a.position - b.position);
      }
    },
    isAnythingLocked: {
      get() {
        return store.getters.teamHasLockedFields(this.team.id);
      }
    }
  },
  setup(props) {
    // scrolling functionality for floating buttons
    const container = ref(null);
    function scrollHorizontal(amount) {
      container.value.scrollBy({
        top: 0,
        left: amount,
        behavior: "smooth"
      }); // works, but no smooth behavior on safari...
      //https://vue-horizontal.fuxing.dev/limitations/#smoothscroll-polyfill
    }
    // handle visibility of scroll buttons
    const leftMarker = ref(null);
    const rightMarker = ref(null);
    let prevVisible = ref(null);
    let nextVisible = ref(null);
    function intersectionCallback(entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          if (entry.target.classList == "left-marker") {
            prevVisible.value = true;
          }
          if (entry.target.classList == "right-marker") {
            nextVisible.value = true;
          }
        } else {
          if (entry.target.classList == "left-marker") {
            prevVisible.value = false;
          }
          if (entry.target.classList == "right-marker") {
            nextVisible.value = false;
          }
        }
      });
    }
    const observer = new IntersectionObserver(intersectionCallback);
    onMounted(() => {
      observer.observe(leftMarker.value);
      observer.observe(rightMarker.value);
    });

    const { mutate: mutateAddRole } = useMutation(mutationAddRole);

    function addRoleAtEnd() {
      const id = uuidv4()
      const now = new Date();
      const newRoles = [...props.team.teamRoles, {
                 __typename: 'Role',
                roleId: id, 
                position: props.team.teamRoles.length,
                role: {
                  id,
                  title: "",
                  purpose: "",
                  roleAssignedUsers: [],
                  responsibilities: [],
                  createdDate: now.toISOString(),
                  updatedDate: now.toISOString(),
                },
              }]
      apolloClient.cache.writeFragment({
            id: `Team:${props.team.id}`,
            fragment: teamFragment,
            data: {
              ...props.team, 
              teamRoles: newRoles},
              updatedDate: now.toISOString(),
              organisation: {...props.team.organisation, updatedDate: now.toISOString(),}
          });
      mutateAddRole({ id, teamId: props.team.id }).then((d) => {
        console.log(`Added role ${d}`);
      });
    }

    const { mutate: mutateUpdateRolePosition } = useMutation(
      mutationUpdateRolePosition
    );

    const { mutate: mutateMoveResponsibility } = useMutation(mutationMoveResponsibility);

    function finish(item) {
      const {
        moved: {
          newIndex,
          element: { roleId }
        }
      } = item;
      apolloClient.cache.writeFragment({
            id: `Team:${props.team.id}`,
            fragment: teamFragment,
            data: {
              ...props.team, 
              teamRoles: moveElementInArray(props.team.teamRoles, roleId, newIndex, "roleId")}
          });
      mutateUpdateRolePosition({
        id: roleId,
        teamId: props.team.id,
        position: newIndex
      })
        .then((m) => console.log("role move succesful", m))
        .catch((e) => console.error("Failed to update role position", e));
    }

    const { mutate: mutateDeleteRole } = useMutation(mutationDeleteRole);

    function deleteRole(id) {
      console.log(`[TeamRoleContainer] deleteRole ${id} ${Date.now()}`)
      apolloClient.cache.writeFragment({
        id: `Team:${props.team.id}`,
        fragment: teamFragment,
        data: {
          ...props.team, 
          teamRoles: props.team.teamRoles.filter(role => role.roleId != id)
        },
        });

      mutateDeleteRole({ id , teamId: props.team.id }).then(
        ({
          data: {
            deleteRole: { name }
          }
        }) => {
          console.info(`Deleted role in team ${name}`);}
      );
    }

    const moveElementInArray = (array, id, toPosition, idField) => {
      const item = array.filter((e) => e[idField] == id)[0];
      const arr1 = array
        .filter((e) => e[idField] != id)
        .map((e, i) => ({ ...e, position: i }));
      const r1 = arr1.filter((e) => e.position < toPosition);
      const r2 = arr1.filter((e) => e.position >= toPosition);
      return [...r1, item, ...r2].map((e, i) => ({ ...e, position: i }));
    };
    const insertElementInArray = (array, item, toPosition) => {
      const arr1 = array
        .map((e, i) => ({ ...e, position: i }));
      const r1 = arr1.filter((e) => e.position < toPosition);
      const r2 = arr1.filter((e) => e.position >= toPosition);
      return [...r1, item, ...r2].map((e, i) => ({ ...e, position: i }));
    }

    function dragResponsibility(item) {
      console.log(
        `[TeamRoleContainer] dragResponsibility ${JSON.stringify(item)}`
      );
      const { originRoleId, targetRoleId, id, position } = item;
      if (originRoleId === targetRoleId) {
          const newRoles = props.team.teamRoles.map(teamRole => {
            if (teamRole.roleId == originRoleId) {
              return {...teamRole, role: {
                ...teamRole.role, responsibilities: moveElementInArray(teamRole.role.responsibilities, id, position, "id")}
              }
            }
            else {
              return teamRole;
            }
          })
          apolloClient.cache.writeFragment({
            id: `Team:${props.team.id}`,
            fragment: teamFragment,
            data: {
              ...props.team, 
              teamRoles: newRoles}
          });
        }
      else {
        // to other role
        const item = props.team.teamRoles.find(teamRole =>  teamRole.roleId == originRoleId).role.responsibilities.find(resp => resp.id == id)
        const newRoles = props.team.teamRoles.map(teamRole => {
            if (teamRole.roleId == originRoleId) {
              return {...teamRole, role: {
                ...teamRole.role, responsibilities: teamRole.role.responsibilities.filter(resp => resp.id != id)}
              }
            }
            else if (teamRole.roleId == targetRoleId) {
              return {...teamRole, role: {
                ...teamRole.role, responsibilities: insertElementInArray(teamRole.role.responsibilities, item , position)}
              }
            }
            else {
              return teamRole;
            }
          })
        apolloClient.cache.writeFragment({
          id: `Team:${props.team.id}`,
          fragment: teamFragment,
          data: {
            ...props.team, 
            teamRoles: newRoles}
        });
      }
      console.time("dragResponsibility")
      mutateMoveResponsibility({id,
          teamId: props.team.id,
          fromRoleId: originRoleId,
          toRoleId: targetRoleId,
          position}).then((m) => {
        const {
          data: {
            moveResponsibility: { id: teamId, name }
          }
        } = m;
        console.timeEnd("dragResponsibility")
        console.log(`responsibility moved within ${teamId} ${name}`);
      });
    }

    return {
      container,
      scrollHorizontal,
      leftMarker,
      rightMarker,
      observer,
      prevVisible,
      nextVisible,
      addRoleAtEnd,
      finish,
      dragResponsibility,
      deleteRole
    };
  }
};
</script>
<style>
/* layout of cards and container */
.card-container {
  width: 100%;
  flex-grow: 1;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  scroll-snap-type: x proximity;
  scroll-snap-stop: always;
  position: relative;
  background-color: var(--color-container-background);
}
.draggable-roles {
  display: flex;
}
.card {
  flex-basis: var(--card-width-min);
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: scroll;
  scroll-snap-align: center;
}
.card:not(:nth-last-child(2)) {
  /* marker must also be div in container */
  margin-right: 2px;
}
/* markers who's visibility determines the visibility of the buttons */
.left-marker {
  display: block;
  width: 24px;
  height: 0;
  position: absolute;
  top: 0;
  left: 0px;
}
.right-marker {
  height: 1px;
  flex-basis: 1px;
  flex-shrink: 0;
}
/* layout of scroll buttons */
.scroll-next {
  position: absolute;
  top: 50%;
  right: 8px;
}
.scroll-previous {
  position: absolute;
  top: 50%;
  left: 8px;
}

.reorder-button {
  position: absolute;
  right: 8px;
  top: calc(50% + 64px);
}
.help-center-button {
  position: absolute;
  right: 8px;
  bottom: 24px;
}
.help-menu {
  position: absolute;
  right: 8px;
  bottom: 72px;
}

.alert {
  margin-bottom: calc(var(--line-height) * 2);
}

/* adding and removing elements */
/* standard-vue transition on enter and leave */
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  /* transition-property: opacity, transform; */
  transition-property: transform;
  /* 
  transform on opacity does a strange thing with the modal in chrome 
  */
}
.list-enter {
  opacity: 0;
  transform: translateX(0);
}
.list-enter-to {
  opacity: 1;
  transform: translateX(0);
}
.list-leave-active {
  position: absolute;
}
.list-leave-to {
  opacity: 0;
  transform: scaleX(0);
  transform-origin: center top;
}
.card {
  background-color: var(--color-card-background);
  transition: background-color 0.7s ease-out;
}
</style>
