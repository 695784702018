<template>
  <!-- case: Trial -->
  <section
    v-if="organisation.organisationState.derivedOrganisationState == OrgStateEnum.TRIAL"
    class="semispaced-bottom"
  >
    <h2 v-if="trialDaysLeft > 1">Trial: {{ trialDaysLeft }} days remaining</h2>
    <h2 v-if="trialDaysLeft == 1">Trial: {{ trialDaysLeft }} day remaining</h2>
    <h2 v-if="trialDaysLeft == 0">Trial: 😲 Last day</h2>
    <div class="spaced-top">
      <app-chart-bar
        :label="'Free days left'"
        :value="trialDaysLeft.toString()"
        :progressPercentage="trialDaysLeft / trialLength"
        :progressColor="'#92f0b8'"
        :bgColor="'#E9FCF0'"
        :invertDirection="true"
        class="bar"
      />
    </div>
    <p v-if="trialDaysLeft > 0">
      Your {{ trialLength }} days trial period for {{ organisation.name }} will
      end ⏰ on
      <app-format-date :dateString="trialEndDate" />
    </p>
    <p v-if="trialDaysLeft == 0">
      Your {{ trialLength }} days trial period for {{ organisation.name }} ends
      today ⏰ .
    </p>
  </section>
  <!-- case: TrialEnd -->
  <section
    v-if="organisation.organisationState.derivedOrganisationState == OrgStateEnum.TRIALFREEZE"
    class="semispaced-bottom"
  >
    <h2>😲 Your trial ended</h2>
    <p>
      Your {{ trialLength }} days trial period for {{ organisation.name }} ended
      on 📅 <app-format-date :dateString="trialEndDate" />.
    </p>
    <p>
      No worries, everything is still here 😅.<br />
      Continue using Role-up in minutes.
    </p>
  </section>
  <billing-members :organisation="organisation" />
  <section>
    <p>
      When you upgrade today, you will be billed €
      {{ (organisationMembers.paidMembers.length * pricePerSeat).toFixed(2) }}
      ({{ organisationMembers.paidMembers.length }} member<span
        v-if="organisationMembers.paidMembers.length > 1"
        >s</span
      >).
    </p>
  </section>
  <section class="spaced-bottom">
    <app-button
      :buttonClass="'cta'"
      :buttonLabel="'Upgrade'"
      @click="createPayment"
    />
    <span class="payment">{{ paymentMessage }}</span>
    <!--
    <h2>Upgrade</h2>
    <p>If you want to keep using Role-up, please provide payment information</p>
     <p>
      By upgrading, you authorize ... to charge your payment method on a montly
      basis for the amounts indicated in the offer details until your
      subscibtion is canceled or terminated.
    </p>
    -->
  </section>
  <section>
    <h2>Payments via Paddle</h2>
    <p class="spaced-bottom">
      We work with Paddle as our merchant of record. That means you will
      purchase our subscription via Paddle. Like when you buy a toy in a local
      store, with Paddle being the store, and we the toy-maker. Paddle provides
      the payment infrastructure and handles complicated stuff for us, such as
      international sales taxes and global banking.
    </p>
    <!-- <p class="spaced-bottom">So we can focus on Role-up.<br />&nbsp;</p> -->

    <app-faq>
      <template v-slot:question
        >What happens when I click the upgrade button?</template
      >
      <template v-slot:answer
        >When you click the upgrade button, a small form will be loaded over
        this page. <br />
        You will need to select your country and Zip code. After you click
        'continue' you provide the credit card details, so Paddle can create the
        subscription.<br />
        When this is succesful, you receive an email with a receipt from Paddle.
      </template>
    </app-faq>
    <app-faq>
      <template v-slot:question>What happens after I upgrade?</template>
      <template v-slot:answer>
        After you update, the 'Free Trial' message at the top of the page will
        dissapear for all members. You can now have unlimited members and teams
        in your organization.<br /><br />
        On the startpage of your organization, a link in the bottom-right corner
        called 'Billing' will appear (only for you). On the billing page of your
        organization you can see new members that were added to your
        organization.<br />
      </template>
    </app-faq>
    <app-faq>
      <template v-slot:question>Do I have to pay when I upgrade?</template>
      <template v-slot:answer>
        Yes. Your card will be billed for €
        {{ (organisationMembers.paidMembers.length * pricePerSeat).toFixed(2) }}
        ({{ organisationMembers.paidMembers.length }} member<span
          v-if="organisationMembers.paidMembers.length > 1"
          >s</span
        >). If local sales taxes are applicable, they will be added by Paddle.
      </template>
    </app-faq>
    <app-faq>
      <template v-slot:question>How much will I pay per month?</template>
      <template v-slot:answer>
        Role-up is a subscription where you pay per member per month. This means
        that Paddle will bill your card automatically every month for the
        members that are part of your organization.
        <p>
          However, we don't like surprises when it comes to payments. And we
          assume neither do you. So when someone is invited to your
          organization, or any team in your organization, we give you 30 days to
          remove this person. If you remove a person during this period, you
          will not be billed for this person.
        </p>
      </template>
    </app-faq>
    <app-faq>
      <template v-slot:question>What happens if I do not upgrade?</template>
      <template v-slot:answer>
        When you choose not to upgrade, your organization will become frozen.
        That means everything becomes read-only and no new members can be
        invited or teams created. Members can still sign in, view the teams and
        roles in your organization, manage their user account etc.
        <p>
          After a while, all data that was entered in the organization, such as
          Teams, Roles and responsibilities will be removed from our servers.
        </p>
      </template>
    </app-faq>
  </section>
</template>

<script>
import AppChartBar from "./AppChartBar.vue";
import AppFormatDate from "./AppFormatDate.vue";
import AppFaq from "./AppFaq.vue";
import AppButton from "./AppButton.vue";
import { ref } from "vue";
import { OrgStateEnum } from "../shared/orgstate";
import { retrieveMembers } from "../generic/organisationLogic";
import BillingMembers from "./BillingMembers.vue";

export default {
  name: "BillingUpgradeTrial",
  components: {
    AppChartBar,
    AppFormatDate,
    AppFaq,
    AppButton,
    BillingMembers
  },
  props: {
    organisation: {
      type: Object,
      required: true
    },
    loggedOnUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      OrgStateEnum: OrgStateEnum
    };
  },
  computed: {
    organisationMembers() {
      return retrieveMembers(this.organisation);
    },
    trialDaysLeft() {
      const startDate = new Date(this.organisation.createdDate);
      const today = new Date();
      const delta = today - startDate;
      const daysAgo = Math.round(delta / (1000 * 60 * 60 * 24));
      return this.trialLength - daysAgo;
    },
    trialEndDate() {
      const startDate = new Date(this.organisation.createdDate);
      return new Date(
        startDate.getTime() + this.trialLength * 1000 * 60 * 60 * 24
      );
    },
    pricePerSeat() {
      const {
        paymentDetails: { recurringPriceEUR = "unknown" }
      } = this.organisation;
      return recurringPriceEUR;
    }
  },
  emits: ["paymentsuccesful"],
  mounted() {
    if (process.env.VUE_APP_PADDLE_SANDBOX == "true") {
      console.log("PADDLE SANDBOX SET");
      // eslint-disable-next-line no-undef
      Paddle.Environment.set("sandbox");
    }

    // eslint-disable-next-line no-undef
    Paddle.Setup({
      vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID)
    });
  },
  setup(props, { emit }) {
    const trialLength = parseInt(process.env.VUE_APP_TRIALLENGTHDAYS);
    const paymentMessage = ref();
    const evaluateCheckout = (data) => {
      console.log("checkoutComplete");
      const {
        checkout: { completed, id }
      } = data;
      console.log(`evaluateCheckout completed:${completed} id:${id}`);
      if (completed) {
        paymentMessage.value = `Payment succesful. Please wait, this page will be updated in a couple of seconds`;
        emit("paymentsuccesful");
      } else {
        paymentMessage.value = "Payment not succesful"; // will update screen and remove trial banner immediately. The actual mutation of the database state will be done through the backend.
      }
    };

    function createPayment() {
      const { quantity } = retrieveMembers(props.organisation);
      console.log(`createPayment for org ${props.organisation.id}`);
      const { email: adminEmail, id: adminUserId } = props.loggedOnUser
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        product: parseInt(process.env.VUE_APP_PADDLE_PRODUCT),
        quantity,
        allowQuantity: false,
        disableLogout: true,
        passthrough: `{"userId": "${adminUserId}", "version":"2" ,"organisationId": "${props.organisation.id}"}`,
        email: adminEmail,
        successCallback: evaluateCheckout,
        closeCallback: evaluateCheckout
      });
    }
    return { createPayment, paymentMessage, trialLength };
  }
};
</script>

<style scoped>
p,
.spaced-top {
  margin-top: var(--line-height);
}
.spaced-bottom {
  margin-bottom: var(--line-height);
}
section {
  padding: var(--line-height) 0;
}
section.spaced-bottom {
  padding-bottom: 4.75rem;
}
section.semispaced-bottom {
  padding-bottom: 3rem;
}
.bar {
  height: calc(var(--line-height) * 1.5);
}
.payment {
  margin-left: var(--spacing-content);
}
</style>
