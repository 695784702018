<template>{{ message }}</template>

<script>
export default {
  name: "AppTimeStamp",
  props: {
    timestamp: {
      required: true
    }
  },

  computed: {
    message() {
      const stamp = new Date(this.timestamp);
      const curr = new Date();
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      // console.log(new Date("2021-12-27").getTime());

      const optionsFull = { month: "short", day: "numeric", year: "numeric" };
      var message = stamp.toLocaleString("default", optionsFull);

      // is it Today?
      if (
        stamp.toLocaleString("default", optionsFull) ==
        curr.toLocaleString("default", optionsFull)
      ) {
        message = "Today";
      }
      // or Yesterday?
      else if (
        stamp.toLocaleString("default", optionsFull) ==
        yesterday.toLocaleString("default", optionsFull)
      ) {
        message = "Yesterday";
      }
      // or this year?
      else if (stamp.getFullYear() == curr.getFullYear()) {
        const optionsShort = { month: "short", day: "numeric" };
        // drop the year
        message = stamp.toLocaleString("default", optionsShort);
      }

      return message;
    }
  }
};
</script>
