import { clearState, messageEventHandler } from "./eventHandler";

export class RoleUpWebSocket {
  userId;
  organisationId;
  teamId;
  websocketConnection;

  constructor() {
    console.log(`[RoleUpWebSocket] constructor`);
  }

  getConnection(callback) {
    console.log(
      `[RoleUpWebSocket] connection state ${
        this.websocketConnection ? this.websocketConnection.readyState : "none"
      }`
    );
    if (!this.websocketConnection || this.websocketConnection.readyState != 1) {
      const url = this.teamId
        ? `wss://ws.role-up.com?userId=${this.userId}&organisationId=${this.organisationId}&teamId=${this.teamId}`
        : `wss://ws.role-up.com?userId=${this.userId}&organisationId=${this.organisationId}`;
      this.websocketConnection = new WebSocket(url);
      this.websocketConnection.onmessage = (event) => {
        console.log(`Incoming event ${event.data}`);
        messageEventHandler(JSON.parse(event.data));
      };
      this.websocketConnection.onopen = () => {
        console.log(
          `[RoleUpWebSocket] Successfully connected to the websocket server`
        );
        callback(this.websocketConnection);
      };
      this.websocketConnection.onerror = (event) => {
        console.log(`[RoleUpWebSocket] error connection`);
        console.log(event);
      };
      this.websocketConnection.onclose = (event) => {
        console.log(`[RoleUpWebSocket] close connection`);
        console.log(event);
      };
    } else {
      callback(this.websocketConnection);
    }
  }
  startConnection(newUserId, newOrganisationId, newTeamId) {
    if (!this.organisationId) {
      console.log(`[RoleUpWebSocket] new connection`);
      // new connection
      this.userId = newUserId;
      this.organisationId = newOrganisationId;
      this.teamId = newTeamId;
      this.getConnection(() => {});
    } else {
      if (newOrganisationId != this.organisationId) {
        console.log(`[RoleUpWebSocket] switch org`);
        // switch organisationId
        this.organisationId = newOrganisationId;
        this.teamId = undefined;
        clearState();
        this.getConnection((ws) =>
          ws.send(
            JSON.stringify({
              command: "switchorganisation",
              organisationId: newOrganisationId
            })
          )
        );
      } else {
        // same organisation
        if (!newTeamId) {
          console.log(`[RoleUpWebSocket] unselect team`);
          this.getConnection((ws) =>
            ws.send(
              JSON.stringify({
                command: "unselectteam"
              })
            )
          );
          this.teamId = undefined;
        } else if (newTeamId != this.teamId) {
          console.log(`[RoleUpWebSocket] select team`);
          this.getConnection((ws) =>
            ws.send(
              JSON.stringify({
                command: "selectteam",
                teamId: newTeamId
              })
            )
          );
          this.teamId = newTeamId;
        }
      }
    }
  }

  lockField(typeName, teamId, roleId, field, lock) {
    console.log(`[RoleUpWebSocket] lockField ${field} ${teamId} ${lock}`);
    this.getConnection((ws) =>
      ws.send(
        JSON.stringify({
          command: "lockfield",
          lockField: { typeName, teamId, roleId, field, lock }
        })
      )
    );
  }
}
