<template>
  <div class="button-sized-container">
    <div class="inner-button-wrapper" :class="[buttonClass]">
      <button
        :aria-label="labelText"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/icons/' + icon + '.svg') + ')'
        }"
        @mouseenter="showLabel = true"
        @mouseout="showLabel = false"
        @click="showLabel = false"
      ></button>
      <app-floating-label
        v-if="labelPosition"
        v-show="showLabel"
        :labelText="labelText"
        :labelPosition="labelPosition"
        :centerLabel="true"
        :labelWrap="labelWrap"
      />
    </div>
  </div>
</template>

<script>
import AppFloatingLabel from "./AppFloatingLable.vue";

export default {
  components: { AppFloatingLabel },
  name: "IconButton",
  props: {
    icon: String,
    buttonClass: {
      type: String,
      default: "default"
    },
    labelPosition: {},
    labelText: {},
    labelWrap: {}
  },
  setup() {},
  data() {
    return {
      showLabel: false
    };
  }
};
</script>

<style scoped>
.button-sized-container {
  width: var(--button-size);
  height: var(--button-size);
}
button {
  height: var(--button-size);
  width: var(--button-size);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
}
.inner-button-wrapper {
  position: relative;
  width: var(--button-size);
  display: flex;
  border-radius: 50%;
  /* transition: all 0.6s ease-in-out; */
  /* 
  radial gradients not supported yet in transition. 
  consider speudo-item with opacity transition? 
  */
}

/* button classes */
.default.inner-button-wrapper {
  background: var(--color-card-background);
}
.default.inner-button-wrapper:hover {
  background-color: var(--color-input-background-active);
}

/* selected */
.selected.inner-button-wrapper {
  background-color: var(--color-input-background-active);
}

/* inverted */
.inverted {
  background: var(--color-text);
}
.inverted.inner-button-wrapper:hover {
  /* background-color: var(--color-button-active); */
  background-color: var(--color-4-text);
  /* background-color: var(--color-3); */
}

/* plus */
.plus.inner-button-wrapper {
  background: var(--color-card-background);
}
.plus.inner-button-wrapper:hover {
  background-color: var(--color-input-background-active);
}

/* white */
.white.inner-button-wrapper {
  background: var(--color-card-background);
}
.white.inner-button-wrapper:hover {
  background: var(--color-card-background);
}

/* features */
.bordered {
  box-shadow: 0 0 0 4px var(--color-card-background);
}

/* empty */
.empty.inner-button-wrapper {
  background: transparent;
}
</style>
