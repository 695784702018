<template>
  <div class="org-graph">
    <div class="graph-wrapper">
      <v-network-graph
        :nodes="nodes.teams"
        :edges="nodes.edges"
        :configs="configs"
        :zoom-level="1.5"
        :event-handlers="eventHandlers"
        id="org-graph"
        ref="graph"
      >
        <!-- https://dash14.github.io/v-network-graph/examples/appearance.html#custom-node -->
        <defs>
          <!--
        Define the path for clipping the face image.
        To change the size of the applied node as it changes,
        add the `clipPathUnits="objectBoundingBox"` attribute
        and specify the relative size (0.0~1.0).
      -->
          <clipPath id="faceCircle" clipPathUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.5" />
          </clipPath>
        </defs>

        <!-- Replace the node component -->
        <!-- <template #override-node="{ nodeId, scale, config }">
          <circle
            :r="config.radius * scale"
            :fill="generateTeamColor(nodeId)"
          /> -->
        <!-- <image
            :class="'radius-' + config.radius"
            :x="-config.radius * scale"
            :y="-config.radius * scale"
            :width="config.radius * scale * 2"
            :height="config.radius * scale * 2"
            :xlink:href="
              require('@/assets/img/size-' + getTeamSize(nodeId) + '.svg')
            "
          /> -->
        <!-- </template> -->
      </v-network-graph>
      <div class="buttons-wrapper">
        <app-icon-button
          :icon="'dots-hexagon'"
          @click="layoutFree"
          :labelText="'Network layout'"
          :labelPosition="'left'"
        />
        <app-icon-button
          :icon="'dots-circle'"
          @click="layoutRadial"
          :labelText="'Circle layout'"
          :labelPosition="'left'"
        />
        <app-icon-button
          :icon="'center-content-2'"
          @click="graph.fitToContents()"
          :labelText="'Recenter'"
          :labelPosition="'left'"
        />
      </div>
    </div>

    <div
      class="inspector small"
      :class="isTouchDevice() ? 'isTouch' : 'noTouch'"
      :style="{
        top: `${this.y}px`,
        left: `${this.x}px`,
        opacity: `${this.opacity}`,
        transform: `scale(${this.opacity},${this.opacity})`
      }"
    >
      <div class="inspector-header" v-if="source">
        <div
          class="team-dot"
          :style="{
            background: `${generateTeamColor(source.id, source.colorUI)}`
          }"
        ></div>
        <div class="light">{{ source.name }}</div>
      </div>
      <div v-for="item in members" :key="item.id">{{ item.fullName }}</div>
      <!-- last updated -->
      <div class="light" v-if="!target">
        <br />
        Modified: <app-time-stamp :timestamp="updated" />
      </div>
      <!-- target team -->
      <div class="inspector-footer" v-if="target">
        <div
          class="team-dot"
          :style="{
            background: `${generateTeamColor(target.id, target.colorUI)}`
          }"
        ></div>
        <div class="light">{{ target.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUnmounted } from "vue";
import * as vNG from "v-network-graph";
import { ForceLayout } from "v-network-graph/lib/force-layout";
import AppIconButton from "./AppIconButton.vue";
import router from "../router";
import AppTimeStamp from "./AppTimeStamp.vue";

// docs: https://dash14.github.io/v-network-graph/examples/layout.html
// https://www.typescriptlang.org/play?
// used to convert ts to js

export default {
  components: {
    AppIconButton,
    AppTimeStamp
  },
  name: "OrgGraph",
  props: {
    teams: {
      type: Object,
      required: true
    }
  },
  watch: {
    teams(newTeams) {
      console.log("OrgGraph teams changed");
      this.nodes.teams = newTeams;
      this.nodes.edges = this.generateEdges(newTeams);
    }
  },
  setup(props) {
    function generateEdges(teams) {
      var edges = {};
      const keys = Object.keys(teams);
      keys.forEach((node, index) => {
        // pair with all teams with a higher index
        const a = index;
        keys.forEach((node2, index) => {
          if (index > a) {
            // we have a pair node-node2
            const edgeMembers = teams[node].teamMembers
              .map((u) => u.user)
              .filter((x) =>
                teams[node2].teamMembers.map((u) => u.user).includes(x)
              );
            if (edgeMembers.length > 0) {
              // we have an edge
              const edgeID = "edge" + a + index;
              const newEdge = {
                source: `${node}`,
                target: `${node2}`,
                members: edgeMembers
              };
              edges[edgeID] = newEdge;
            }
          }
        });
      });
      return edges;
    }

    const nodes = reactive({
      teams: props.teams,
      edges: generateEdges(props.teams)
    });

    /*
    create edges based on the nodes

    https://stackoverflow.com/questions/55525886/how-to-create-edges-between-nodes-that-have-similarities
    1. iterate over every pair of nodes
        for each node, pair with all nodes with a higher index in array
    2. per node-pair take compare member arrays
    3. create array with the members that are in both arrays (or remove the ones that are not?)
    4. create edge if array is larger than 0 items
    */

    //nodes.edges = generateEdges(nodes.teams);

    function generateTeamColor(_teamId, colorUI) {
      // 9 colors
      const teamColors = [
        // "#9FFFEE",
        "#D1E3FF",
        "#FFDC2E",
        "#9DD8F9",
        "#92F0B8",
        "#C1B8FC",
        "#FEBDFF",
        "#4BD4CC",
        "#DFEDA7",
        "#BDD16B",
        "#FE993C"
      ];
      return teamColors[colorUI || 0];
    }

    // function getTeamSize(nodeId) {
    //   const size = this.nodes.teams[nodeId].members.filter(
    //     (member) => member.user.claimsub
    //   ).length;
    //   const maxSize = 15;
    //   return Math.min(size, maxSize);
    // }

    const configs = reactive(
      vNG.defineConfigs({
        view: {
          layoutHandler: new ForceLayout({
            positionFixedByDrag: false,
            positionFixedByClickWithAltKey: true,
            // * The following are the default parameters for the simulation.
            // createSimulation: function (d3, nodes, edges) {
            //   const forceLink = d3.forceLink(edges).id(function (d) {
            //     return d.id;
            //   });
            //   return d3
            //     .forceSimulation(nodes)
            //     .force("edge", forceLink.distance(100))
            //     .force("charge", d3.forceManyBody().strength(1.5))
            //     .force("collide", d3.forceCollide(60).strength(0.02))
            //     .force("center", d3.forceCenter().strength(0.8))
            //     .alphaMin(0.0001);
            //   //  https://github.com/d3/d3-force
            // }
            createSimulation: function (d3, nodes, edges) {
              const forceRadius = 120;
              const forceLink = d3.forceLink(edges).id(function (d) {
                return d.id;
              });
              return d3
                .forceSimulation(nodes)
                .force("edge", forceLink.distance(80))
                .force("charge", d3.forceManyBody().strength(0.5))
                .force("radial", d3.forceRadial(forceRadius).strength(2))
                .force("collide", d3.forceCollide(50).strength(0.02))
                .force("center", d3.forceCenter().strength(1))
                .alphaMin(0.0001);
            }
          }),
          panEnabled: false,
          zoomEnabled: true,
          mouseWheelZoomEnabled: false
        },
        node: {
          normal: {
            /*
            https://static1.squarespace.com/static/59df9853cd0f68dd29301c12/t/61ba32f86a54ad11bbe3f471/1639592697089/Sizing-Circles-for-Data-Visualization-InfoNewt.pdf
           */
            radius: (node) =>
              Math.min(
                (40 *
                  Math.sqrt(
                    node.teamMembers.filter((member) => member.user.claimsub)
                      .length / 2
                  )) /
                  2,
                60
              ),
            color: (node) => generateTeamColor(node.id, node.colorUI)
          },
          hover: {
            radius: (node) =>
              Math.min(
                (40 *
                  Math.sqrt(
                    node.teamMembers.filter((member) => member.user.claimsub)
                      .length / 2
                  )) /
                  2,
                60
              ) + 2,
            color: (node) => generateTeamColor(node.id, node.colorUI)
          },
          selectable: false,
          label: {
            visible: true,
            fontFamily: "inter",
            fontSize: 14,
            lineHeight: 0.9,
            // direction: "center",
            direction: "south",
            color: "#000",
            background: {
              visible: false,
              color: "#fff",
              padding: 4,
              borderRadius: 2
            }
          }
        },
        edge: {
          normal: {
            width: (edge) => Math.min(edge.members.length * 6, 96),
            color: "#f3f0ff"
          },
          hover: {
            width: (edge) => Math.min(edge.members.length * 6, 96) + 4,
            color: "#c1b9fc"
          }
        }
      })
    );

    const graph = ref();

    // inspector
    const opacity = ref(0);
    const members = ref();
    const source = ref();
    const target = ref();
    const updated = ref();

    const eventHandlers = {
      "edge:pointerover": ({ edge }) => {
        members.value = nodes.edges[edge].members.sort();
        opacity.value = 1;
        source.value = nodes.teams[nodes.edges[edge].source];
        target.value = nodes.teams[nodes.edges[edge].target];
      },
      "edge:pointerout": () => {
        opacity.value = 0;
      },
      "node:pointerover": ({ node }) => {
        // members.value = nodes[node].members.sort();
        members.value = nodes.teams[node].teamMembers.map((u) => u.user);
        opacity.value = 1;
        source.value = nodes.teams[node];
        target.value = null;
        updated.value = nodes.teams[node].updatedDate;
      },
      "node:pointerout": () => {
        opacity.value = 0;
      },
      "node:click": ({ node }) => {
        router.push({ name: "Team", params: { id: nodes.teams[node].id } });
      }
    };

    // mouse coordinates
    const x = ref(0);
    const y = ref(0);
    const offset = 12;

    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    function update(event) {
      if (isTouchDevice()) {
        x.value = 80;
        y.value = 96;
      } else {
        x.value = event.pageX + offset;
        y.value = event.pageY + offset;
      }
    }

    onMounted(() => window.addEventListener("mousemove", update));
    onUnmounted(() => window.removeEventListener("mousemove", update));

    // layout buttons
    function layoutRadial() {
      const forceRadius = 120;
      configs.view.layoutHandler = new ForceLayout({
        createSimulation: function (d3, nodes, edges) {
          const forceLink = d3.forceLink(edges).id(function (d) {
            return d.id;
          });
          return d3
            .forceSimulation(nodes)
            .force("edge", forceLink.distance(80))
            .force("charge", d3.forceManyBody().strength(0.5))
            .force("radial", d3.forceRadial(forceRadius).strength(2))
            .force("collide", d3.forceCollide(50).strength(0.02))
            .force("center", d3.forceCenter().strength(1))
            .alphaMin(0.0001);
        }
      });
    }
    function layoutFree() {
      configs.view.layoutHandler = new ForceLayout({
        createSimulation: function (d3, nodes, edges) {
          const forceLink = d3.forceLink(edges).id(function (d) {
            return d.id;
          });
          return d3
            .forceSimulation(nodes)
            .force("edge", forceLink.distance(100))
            .force("charge", d3.forceManyBody().strength(1.5))
            .force("collide", d3.forceCollide(60).strength(0.02))
            .force("center", d3.forceCenter().strength(0.8))
            .alphaMin(0.0001);
        }
      });
    }
    // function doScroll(event) {
    //   // window.scrollBy(event.deltaX, event.deltaY);
    //   window.scrollBy(event.deltaX, event.deltaY);
    // }

    return {
      nodes,
      configs,
      graph,
      eventHandlers,
      members,
      x,
      y,
      opacity,
      layoutRadial,
      layoutFree,
      isTouchDevice,
      source,
      target,
      updated,
      generateTeamColor,
      // getTeamSize,
      generateEdges
      // doScroll
    };
  }
};
</script>

<style scoped>
.org-graph {
  /* THIS IS NOT WORKING: FIREFOX creates horizontal scroll for the inspector */
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
}
.graph-wrapper {
  position: relative;
}
.buttons-wrapper {
  position: absolute;
  /* right: var(--spacing-s); */
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
}
.buttons-wrapper .button-sized-container {
  margin: var(--spacing-xs);
}
/* use id to overwrite values from plugin css */
#org-graph {
  /* minimal height, defined in svg */
  height: 500px;
}
svg.v-canvas {
  width: 100%;
  height: 400px;
}

/* https://css-tricks.com/svg-properties-and-css/ */
svg.v-canvas .v-text {
  /* targets the labels */
  fill: var(--color-text);
}

.inspector {
  position: absolute;
  top: 96px; /* default position on touch */
  left: 80px; /* default position on touch */
  display: block;
  background-color: var(--color-text);
  color: var(--color-white);
  border-radius: var(--border-radius);
  padding: var(--spacing-m) var(--spacing-m);
  line-height: var(--line-height);
  z-index: 10;
}
.inspector-header,
.inspector-footer {
  display: flex;
}
.inspector-header {
  margin-bottom: var(--line-height);
}
.inspector-footer::before {
  content: "";
  display: block;
  width: 1px;
  height: var(--line-height);
  background-color: var(--color-white);
  position: absolute;
  top: 31px;
  left: 16px;
  opacity: 0.5;
}
.inspector-footer {
  margin-top: var(--line-height);
}
.inspector-footer::after {
  content: "";
  display: block;
  width: 1px;
  height: var(--line-height);
  background-color: var(--color-white);
  position: absolute;
  bottom: 30px;
  left: 16px;
  opacity: 0.5;
}
.team-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  /* transform: translateX(-3px); */
  transform: translate(-3px, 3px);
}

.light {
  opacity: 0.7;
}
</style>
