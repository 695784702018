var OrgStateEnum = {
  BETA: "BETA",
  TRIAL: "TRIAL",
  TRIALFREEZE: "TRIALFREEZE",
  ACTIVE: "ACTIVE",
  FROZEN: "FROZEN",
  CANCELLED: "CANCELLED",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  properties: {
    BETA: { msg: "β &mdash; Beta version" },
    TRIAL: { msg: "Free Trial" },
    TRIALFREEZE: { msg: "Free Trial has ended" },
    ACTIVE: { msg: "everything is fine" },
    FROZEN: { msg: "This organization is read-only" },
    CANCELLED: { msg: "Subscription cancelled" },
    ARCHIVED: { msg: "This organization is archived" },
    DELETED: { msg: "This organization is deleted" },
  }
};

export { OrgStateEnum };
