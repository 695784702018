<template>
  <div class="team" :class="{ actions: userCanEdit }">
    <div class="row col-labels">
      <span class="column-label"></span>
      <span class="column-label">Online</span>
      <span class="column-label">Modified</span>
      <span class="column-label owner">Owner</span>
    </div>
    <div class="container">
      <div class="color-control">
        <app-color-selector
          :currentColor="teamColorUI"
          @update:color="updateTeamColor($event, team.id)"
        />
      </div>
      <router-link
        :to="{ name: 'Team', params: { id: team.id } }"
        class="no-line"
      >
        <div class="row">
          <div class="name truncated" v-if="team.name">{{ team.name }}</div>
          <div class="name truncated unnamed" v-if="!team.name">
            Team with no name
          </div>
          <div class="members-online">
            <app-user-badge-micro
              v-for="item in activeMembers"
              :key="item.userId"
              :user="item"
            />
          </div>
          <div class="last-edit">
            <app-time-stamp :timestamp="team.updatedDate" />
          </div>
          <div class="owner truncated">{{ team.adminUser.fullName }}</div>
        </div>
      </router-link>
      <!-- delete button for owner -->
      <div class="owner-actions" v-if="userCanEdit">
        <app-icon-button
          :icon="'delete-outline'"
          :labelPosition="'left'"
          :labelText="'Delete team'"
          @click="showDeleteTeamModal = true"
        />
      </div>
    </div>
  </div>
  <!-- delete team modal -->
  <transition name="modal">
    <app-modal v-if="showDeleteTeamModal" @close="showDeleteTeamModal = false">
      <template v-slot:header>
        <h3>Delete Team</h3>
      </template>
      <template v-slot:body>
        <div>
          Are you sure you want to delete this team &ldquo;{{
            team.name
          }}&rdquo; for everyone?<br />
          This action cannot be undone.
        </div></template
      >
      <template v-slot:footer>
        <app-button
          :buttonLabel="'Cancel'"
          @click="showDeleteTeamModal = false"
        />
        <app-button
          :buttonLabel="'Delete team'"
          :buttonClass="'destroy'"
          @click="(showDeleteTeamModal = false), deleteTeam(team.id)"
        />
      </template>
    </app-modal>
  </transition>
  <!-- end modal -->
</template>

<script>
import AppUserBadgeMicro from "./AppUserBadgeMicro.vue";
import AppIconButton from "./AppIconButton.vue";
import AppButton from "./AppButton.vue";
import AppModal from "./AppModal.vue";
import AppTimeStamp from "./AppTimeStamp.vue";
import { useMutation, useSubscription } from "@vue/apollo-composable";
import {
  mutationDeleteTeam,
  subscriptionUpdateTeam,
  mutationUpdateTeamColorUI
} from "../apollo";
import { store } from "../store";
import AppColorSelector from "./AppColorSelector.vue";
export default {
  components: {
    AppUserBadgeMicro,
    AppTimeStamp,
    AppIconButton,
    AppButton,
    AppModal,
    AppColorSelector
  },
  name: "OrgTeam",
  props: {
    team: {
      type: Object,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },
  inject: ["userCanEdit"],
  computed: {
    teamColorUI() {
      const { colorUI } = this.team;
      return colorUI;
    },
    activeMembers() {
      return (
        this.team.teamMembers.filter((m) =>
          store.getters.userOnlineAndInTeamId(m.userId, this.team.id)
        ) || []
      );
    }
  },
  data() {
    return {
      showDeleteTeamModal: false
    };
  },
  setup(props) {
    const { onResult: teamUpdateSubscriptionResult } = useSubscription(
      subscriptionUpdateTeam,
      { id: props.team.id }
    );
    teamUpdateSubscriptionResult(() => {
      console.log(`subscription triggered for team ${props.team.title}`);
    });

    const { mutate: deleteTeamMutation } = useMutation(mutationDeleteTeam);
    const { mutate: updateTeamColorUI } = useMutation(
      mutationUpdateTeamColorUI
    );

    function deleteTeam(id) {
      console.log(
        `[OrgTeam]: delete team with id ${id} of organisation with id ${props.team.organisation.id}`
      );
      deleteTeamMutation({
        id,
        organisationId: props.team.organisation.id
      })
        .then(() => {
          console.log(`User succesfully deleted team`);
        })
        .catch((error) => {
          console.log(`failed to delete team ${error}`);
          // TODO: should return something here
        });
    }

    function updateTeamColor(value, teamId) {
      console.log(
        "[OrgTeam]: teamID " + teamId + " color to be updated to: " + value
      );
      updateTeamColorUI({ id: teamId, colorUI: value })
        .then(() => {
          console.log(`Team color succesfully changed`);
        })
        .catch((error) => {
          console.log(`failed to update team color ${error}`);
        });
    }

    return {
      deleteTeam,
      updateTeamColor
    };
  }
};
</script>

<style scoped>
.row {
  padding-left: 48px;
}
.row {
  min-height: 3rem;
  border-bottom: 1px solid var(--color-4-light);
  display: grid;
  grid-template-columns: 5fr 5fr 2fr 3fr;
}
.row:not(.col-labels):hover {
  background-color: var(--color-4-superlight);
}
.row > div {
  margin: auto 0;
  padding-right: var(--spacing-s);
}
.members-online {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.col-labels {
  min-height: var(--spacing-content);
}
.team:not(:first-of-type) .col-labels {
  display: none;
}
.unnamed {
  color: var(--color-input-placeholder);
}

/* actions for owner */
.container {
  position: relative;
}
.actions .row {
  /* grid-template-columns: 2fr 2fr 1fr 1fr 3rem; */
  margin-right: 3rem;
}
.owner-actions {
  position: absolute;
  right: 0;
  bottom: 0.5rem;
}
.color-control {
  position: absolute;
  left: 0;
  bottom: 0.5rem;
}

/* MOBILE VERSION */
@media screen and (max-width: 767px) {
  .row {
    grid-template-columns: 2fr 1fr 1fr;
  }
  .owner {
    display: none;
  }
}
</style>
