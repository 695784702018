<template>
  <input
    v-model="value"
    type="email"
    placeholder="name@example.com"
    ref="activeInput"
    maxlength="256"
    list="options"
    v-on:input="isEmailValid"
  />
  <datalist v-if="this.options" id="options">
    <option v-for="option in this.options" :key="option.email">
      {{ option.email }}
    </option>
  </datalist>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "AppInputEmail",
  props: {
    modelValue: {},
    autoFocus: {
      type: Boolean,
      default: false
    },
    options: {
      required: false
    }
  },
  data: function() {
return {
    // eslint-disable-next-line no-useless-escape
    reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  };
},
methods: {
  isEmailValid: function(value) {
      const isValid = value.srcElement.value != "" && this.reg.test(value.srcElement.value)
      this.$emit("update:emailValid", isValid)
    }},
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  emits: ["update:modelValue","update:emailValid"],
  setup(props) {
    const activeInput = ref(null);
    onMounted(() => {
      if (props.autoFocus == true) {
        activeInput.value.focus();
      }
    });
    return {
      activeInput
    };
  }
};
</script>

<style scoped>
input {
  width: 100%;
  min-height: 44px;
  margin: 2px 0;
  padding: var(--spacing-s);
  background-color: var(--color-input-background-active);
  text-align: inherit;
  border-radius: var(--border-radius);
}
input:hover,
input:placeholder-shown {
  background-color: var(--color-input-background-active);
}
input:focus {
  background-color: transparent;
  border-radius: calc(var(--border-radius) / 2);
}
</style>
