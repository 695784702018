<template>
  <div class="button-wrapper" :class="buttonClass">
    <div class="inner-button-wrapper">
      <button
        @mouseenter="showLabel = true"
        @mouseout="showLabel = false"
        @click="this.$emit('buttonclicked')"
      >
        {{ buttonLabel }}
      </button>
      <app-floating-label
        v-if="labelText"
        v-show="showLabel"
        :labelText="labelText"
        :labelPosition="labelPosition"
        :centerLabel="true"
        :labelWrap="labelWrap"
      />
    </div>
  </div>
</template>

<script>
import AppFloatingLabel from "./AppFloatingLable.vue";
export default {
  name: "AppButton",
  components: { AppFloatingLabel },
  emits: ["buttonclicked"],
  props: {
    buttonLabel: {
      type: String,
      required: true,
      default: "Hit me baby!"
    },
    buttonClass: {
      type: String
    },
    labelPosition: {},
    labelText: {},
    labelWrap: {}
  },
  data() {
    return {
      showLabel: false
    };
  }
};
</script>

<style scoped>
/*
CLASSES
- cta
- destroy
- text-only
- locked

*/

button {
  cursor: pointer;
  height: 36px;
  border: 1.5px solid var(--color-buttons);
  padding: 0 var(--spacing-content);
  border-radius: var(--border-radius);
  /* text-transform: uppercase; */
  color: var(--color-button-text);
  white-space: nowrap;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
}
button:hover {
  background-color: var(--color-button-hover);
}
button:active {
  background-color: var(--color-button-text);
  border-color: var(--color-button-text);
  color: white;
}
.button-wrapper + .button-wrapper {
  margin-left: var(--spacing-m);
}

/* button syles / classes */
/* cta */
.cta button {
  height: 2.5rem;
  border-radius: calc(var(--border-radius) * 2);
  font-size: var(--base-size);
  padding: 0 2.5rem;
  text-transform: unset;
  border: 0;
  background-color: var(--color-text);
  color: var(--color-white);
}
.cta button:hover {
  background-color: var(--color-3);
}
.cta button:active {
  background-color: var(--color-3);
  color: var(--color-4-light);
}

.cta-locked button {
  height: 2.5rem;
  border-radius: calc(var(--border-radius) * 2);
  font-size: var(--base-size);
  padding: 0 2.5rem;
  text-transform: unset;
  border: 0;
  background-color: var(--color-grey-100);
  color: #b5b5b5;
}
.cta-locked button:hover {
  background-color: #dadada;
  color: #9d9d9d;
}
.cta-locked button:active {
  background-color: #dadada;
  color: #9d9d9d;
}

/* filled */
/* .filled button {
  background-color: var(--color-button-text);
  border-color: var(--color-button-text);
  color: var(--color-white);
}
.filled button:hover {
  background-color: var(--color-button-active);
  border-color: var(--color-button-active);
}
.filled button:active {
  background-color: var(--color-3);
  border-color: var(--color-3);
} */

/* destroy */
.destroy button {
  color: var(--color-white);
  background-color: var(--color-destroy);
  border-color: var(--color-destroy);
}
.destroy button:hover {
  color: var(--color-destroy);
  background-color: var(--color-white);
}
.destroy button:active {
  color: var(--color-white);
  background-color: var(--color-destroy);
}
/* destroy-secondary */
.destroy-secondary button {
  color: var(--color-destroy);
  /* border-color: var(--color-destroy); */
}

/* text-only */
.text-only button {
  border: none;
  color: var(--color-link);
  padding: 0 var(--spacing-m);
  text-transform: capitalize;
  font-size: var(--base-size);
}
.text-only button:hover {
  background-color: var(--color-input-background-active);
}
.text-only button:active {
  background-color: var(--color-input-background-active);
}
.text-only.button-wrapper + .text-only.button-wrapper {
  margin-left: 0;
}

/* labels */
.button-wrapper {
  display: inline-block;
}
.inner-button-wrapper {
  position: relative;
  display: flex;
}

/* locked */
.locked button,
.locked button:hover {
  color: #9d9d9d;
  background-color: transparent;
  border-color: #dadada;
}
</style>
