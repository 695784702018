<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click.self="$emit('close')">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <h3>Invite Someone</h3>
          </slot>
        </div>
        <form
          @submit.prevent="inviteUser(emailAddress)"
          v-if="!maxMembersReached"
        >
          <div class="modal-body">
            <slot name="body">
              <app-input-email :autoFocus="true"
              @update:modelValue="changed"
              @update:emailValid="validEmail"/>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <app-button
                v-if="!emailAddress"
                :buttonLabel="'Send Invitation'"
                :button-class="'locked'"
              />
              <app-button
                v-if="emailAddress && validEmailAddress"
                :buttonLabel="'Send Invitation'"
              />
              <app-button
                v-if="emailAddress && !validEmailAddress"
                :buttonLabel="'Send Invitation'"
                :button-class="'locked'"
              />
            </slot>
          </div>
        </form>
        <div class="full" v-if="maxMembersReached">
          <div class="modal-body -align-left">
            <p class="oops">&#129488;</p>
            <p class="reason">
              Looks like all member spots for this organization are taken.
            </p>
            <p>
              Ask the organization's owner,<br />
              <a :href="'mailto:' + orgOwnerEmail">{{ orgOwnerFullName }}</a
              ><br />
              to upgrade the account.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMutation } from "@vue/apollo-composable";
import { mutationInviteUserToOrganisation } from "../apollo";
import AppButton from "./AppButton.vue";
import AppInputEmail from "./AppInputEmail.vue";
import { maxMembersReached } from "../generic/organisationLogic";

export default {
  name: "OrgInviteModal",
  components: {
    AppButton,
    AppInputEmail
  },
  props: {
    org: {
      type: Object
    }
  },
  emits: ["close", "next", "email"],
  data() {
    return {
      emailAddress: String,
      validEmailAddress: Boolean
    };
  },
  methods: {
    changed(value) {
      this.emailAddress = value;
    },
    validEmail(value) {
      this.validEmailAddress = value
    }
  },
  mounted() {
    this.emailAddress = "";
  },
  computed: {
    maxMembersReached() {
      return maxMembersReached(this.org);
    }
  },
  setup(props, { emit }) {
    const { mutate: mutateInviteUser } = useMutation(
      mutationInviteUserToOrganisation
    );
    function inviteUser(value) {
      mutateInviteUser({
        email: value.toLowerCase(),
        organisationId: props.org.id,
        teamId: undefined
      })
        .then((r) => {
          console.log(`success ${JSON.stringify(r)}`);
          emit("email", value.toLowerCase());
          emit("next");
        }
      )
        .catch((e) => {
          console.log(`failed ${e}`);
          emit("close");
        });
    }

    return {
      inviteUser,
      orgOwnerFullName: props.org.adminUser.fullName,
      orgOwnerEmail: props.org.adminUser.email
    };
  }
};
</script>

<style scoped>
/* https://codepen.io/team/Vue/pen/mdPoyvv */
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--viewport-height);
  display: table;
  background-color: rgba(220, 217, 240, 0.6);
  backdrop-filter: blur(6px);
}
@-moz-document url-prefix() {
  .modal-mask {
    background-color: rgba(220, 217, 240, 0.9);
  }
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
}
.modal-container {
  margin: 0 auto;
  width: 90%;
  max-width: 360px;
  padding: var(--spacing-content);
  background-color: var(--color-card-background);
  border-radius: var(--border-radius-l);
  text-align: center;
  max-height: 90vh;
}
.modal-body {
  margin-top: var(--line-height);
  margin-bottom: calc(var(--spacing-content) * 2);
  /* text-align: left; */
}
/* standard-vue transition on enter and leave */
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.modal-enter-active {
  transition: all 0.4s ease;
}
p.oops {
  font-size: 3rem;
}
p.reason {
  padding: var(--line-height) 0;
}
</style>
