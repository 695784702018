<template>
  <div class="list-item" :class="lineItemClass">
    <div class="lined">
      <span class="label"><slot name="label">Label text</slot></span>
      <span class="actions">
        <slot name="actions"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLineItem",
  props: {
    lineItemClass: { type: String, required: false }
  }
};
</script>

<style scoped>
.list-item {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 3rem;
}
.lined {
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-4-light);
  min-height: 3rem;
}
.label {
  flex-grow: 1;
  text-align: left;
  padding: var(--spacing-m) 0;
}
.actions {
  white-space: nowrap;
  padding-left: var(--spacing-m);
}

/* classes */
.spaced-above {
  margin-top: 2.5rem;
}
</style>
